import styled from "@emotion/styled";

export const TableHeaders = [
    {
        name: '',
        align: 'left'
    },
    {
        name: 'Title',
        align: 'left'
    },
    {
        name: 'Product Id',
        align: 'left'
    },
    {
        name: 'Category',
        align: 'left'
    },
    {
        name: 'URL',
        align: 'left'
    },
    {
        name: 'Type',
        align: 'left'
    },
    {
        name: 'Sales',
        align: 'left'
    },
    {
        name: 'Status',
        align: 'left'
    },
    {
        name: 'Price',
        align: 'left'
    },
    {
        name: 'Actions',
        align: 'left'
    },
];

export const MarketplaceText = styled.h1`
    color: #6D0085;
    font-family: Poppins;
    font-size: 1.4375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const FilterContainer = styled.div`
  display: flex;
  border-radius: 0.375rem;
  background: #F9F9F9;
  justify-content: space-between;
  height: 2.8125rem;
`;

export const AddNewProductBtn = styled.div`
    display: flex;
    width: 11.4375rem;
    height: 2.8125rem;
    border-radius: 0.375rem;
    background: #3F0065;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-weight: 600;
    transition: opacity 0.3s;
    &:hover {
        opacity: 0.8;
    }
`;

export const TagDiv = styled.div`
    display: flex;
    height: 2.125rem;
    border-radius: 0.3125rem;
    background: #F9F9F9;
    color: #8F8F8F;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-weight: 500;
`;

export const ProductStatusDiv = styled.div`
    width: 4.5rem;
    height: 1.3125rem;
    border-radius: 0.75rem;
    font-family: Poppins;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    padding-top: 0.2rem;
`;

export const pageSize = 7;
