import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
  if (userData) {
    if (userData.response.role === 'seller') {
      return <Navigate to="/seller" />;
    }
    return <Navigate to="/" />;
  }
  return children;
};

const SellerProtectedRoute = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
  if (userData) {
    if(userData.response.role === 'seller' && (!userData.response.stripe_connect_account)){
      return <Navigate to="/onboard" />;      
    }
    else if (userData.response.role === 'seller') {
      return children;
    }    
    return <Navigate to="/" />;
  }
  return <Navigate to="/" />;
};

const BuyerProtectedRoute = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
  if (userData) {
    if (userData.response.role === 'seller') {
      return <Navigate to="/seller" />;
    }
  }
  return children;
};

const BuyerProtectedRouteTwo = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
  if (userData) {
    if (userData.response.role === 'seller') {
      return <Navigate to="/seller" />;
    }
    return children;
  }
  localStorage.setItem('redirectPath', location.pathname + location.search);
  return <Navigate to="/login" />;
};

const SubscriptionProtectedRoute = ({children}) => {
  const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
  if(userData){
    return children;
  }
return <Navigate to="/login" />;
}
export { ProtectedRoute, SellerProtectedRoute, BuyerProtectedRoute, BuyerProtectedRouteTwo, SubscriptionProtectedRoute };