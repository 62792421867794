import NavBar from "../../components/Navbar";
import FooterDivider from "../../components/FooterDivider";
import Footer from "../../components/Footer";
import { FlexDiv } from "../../assets/styles/styles";
import SellerInfo from "../../components/SellerDetailsBuyer/SellerInfo";
import { Typography, Grid } from "@mui/material";
import TrendingProductsCard from "../../components/LandingPage/TrendingProductsCard";
import { useMediaQuery } from "react-responsive";
import { AI_PRODUCTS_TEXT, ALEX_PRODUCTS } from "../../constants/text";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IP_ADD } from "../../constants/api";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import NoProducts from "../../components/SellerDashboard/NoProducts";
import { Helmet } from "react-helmet";
import { Analytics, AnalyticsHeading, SellerHeading, SellerDesc, HEADER_DATA, AnalyticsData } from "./constants";
import AnimatedNumber from "../../components/SellerDashboard/Dashboard/AnimateNumber";
import { Container } from "@mui/material";
import CustomPagination from "../../components/CustomPagination";
import { useNotification } from "../../Context/NotificationContext";

const SellerDetails = () => {
    const isDesktop = useMediaQuery({ minWidth: 1400 });
    const isDesktopthree = useMediaQuery({ minWidth: 1600 });
    const isDesktopFour = useMediaQuery({ minWidth: 1525 });
    const isDesktopTwo = useMediaQuery({ minWidth: 1260 });
    const isLaptop = useMediaQuery({ minWidth: 920 });
    const isLaptopTwo = useMediaQuery({ minWidth: 992 });
    const isTab = useMediaQuery({ minWidth: 690 });
    const isMobile = useMediaQuery({ minWidth: 600 });
    const isSmallMobile = useMediaQuery({ maxWidth: 599 })
    const { id } = useParams();
    const [sellerData, setSellerData] = useState([]);
    const [fetchSellerData, setFetchSellerData] = useState(true);
    const [products, setProducts] = useState([]);
    const [fetchProducts, setFetchProducts] = useState(true);
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [salesFetched, setSalesFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(isSmallMobile ? 3 : 6);
    const { selected } = useNotification();

    const getSellerDetails = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${IP_ADD}/api/users/${id}`);
            if (response.status === 200) {
                setSellerData(response.data.response);
                setFetchSellerData(false);
                // Call the function to fetch products
                fetchProductsData();
            }
        } catch (error) {
            console.error('Error fetching seller details:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchProductsData = async () => {
        try {
            setPageSize(isSmallMobile ? 3 : 6);
            const productsResponse = await axios.get(`${IP_ADD}/api/products/seller/${id}?page=${currentPage}&limit=${pageSize}&type=${selected}`);
            if (productsResponse.status === 200) {
                setProducts(productsResponse.data.response.results);
                setTotalProducts(productsResponse.data.response.totalResults);
                setReviews(productsResponse.data.response.totalReviews);
                setTotalPages(productsResponse.data.response.totalPages);
                setFetchProducts(false);
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    useEffect(() => {
        setFetchSellerData(true);
        setFetchProducts(true);
        getSellerDetails();
    }, []);

    useEffect(() => {
        fetchProductsData();
    }, [currentPage, pageSize, isSmallMobile, selected]);

    useEffect(() => {
        if (!fetchProducts) {
            const newTotalSales = products.reduce((total, product) => total + (product.buyers ? product.buyers.length : 0), 0);
            setTotalSales(newTotalSales);
        }
    }, [fetchProducts, products]);

    useEffect(() => {
        setSalesFetched(true);
    }, [totalSales]);

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <Helmet>
                    <title>Cornucopia AI | Seller-Details</title>
                </Helmet>
                <div style={{ marginBottom: '-200px' }}>
                    <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} />
                    {fetchSellerData ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} />
                        </div>
                    ) : (
                        <>
                            <FlexDiv style={{ margin: isTab ? '3rem 2rem 0rem 3rem' : '3rem 1rem 0rem 1rem', gap: isTab ? '3rem' : '', justifyContent: isTab ? '' : 'center' }}>
                                <div>
                                    <SellerInfo data={sellerData} fetchData={fetchSellerData} />
                                    {salesFetched === true && !isDesktopTwo && (
                                        <Analytics style={{ gap: '2rem', maxWidth: '45rem', flexDirection: 'column', marginTop: '2rem' }}>
                                            {AnalyticsData.map((data) => (
                                                <FlexDiv key={data.id} style={{ justifyContent: 'space-between' }}>
                                                    <FlexDiv>
                                                        <img src={data.line} alt="" />
                                                        <div style={{ marginLeft: '0.8rem' }}>
                                                            <Typography sx={AnalyticsHeading}>{data.heading}</Typography>
                                                            <AnimatedNumber value={data.id === 1 ? totalProducts : data.id === 2 ? totalSales : data.id === 3 ? reviews : '0'} />
                                                        </div>
                                                    </FlexDiv>
                                                    <img src={data.image} alt="" />
                                                </FlexDiv>
                                            ))}
                                        </Analytics>
                                    )}
                                </div>
                                <div style={{ width: isDesktopTwo ? '100%' : '' }}>
                                    {salesFetched === true && isDesktopTwo && (
                                        <Analytics style={{ gap: isDesktopthree ? '13rem' : isDesktopFour ? '10rem' : '5rem', maxWidth: isDesktopthree ? '67rem' : isDesktopFour ? '60rem' : '50rem' }}>
                                            {AnalyticsData.map((data) => (
                                                <FlexDiv key={data.id}>
                                                    <img src={data.line} alt="" />
                                                    <div style={{ marginLeft: '0.8rem' }}>
                                                        <Typography sx={AnalyticsHeading}>{data.heading}</Typography>
                                                        <AnimatedNumber value={data.id === 1 ? totalProducts : data.id === 2 ? totalSales : data.id === 3 ? reviews : '0'} />
                                                    </div>
                                                    <img src={data.image} alt="" style={{ marginLeft: '2rem' }} />
                                                </FlexDiv>
                                            ))}
                                        </Analytics>
                                    )}
                                    {isTab && (
                                        <>
                                            <Typography sx={SellerHeading} style={{ marginTop: '1.5rem', textAlign: 'start' }}>{sellerData?.firstName ? `${sellerData.firstName}’s Products` : ALEX_PRODUCTS}</Typography>
                                            <Typography sx={SellerDesc} style={{ textAlign: 'start' }}>{AI_PRODUCTS_TEXT}</Typography>
                                            {loading ? (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                                    <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} />
                                                </div>
                                            ) : products.length === 0 ? (
                                                <div style={{ marginTop: '1rem' }}>
                                                    <NoProducts />
                                                </div>
                                            ) : (
                                                <>
                                                    <Grid container>
                                                        {products.map((product, index) => (
                                                            <Grid
                                                                item
                                                                key={`product-${index}`}
                                                                lg={isDesktopFour ? 3 : isDesktopTwo ? 4 : 6}
                                                                md={isDesktopTwo ? 4 : isLaptopTwo ? 6 : 12}
                                                                sm={isLaptop ? 6 : 12}
                                                                xs={12}
                                                                display='flex'
                                                                flexDirection='column'
                                                                alignItems={isLaptopTwo ? 'flex-start' : 'center'}
                                                                marginTop='2.8rem'
                                                            >
                                                                <TrendingProductsCard product={{ name: product.name, price: product.price, description: product.description, image: product.images[0], buyers: product.buyers.length, id: product._id }} tags={product.category} />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3rem' }}>
                                                        <CustomPagination
                                                            count={totalPages}
                                                            // sx={{ marginRight: isLargeLaptop ? '0rem' : '0.5rem' }}
                                                            page={currentPage}
                                                            onChange={(event, newPage) => setCurrentPage(newPage)}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </FlexDiv>
                            {!isTab && (
                                <div style={{ padding: '1rem 1rem 0rem 1rem' }}>
                                    <Typography sx={SellerHeading} style={{ marginTop: '1.5rem', textAlign: 'start' }}>{sellerData?.firstName ? `${sellerData.firstName}’s Products` : ALEX_PRODUCTS}</Typography>
                                    <Typography sx={SellerDesc} style={{ textAlign: 'start' }}>{AI_PRODUCTS_TEXT}</Typography>
                                    {loading ? (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                            <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} />
                                        </div>
                                    ) : products.length === 0 ? (
                                        <div style={{ marginTop: '1rem' }}>
                                            <NoProducts />
                                        </div>
                                    ) : (
                                        <>
                                            <Grid container>
                                                {products.map((product, index) => (
                                                    <Grid item key={`key-${index}`} lg={isDesktop ? 3 : 4} md={isDesktopTwo ? 4 : 6} sm={6} xs={12} display="flex" flexDirection="column" alignItems='center' marginTop={isMobile || index === 0 ? '2.8rem' : '6.5rem'}>
                                                        <TrendingProductsCard product={{ name: product.name, price: product.price, description: product.description, image: product.images[0], buyers: product.buyers.length, id: product._id }} tags={product.category} />
                                                    </Grid>
                                                ))}
                                            </Grid>

                                            <FlexDiv style={{ justifyContent: 'center', marginTop: '7.5rem' }}>
                                                <CustomPagination
                                                    count={totalPages}
                                                    // sx={{ marginRight: isLargeLaptop ? '0rem' : '0.5rem' }}
                                                    page={currentPage}
                                                    onChange={(event, newPage) => setCurrentPage(newPage)}
                                                />
                                            </FlexDiv>
                                        </>
                                    )}

                                </div>

                            )}
                        </>
                    )}

                </div>
            </Container>
            <FooterDivider />
            <Footer />
        </>
    );
};

export default SellerDetails;
