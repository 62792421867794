import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CornucopiaIcon from '../../assets/images/cornucopia_landing_icon.png';
import CircularProgress from '@mui/material/CircularProgress';
import EmailVerified from '../../assets/images/email_verified.svg'
import { LoginBoxBtn } from '../../assets/styles/styles';
import { useMediaQuery, useTheme } from '@mui/material';
import FooterDivider from '../../components/FooterDivider';
import Footer from '../../components/Footer';
import { verifyEmailApi } from '../../Services/Authentication';
import { emailVerifiedText, emailSubText } from './constants';
import { Container } from '@mui/material';

const TokenVerify = () => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get('token');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const verifyEmail = async () => {
        try {
            const result = await verifyEmailApi(tokenFromUrl);
            if (result === 200) {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error verifying email:', error);
            // Handle error if needed
        }
    };

    const goToDashboard = () => {
        // Navigate to the "/" route
        navigate('/login');
    };

    useEffect(() => {

        verifyEmail();
    }, [tokenFromUrl]);
    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '7rem' }}>
                    <img src={CornucopiaIcon} alt="Icon" />
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} />
                        </div>
                    ) : (
                        <>
                            <img src={EmailVerified} alr="Email Verified" style={{ marginTop: '10rem' }} />
                            <p style={emailVerifiedText}>Email Verified</p>
                            <p style={emailSubText}>Yahoo! You have successfully verified your email. </p>
                            <LoginBoxBtn
                                onClick={goToDashboard}
                                style={{
                                    marginTop: '2.75rem',
                                    width: isMobile ? '70%' : '18rem',
                                    height: isMobile ? '3rem' : '3.6875rem',
                                    flexShrink: 0,
                                    borderRadius: '2.125rem',
                                    background: '#6A007C',
                                    color: '#FFFFFF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '20px',
                                }}
                            >
                                GO to Login
                            </LoginBoxBtn>
                        </>
                    )}
                </div>
            </Container>
            <FooterDivider />
            <Footer />
        </>
    );
};

export default TokenVerify;
