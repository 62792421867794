export const emailVerifiedText = {
    color: '#6D0085',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop: '2.03rem'
};
export const emailSubText = {
    color: '#676767',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginTop: '1.6rem',
};
