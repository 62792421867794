import Image from "../../assets/images/product_purchase.svg";
import styled from "@emotion/styled";
import { FlexDiv } from "../../assets/styles/styles";
import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import { IP_ADD } from "../../constants/api";
import { useMediaQuery } from "react-responsive";

const ImageofChat = styled.img`
    width: 1.8125rem;
    height: 1.75rem;
    border-radius: 0.3125rem;    
`;

const Clear_Style = {
    width: "1rem",
    height: "1rem",
    fill: "#9D40B1",
    strokeWidth: "1.5px",
    stroke: '#9D40B1'
}

const ChatText = {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "0.6875rem",
    fontStyle: "normal",
    fontWeight: 600,
    maxWidth: '350px',
    textAlign: 'initial'
}

const InboxText = {
    color: "#626161",
    fontFamily: "Poppins",
    fontSize: "0.5625rem",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
    textDecoration: "none", // Initially no underline
    transition: "text-decoration 0.3s ease", // Smooth transition on hover
    ":hover": {
        textDecoration: "underline" // Underline on hover
    }
}

const calculateTimeDifference = (notificationTime) => {
    const currentTime = new Date();
    const notificationDate = new Date(notificationTime);
    const timeDifference = currentTime - notificationDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days >= 1) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours >= 1) {
        return `${hours} hr${hours > 1 ? 's' : ''} ago`;
    } else if (minutes >= 1) {
        return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
    } else {
        return `${seconds} sec${seconds > 1 ? 's' : ''} ago`;
    }
};


const EmailNotification = ({ newNotification = true, message, id, onClearNotification, onMarkAsRead, name, time, productId }) => {
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const userEmail = userData.response.email;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const isMobile = useMediaQuery({ maxWidth: 500 });

    const handleReadClick = () => {
        onMarkAsRead(id, 'webPayment');
    }
    const handleNotificationClick = () => {
        // Make POST request to resend the email
        axios.post(`${IP_ADD}/api/auth/resend-onboarding-email`, {
            productId: productId,
            email: userEmail
        }).then(response => {
            // Show success message
            setSnackbarSeverity('success');
            setSnackbarMessage('Email resend successful');
            setSnackbarOpen(true);
        }).catch(error => {
            // Show error message
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to resend email');
            setSnackbarOpen(true);
            console.error('Error resending email:', error);
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const formattedTime = calculateTimeDifference(time);

    return (
        <>
            <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <FlexDiv style={{ height: isMobile ? "5.5625rem" : '4.5625rem', background: newNotification ? "rgba(200, 125, 212, 0.12)" : '', padding: '1.37rem 1.8rem 1.37rem 1.8rem', justifyContent: 'space-between' }}>
                <button style={{ display: 'contents' }} >
                    <FlexDiv>
                        <ImageofChat src={Image} alt="" />
                        <div style={{ marginLeft: '1rem' }}>
                            <Typography sx={{ ...ChatText, width: '90%' }}>
                                {message}.{' '}
                                <span
                                    onClick={handleNotificationClick}
                                    style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                >
                                    Click here to resend.
                                </span>
                            </Typography>
                            <FlexDiv style={{ gap: '0.4rem' }}>
                                <Typography sx={InboxText}>{formattedTime}</Typography>
                                <FiberManualRecordIcon sx={{ width: '0.1875rem', height: '0.1875rem', fill: '#ACA8A8', marginTop: '0.3rem' }} />
                                <Typography onClick={handleReadClick} sx={InboxText}>Mark as read</Typography>
                            </FlexDiv>
                        </div>
                    </FlexDiv>
                </button>
                <button style={{ display: 'contents' }} onClick={() => onClearNotification(id)}>
                    <ClearIcon sx={Clear_Style} style={{ marginTop: '0.5rem' }} />
                </button>
            </FlexDiv>
            <div style={{ height: '0.0625rem', background: '#D9D9D9' }} />
        </>
    )
}
export default EmailNotification;