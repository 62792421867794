import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { NEW_SELLERS } from '../../constants/text';

function SellerOptions({isNewSellerClicked = false, setIsNewSellerClicked = () =>{}}) {
    const [isAllClicked, setIsAllClicked] = useState(false);
    const [isVerifiedClicked, setIsVerifiedClicked] = useState(false);

    const handleAllClick = () => {
        setIsAllClicked(!isAllClicked);
        setIsVerifiedClicked(false); // Unclick "Verified" when "All" is clicked
        setIsNewSellerClicked(false);
    };

    const handleVerifiedClick = () => {
        setIsVerifiedClicked(!isVerifiedClicked);
        setIsAllClicked(false); // Unclick "All" when "Verified" is clicked
        setIsNewSellerClicked(false);

    };
    const handleNewSellerClick = () => {
        setIsNewSellerClicked(!isAllClicked);
        setIsVerifiedClicked(false); // Unclick "Verified" when "All" is clicked
        setIsAllClicked(false); // Unclick "All" when "Verified" is clicked

    };

    return (
        <Box
            sx={{
                width: '100%',
                // maxWidth: '87.8125rem',
                height: '2.8125rem',
                borderRadius: '0.625rem',
                border: '1px solid rgb(152, 3, 250)',     
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                // padding: '0 1rem',
                
            }}
        >
            <Typography
                sx={{
                    color: isAllClicked ? '#58006B' : '#B9B7BA',
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '1.6875rem',
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                    marginLeft: '1.7rem', // Margin after "All" text

                    marginRight: '1.19rem', // Margin after "All" text
                    textShadow: isAllClicked ? '0px 0px 15px #BA68C8' : 'none',
                }}
                onClick={handleAllClick}
            >
                All
            </Typography>
            <div
                style={{
                    width: '0.03125rem',
                    height: '1.25rem',
                    background: '#9D40B1',
                }}
            />
            <Typography
                sx={{
                    color: isVerifiedClicked ? '#58006B' : '#B9B7BA',
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '1.6875rem',
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                    marginLeft: '1.19rem', // Margin before "Verified" text
                    textShadow: isVerifiedClicked ? '0px 0px 15px #BA68C8' : 'none',
                }}
                onClick={handleVerifiedClick}
            >
                Verified
            </Typography>
            <div
                style={{
                    width: '0.03125rem',
                    height: '1.25rem',
                    background: '#9D40B1',
                    marginLeft: '1.25rem'
                }}
            />
            <Typography
                sx={{
                    color: isNewSellerClicked ? '#58006B' : '#B9B7BA',
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '1.6875rem',
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                    marginLeft: '1.19rem', // Margin before "Verified" text
                    textShadow: isNewSellerClicked ? '0px 0px 15px #BA68C8' : 'none',
                    whiteSpace: 'nowrap', 

                }}
                onClick={handleNewSellerClick}
            >
                {NEW_SELLERS}
            </Typography>
        </Box>
    );
}

export default SellerOptions;
