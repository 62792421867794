import styled from "@emotion/styled";
import { Container } from "@mui/material";

const DividerOne = styled.div`
    width: 110%;
    height:200px;
    background-color:#EBD6EF;
    transform:rotate(-4.899deg);
    margin:0px;
    padding:0px;
    z-index:40;
    position:relative;
    top:330px;
`;
const DividerTwo = styled.div`
    width: 110%;
    height: 100px;
    background-color:rgba(78, 0, 129, 0.17);
    transform:rotate(-2.898deg);
    margin:0px;
    padding:0px;
    z-index:41;
    position:relative;
    top:160px;
`;
const DividerThree = styled.div`
    width:110%;
    height:100px;
    background-color:white;
    transform:rotate(-1.5deg);
    margin:0px;
    padding:0px;
    z-index:42;
    position:relative;
    top:85px;
`;

const FooterDivider = () => {
    return (
        <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
            <div style={{ width: "100%", overflow: "hidden" }}>
                <DividerOne />
                <DividerTwo />
                <DividerThree />
            </div>
        </Container>
    )
}
export default FooterDivider;