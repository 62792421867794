import React from "react";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import WebOnboardCard from "../components/WebOnboardCard";
import { SIGNUP_HEADER_DATA } from "../constants/data";
import FooterDivider from "../components/FooterDivider";
import { Container } from "@mui/material";

const WebOnboard = () => {
    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <NavBar header_data={SIGNUP_HEADER_DATA} header_button={{ label: 'Login', navigation_url: '/login' }} mobile_nav_height={'210px'} />
                <WebOnboardCard />
            </Container>
            <div style={{ marginTop: '-200px' }}>
                <FooterDivider />
                <Footer />
            </div>
        </>
    );
};

export default WebOnboard