import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "react-responsive";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PaginationItem from '@mui/material/PaginationItem';
const CustomPagination = ({ count, page, onChange, sx = {} }) => {
    const isMobile = useMediaQuery({ maxWidth: 500 });

    return (
        <Stack spacing={2}>
            <Pagination
                count={count}
                page={page}
                onChange={onChange}
                boundaryCount={0}
                siblingCount={isMobile ? 0 : 1}
                color="secondary"
                sx={sx}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                    />
                )}
            />
        </Stack>
    );
};

export default CustomPagination;
