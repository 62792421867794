import styled from "styled-components";

export const SkipForNowDiv = styled.div`
    font-family: 'Poppins';
    font-size: 0.9375rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: underline 0.4s;

    @media (max-width: 530px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5.5rem;
        width: 100%;
    }

    @media (min-width: 531px) {
        position: absolute;
        top: 6.2rem;
        right: 15px;
    }

    &:hover {      
        text-decoration: underline;
    }
`;
