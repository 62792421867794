import axios from 'axios';
import { IP_ADD, LOCAL_HOST } from '../constants/api';
import { SellerProfileDiv } from '../assets/styles/styles';

export const buyProductApi = async ({ sellerId, productId, price, paymentMethod, user }) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/payment/buy-product`, {
            seller_id: sellerId,
            amount: price * 100,
            paymentMethod: paymentMethod.id,
            buyer_id: user.response.id,
            product_id: productId,
        });

        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        console.error('Error Paying:', error);

        return {
            success: false,
            error: error?.response?.data?.message || error?.message || 'Error',
        };
    }
};

export const createProductPaymentApi = async ({ userId, amount, paymentMethod }) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/payment/create-productPayment`, {
            user_id: userId,
            amount,
            paymentMethod: paymentMethod.id,
        });

        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        console.error('Error creating product payment:', error);

        return {
            success: false,
            error: error?.response?.data?.message || error?.message || 'Error',
        };
    }
};

export const onboardUserApi = async (userId) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/payment/onboard-user`, {
            user: userId,
        });

        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        console.error('Error onboarding user:', error);

        return {
            success: false,
            error: 'An error occurred while processing the payment.',
        };
    }
};