import React from 'react';
import { useNotification } from '../Context/NotificationContext';
import { useMediaQuery } from 'react-responsive';

const ToggleButton = ({ margintop}) => {
    const isDesktop = useMediaQuery({ minWidth: 1125 });

    const { selected, setSelected } = useNotification();

    const handleToggle = (option) => {
        setSelected(option);
    };

    // Adjusted container styles to ensure proper alignment and visualization
    const containerStyles = {
        display: 'flex',
        padding: '0.28125rem',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '1.40625rem',
        background: '#F9F9F9',
        marginTop: margintop ? margintop : isDesktop ? '1.75rem' : '',
        height: '2.75rem',
        position: 'relative',
        overflow: 'hidden',
        width: 'fit-content', // Ensure the container size fits its content
        marginLeft: '1rem'
    };

    const buttonStyles = {
        display: 'flex',
        padding: '0.5rem 0.625rem',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.625rem',
        borderRadius: '1.25rem',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        background: 'transparent',
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '1.25rem',
        color: '#6F6F6F',
        zIndex: 1,
    };

    const sliderWidth = selected === 'web' ? '40%' : '55%';
    const sliderLeft = selected === 'web' ? '0.28125rem' : '42%'; // Adjust based on the slider width

    const sliderStyles = {
        position: 'absolute',
        bottom: '0.28125rem',
        left: sliderLeft,
        width: sliderWidth,
        height: 'calc(100% - 0.5625rem)',
        borderRadius: '1.25rem',
        background: '#9803fa',
        transition: 'all 0.3s ease-out',
        zIndex: 0,
    };

    return (
        <div style={containerStyles}>
            <div style={sliderStyles}></div>
            <button
                style={{ ...buttonStyles, color: selected === 'web' ? '#FFF' : '#6F6F6F' }}
                onClick={() => handleToggle('web')}
            >
                Web-App
            </button>
            <button
                style={{ ...buttonStyles, color: selected === 'desktop' ? '#FFF' : '#6F6F6F' }}
                onClick={() => handleToggle('desktop')}
            >
                Desktop App
            </button>
        </div>
    );
};

export default ToggleButton;
