
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '../../../assets/images/delete-icon.svg'
import { useMediaQuery } from 'react-responsive';
const ConfirmationModal = ({ isOpen, onClose, onVerifyEmail, isVerifyingEmail, heading = 'Please verify your Email', buttonText = 'Verify Email', productId }) => {
    const isMobile = useMediaQuery({ maxWidth: 450 });

    const handleVerify = () => {
        onVerifyEmail(productId);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Terms and conditions Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                },
                content: {
                    width: isMobile ? '70%' : '25rem',
                    height: '17rem',
                    margin: 'auto',
                    borderRadius: '0.75rem',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: '1.5rem',
                    zIndex: 1001,
                },
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                    src={DeleteIcon}
                    alt=""
                    style={{
                        width: '3.5rem',
                        height: '3.5rem',
                        borderRadius: '1rem',
                        border: '8px solid var(--primary-50, #F9F5FF)',
                        background: 'var(--primary-100, #F4EBFF)',
                        marginBottom: '1.25rem',
                        justifyContent: 'center',
                    }}
                />
            </div>
            <h2
                style={{
                    color: '#46137C',
                    fontFamily: 'Poppins',
                    fontSize: '1.5rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {heading}
            </h2>
            <h2
                style={{
                    color: '#667085',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                Your product will be deleted permanently
            </h2>
            <div style={{ display: 'flex', marginTop: '0rem' }}>
                <button
                    onClick={onClose}
                    style={{
                        background: '#EDEDED', // Change background color based on the disabled state
                        color: '#8F8F8F',
                        width: '45%',
                        borderRadius: '0.4375rem',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        marginTop: '2rem',
                        border: 'none',
                        display: 'block',
                        padding: '8px',
                        cursor: 'pointer',
                        marginLeft: '1rem',
                        marginRight: '1rem'
                    }}
                >
                    Cancel
                </button>
                <button
                    onClick={handleVerify}
                    style={{
                        background: '#3F0065', // Change background color based on the disabled state
                        color: '#FFF',
                        width: '45%',
                        borderRadius: '0.4375rem',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        marginTop: '2rem',
                        border: 'none',
                        display: 'block',
                        padding: '8px',
                        cursor: 'pointer',
                    }}
                >
                    {isVerifyingEmail ? <CircularProgress color="secondary" style={{ width: '10px', height: '10px' }} /> : buttonText}
                </button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
