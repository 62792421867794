import { FlexDiv } from "../../assets/styles/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Grid, Typography, TextField, InputAdornment, IconButton, Container } from "@mui/material";
import { Textarea } from "@mui/joy";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import HeaderCover from '../../assets/images/header_cover.png';
import ProfilePicture from "../../assets/images/header_profile.png";
import UsernameIcon from '../../assets/images/username_icon.svg'
import UserProfileSeller from "../../components/SellerDashboard/UserProfileSeller";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { updateProfile, passwordReset } from "../../Services/User";
import { ManageProfileText, SaveBtn, ProductText, placeholderStyles, HeaderCoverStyle, TokenExpired, DisplayPicDiv, EditDisplayDiv, DisplayPicImg, UsernameDiv, UsernameIconDiv, UsernameText } from "../../components/SellerDashboard/Settings/constants";
import { MANAGE_PROFILE, EDIT_DISPLAY, FIRST_NAME, LAST_NAME, PHONE, EMAIL } from "../../constants/text";
import { handleRefreshToken } from "../../Services/Authentication";
import { handleUpdateUser } from "../../Services/User";
import { Helmet } from "react-helmet";
import FooterDivider from "../../components/FooterDivider";
import Footer from "../../components/Footer";
import NavBar from "../../components/Navbar";
import { HEADER_DATA } from "../BuyerProducts/constants";
const BuyerSettings = () => {
    const isDesktop = useMediaQuery({ maxWidth: 1125 });
    const isSmallDesktop = useMediaQuery({ minWidth: 900 });
    const isLargeLaptop = useMediaQuery({ minWidth: 720 });
    const isLaptop = useMediaQuery({ minWidth: 620 });
    const isMobile = useMediaQuery({ maxWidth: 650 });
    const [coverPicture, setCoverPicture] = useState();
    const [dataSubmission, setDataSubmission] = useState(false);
    const [selectedField, setSelectedField] = useState(null);
    const [isExpired, setIsExpired] = useState(true);
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [showPassword, setShowPassword] = useState(false)
    const [showNewPass, setShowNewPass] = useState(false)
    const [saveButtonClicked, setSaveButtonClicked] = useState(false);
    const navigate = useNavigate();
    const formData = new FormData();
    const storedUser = localStorage.getItem('Cornucopia-user');
    const user = JSON.parse(storedUser);
    const userId = user.response.id;
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const isGoogle = userData.response.isGoogle
    const initialValues = {
        firstName: userData.response.firstName,
        lastName: userData.response.lastName,
        phone: userData.response?.contact ? userData.response.contact : '',
        email: userData.response.email,
        currPass: '',
        newPass: '',
        displayPicture: userData?.response?.displayPicture ? userData?.response?.displayPicture : ProfilePicture,
        coverPicture: userData?.response?.coverPicture ? userData?.response?.coverPicture : HeaderCover
    };
    const [values, setValues] = useState(initialValues);
    const [selectedFile, setSelectedFile] = useState(null);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowNewPass = () => {
        setShowNewPass(!showNewPass)
    }
    const handleFieldChange = (fieldName, value) => {
        if (fieldName === 'phone') {
            const sanitizedValue = value.replace(/[^0-9+\-]/g, '');
            setValues({ ...values, [fieldName]: sanitizedValue });
        } else {
            setValues({ ...values, [fieldName]: value });
        }
    };

    const refreshtoken = async () => {
        try {
            const isTokenRefreshed = await handleRefreshToken();
            if (isTokenRefreshed) {
                setIsExpired(false);
            }
        } catch (error) {
            console.log('Error refreshing token:', error);
        }
    };

    const appendUserData = () => {
        if (values.firstName !== initialValues.firstName) {
            formData.append("firstName", values.firstName);
        }

        if (values.lastName !== initialValues.lastName) {
            formData.append("lastName", values.lastName);
        }

        if (values.phone !== initialValues.phone) {
            formData.append("contact", values.phone);
        }

        if (values.email !== initialValues.email) {
            formData.append("email", values.email);
        }

        if (values.bio !== initialValues.bio) {
            formData.append("bio", values.bio);
        }

        if (selectedFile) {
            formData.append('displayPicture', selectedFile);
        }
        if (coverPicture) {
            formData.append('coverPicture', coverPicture)
        }
    }

    const updateUser = async () => {
        try {
            await handleUpdateUser(userId)
        }
        catch (error) {
            console.log(error)
        }
    }
    const isPasswordValid = (password) => {
        // Validate password format using the specified pattern
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return passwordPattern.test(password);
    };

    const editProduct = async () => {
        const refreshExpiryToken = localStorage.getItem('refresh-token-expiry');
        const convertedTime = new Date(refreshExpiryToken);
        const currentTime = new Date();
        if (currentTime >= convertedTime) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('access-token-expiry');
            localStorage.removeItem('refresh-token-expiry');
            localStorage.removeItem('Cornucopia-user');
            navigate('/login');
            return;
        }
        setDataSubmission(true);
        appendUserData();
        const accessExpiryToken = localStorage.getItem('access-token-expiry');
        const isTokenExpired = TokenExpired(accessExpiryToken);
        if (isTokenExpired) {
            await refreshtoken();
        }
        let passMatch = false;
        let passCorrect = true;
        setSaveButtonClicked(true);
        if (values.currPass !== '' || values.newPass !== '') {
            if (values.currPass !== '' && values.newPass !== '') {
                const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
                if (!passwordPattern.test(values.currPass) || !passwordPattern.test(values.newPass)) {
                    setDataSubmission(false);
                    return;
                }
                try {
                    const response = await passwordReset(userData.response.email, values.currPass, values.newPass);
                } catch (error) {
                    console.error("Error updating profile", error);
                    setSnackbarMsg(error?.response?.data?.message);
                    setSnackbarSeverity('error');
                    setDataSubmission(false);
                    setOpen(true);
                    passCorrect = false;
                    return;
                }
            } else if (values.currPass !== '') {
                setSnackbarMsg('Enter the new password');
                setSnackbarSeverity('error');
                setOpen(true);
                setDataSubmission(false);
                passCorrect = false;
                return;
            } else {
                setSnackbarMsg('Enter the current password');
                setSnackbarSeverity('error');
                setOpen(true);
                setDataSubmission(false);
                passCorrect = false;
                return;
            }
        }
        if (passCorrect) {
            const response = await updateProfile(userId, formData)
            if (response) {
                try {
                    setDataSubmission(false);
                    setSnackbarMsg("Profile Updated Successfully");
                    setSnackbarSeverity("success");
                    setOpen(true);
                    updateUser();
                }
                catch (error) {
                    console.error("Error updating profile", error);
                    setDataSubmission(false);
                    console.error('Login error:', error);
                    if (error?.response?.message) {
                        setSnackbarMsg(error.response.message);
                        setSnackbarSeverity('error');
                    } else if (error?.message) {
                        setSnackbarMsg(error.message);
                        setSnackbarSeverity('error');
                    } else {
                        setSnackbarMsg('Error');
                        setSnackbarSeverity('error');
                    }
                    setOpen(true);
                };
            }
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };

    const handleProfileFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    }

    const handleButtonClick = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };

    const handleCoverFileChange = (event) => {
        const file = event.target.files[0];
        setCoverPicture(file);
    }

    const handleCoverButtonClick = () => {
        const fileInput = document.getElementById('fileInputCover');
        fileInput.click();
    };

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>

                <Helmet>
                    <title>Cornucopia AI | Settings</title>
                </Helmet>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        severity={snackbarSeverity}
                    >
                        {snackbarMsg}
                    </MuiAlert>
                </Snackbar>
                <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} authenticated={true} />
                <div style={{ marginBottom: '-200px', paddingRight: isDesktop ? '1.5rem' : '4rem', paddingLeft: isDesktop ? '1.37rem' : '4.37rem' }}>
                    <Grid container sx={{ marginTop: '2.44rem' }} paddingLeft={isLargeLaptop ? '' : '1.5rem'}>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <div style={{ position: 'relative' }}>
                                {!isMobile && (
                                    <img style={HeaderCoverStyle} src={coverPicture ? URL.createObjectURL(coverPicture) : initialValues.coverPicture} alt="" />
                                )}
                                {!isMobile &&
                                    <div
                                        style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                            margin: '1rem',
                                        }}
                                    >
                                        <EditIcon style={{ fontSize: '1.5rem', color: '#A1A0A0', cursor: 'pointer' }} onClick={handleCoverButtonClick} />
                                        <input
                                            type="file"
                                            id="fileInputCover"
                                            accept=".jpg, .jpeg, .png"
                                            style={{ display: 'none' }}
                                            onChange={handleCoverFileChange}
                                        />
                                    </div>
                                }
                                {!isMobile && (
                                    <DisplayPicDiv>
                                        <DisplayPicImg
                                            src={selectedFile ? URL.createObjectURL(selectedFile) : initialValues.displayPicture}
                                            alt=""
                                        />
                                        <button style={{ display: 'contents' }} onClick={handleButtonClick}>
                                            <EditDisplayDiv>
                                                {EDIT_DISPLAY}
                                            </EditDisplayDiv>
                                        </button>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept=".jpg, .jpeg, .png"
                                            style={{ display: 'none' }}
                                            onChange={handleProfileFileChange}
                                        />
                                        {isMobile && (
                                            <UsernameDiv>
                                                <UsernameIconDiv>
                                                    <img src={UsernameIcon} alt="" />
                                                </UsernameIconDiv>
                                                <UsernameText>
                                                    {userData?.response?.email ? '@' + userData.response.email.split('@')[0] : '@jennifer_lawrence122'}
                                                </UsernameText>
                                            </UsernameDiv>
                                        )}

                                    </DisplayPicDiv>
                                )}
                                {isMobile && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: '45%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            textAlign: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            marginTop: '7rem'
                                        }}
                                    >
                                        <DisplayPicImg
                                            src={selectedFile ? URL.createObjectURL(selectedFile) : initialValues.displayPicture}
                                            alt=""
                                        />
                                        <button style={{ display: 'contents' }} onClick={handleButtonClick}>
                                            <EditDisplayDiv>
                                                {EDIT_DISPLAY}
                                            </EditDisplayDiv>
                                        </button>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept=".jpg, .jpeg, .png"
                                            style={{ display: 'none' }}
                                            onChange={handleProfileFileChange}
                                        />
                                        <UsernameDiv style={{ position: 'initial' }}>
                                            <UsernameIconDiv>
                                                <img src={UsernameIcon} alt="" />
                                            </UsernameIconDiv>
                                            <UsernameText>
                                                {userData?.response?.email ? '@' + userData.response.email.split('@')[0] : '@jennifer_lawrence122'}
                                            </UsernameText>
                                        </UsernameDiv>
                                    </div>
                                )}

                                {!isMobile && (
                                    <UsernameDiv>
                                        <UsernameIconDiv>
                                            <img src={UsernameIcon} alt="" />
                                        </UsernameIconDiv>
                                        <UsernameText>
                                            {userData?.response?.email ? '@' + userData.response.email.split('@')[0] : '@jennifer_lawrence122'}
                                        </UsernameText>
                                    </UsernameDiv>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <FlexDiv style={{ flexDirection: 'column', marginTop: isMobile ? '18rem' : '8rem', gap: '2.5rem' }}>
                        <FlexDiv style={{ gap: '1.5rem', flexDirection: isMobile ? 'column' : 'row' }}>
                            <FlexDiv style={{ flexDirection: 'column', width: isMobile ? '100%' : '50%', }}>
                                <Typography sx={{ ...ProductText, color: selectedField === 'firstName' ? '#780093' : 'rgba(7, 7, 7, 0.67)' }}>{FIRST_NAME}</Typography>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    value={values.firstName}
                                    onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                    onFocus={() => setSelectedField('firstName')} // Replace 'firstName' with a unique identifier for each field
                                    onBlur={() => setSelectedField(null)}

                                    sx={{
                                        width: '100%',
                                        height: '2.875rem',
                                        borderRadius: '0.4375rem',
                                        '& input': {
                                            color: selectedField === 'firstName' ? '#9D40B1' : 'inherit', // Change text color on focus
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& input::placeholder': placeholderStyles,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    {selectedField === 'firstName' ? <CancelIcon /> : <EditIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FlexDiv>
                            <FlexDiv style={{ flexDirection: 'column', width: isMobile ? '100%' : '50%' }}>
                                <Typography sx={{ ...ProductText, color: selectedField === 'lastName' ? '#780093' : 'rgba(7, 7, 7, 0.67)' }}>{LAST_NAME}</Typography>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    value={values.lastName}
                                    onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                    onFocus={() => setSelectedField('lastName')}
                                    onBlur={() => setSelectedField(null)}
                                    sx={{
                                        width: '100%',
                                        height: '2.875rem',
                                        borderRadius: '0.4375rem',
                                        '& input': {
                                            color: selectedField === 'lastName' ? '#9D40B1' : 'inherit', // Change text color on focus
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& input::placeholder': placeholderStyles,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    {selectedField === 'lastName' ? <CancelIcon /> : <EditIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FlexDiv>
                        </FlexDiv>

                        <FlexDiv style={{ gap: '1.5rem', flexDirection: isMobile ? 'column' : 'row' }}>
                            <FlexDiv style={{ flexDirection: 'column', width: isMobile ? '100%' : '50%' }}>
                                <Typography sx={{ ...ProductText, color: selectedField === 'phone' ? '#780093' : 'rgba(7, 7, 7, 0.67)' }}>
                                    {PHONE}
                                </Typography>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    value={values.phone}
                                    onChange={(e) => handleFieldChange('phone', e.target.value)}
                                    onFocus={() => setSelectedField('phone')}
                                    onBlur={() => setSelectedField(null)}
                                    sx={{
                                        width: '100%',
                                        height: '2.875rem',
                                        borderRadius: '0.4375rem',
                                        '& input': {
                                            color: selectedField === 'phone' ? '#9D40B1' : 'inherit',
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& input::placeholder': placeholderStyles,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    {selectedField === 'phone' ? <CancelIcon /> : <EditIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FlexDiv>

                            <FlexDiv style={{ flexDirection: 'column', width: isMobile ? '100%' : '50%' }}>

                                <Typography sx={{ ...ProductText, color: selectedField === 'email' ? '#780093' : 'rgba(7, 7, 7, 0.67)' }}>{EMAIL}</Typography>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    value={values.email}
                                    onFocus={() => setSelectedField('email')}
                                    onBlur={() => setSelectedField(null)}
                                    disabled={true}
                                    sx={{
                                        width: '100%',
                                        height: '2.875rem',
                                        borderRadius: '0.4375rem',
                                        '& input': {
                                            color: selectedField === 'email' ? '#9D40B1' : 'inherit', // Change text color on focus
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            background: '#EDEDED', // Apply the background to the input area
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#9D40B1',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#9D40B1',
                                            },
                                            '& input::placeholder': placeholderStyles,
                                        },
                                    }}

                                />
                            </FlexDiv>
                        </FlexDiv>

                        <FlexDiv style={{ gap: '1.5rem', flexDirection: isMobile ? 'column' : 'row' }}>
                            <FlexDiv style={{ flexDirection: 'column', width: isMobile ? '100%' : '50%' }}>

                                <Typography sx={{ ...ProductText, color: selectedField === 'currPass' ? '#780093' : 'rgba(7, 7, 7, 0.67)' }}>Current Password</Typography>
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    value={values.currPass}
                                    helperText={saveButtonClicked && values.currPass && !isPasswordValid(values.currPass) ? 'Invalid password format' : ''}
                                    onChange={(e) => handleFieldChange('currPass', e.target.value)}
                                    onFocus={() => setSelectedField('currPass')}
                                    onBlur={() => setSelectedField(null)}
                                    disabled={isGoogle}
                                    sx={{
                                        width: '100%',
                                        height: '2.875rem',
                                        borderRadius: '0.4375rem',
                                        '& input': {
                                            color: selectedField === 'currPass' ? '#9D40B1' : 'inherit',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            background: isGoogle ? '#EDEDED' : '',
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& input::placeholder': placeholderStyles,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            values.currPass && (
                                                <InputAdornment position="end" onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                                                    {showPassword ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </InputAdornment>
                                            )
                                        ),
                                    }}
                                    FormHelperTextProps={{ sx: { color: 'red' } }}
                                />
                            </FlexDiv>

                            <FlexDiv style={{ flexDirection: 'column', width: isMobile ? '100%' : '50%' }}>
                                <Typography sx={{ ...ProductText, color: selectedField === 'newPass' ? '#780093' : 'rgba(7, 7, 7, 0.67)' }}>New Password</Typography>
                                <TextField
                                    type={showNewPass ? 'text' : 'password'}
                                    variant="outlined"
                                    value={values.newPass}
                                    helperText={saveButtonClicked && values.newPass && !isPasswordValid(values.newPass) ? 'Invalid password format' : ''}
                                    onChange={(e) => handleFieldChange('newPass', e.target.value)}
                                    onFocus={() => setSelectedField('newPass')}
                                    onBlur={() => setSelectedField(null)}
                                    disabled={isGoogle}
                                    sx={{
                                        width: '100%',
                                        height: '2.875rem',
                                        borderRadius: '0.4375rem',
                                        '& .MuiOutlinedInput-root': {
                                            background: isGoogle ? '#EDEDED' : '',
                                        },
                                        '& input': {
                                            color: selectedField === 'newPass' ? '#9D40B1' : 'inherit', // Change text color on focus
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& input::placeholder': placeholderStyles,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            values.newPass && (
                                                <InputAdornment position="end" onClick={toggleShowNewPass} style={{ cursor: 'pointer' }}>
                                                    {showNewPass ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </InputAdornment>
                                            )
                                        ),
                                    }}
                                    FormHelperTextProps={{ sx: { color: 'red' } }}
                                />
                            </FlexDiv>
                        </FlexDiv>
                    </FlexDiv>

                    <FlexDiv style={{ justifyContent: 'flex-end', marginRight: '1.5rem', marginTop: '3.2rem', marginBottom: '2rem' }}>
                        <button style={{ display: 'contents' }} onClick={editProduct}>
                            <SaveBtn>
                                {!dataSubmission ? 'Save' : <CircularProgress color="secondary" style={{ width: '27px', height: '25px' }} />}
                            </SaveBtn>
                        </button>
                    </FlexDiv>
                </div>
                <FooterDivider />
                <Footer />
            </Container>
        </>
    )
}
export default BuyerSettings;