import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ProfileImage from '../../assets/images/seller_profile.png'
import VerifiedCheck from '../../assets/images/verify_check.svg'
import { LoginBoxBtn } from '../../assets/styles/styles';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import {  CONTACT_ME, LOCATION, USER_NAME, EMAIL, VERIFIED_ACCOUNT, DESCRIPTION } from '../../constants/text';
import { cardStyle, profileImageStyle, nameStyle, professionStyle, tickStyle, verifiedtext, userNameStyle, usernameValueStyle, descriptionContentStyle, descriptionStyle, Line } from './constants'

const SellerInfo = ({ data = [] }) => {
    const navigate = useNavigate();
    const user = localStorage.getItem('Cornucopia-user');

    const navigateToChat = () => {
        if (user && data?.id) {
            navigate(`/inboxbuyer?sellerId=${data.id}`)
        } else {
            navigate('/login')
        }
    }

    return (
        <Card sx={{ ...cardStyle }}>
            <CardContent sx={{ padding: '2.25rem 0.5rem 0rem 0.5rem' }}>
                <div style={{ textAlign: 'center', width: '100%' }}>
                    <img src={data?.displayPicture && data?.displayPicture?.includes('cornucopia-uploads.s3.us-east-1.amazonaws') ? data.displayPicture : ProfileImage} alt="Seller Profile" style={{ ...profileImageStyle }} />
                </div>
                <div style={{ ...nameStyle, textAlign: 'center', width: '100%', marginTop: '0.5rem' }}>
                    {data ? data?.firstName + ' ' + data?.lastName : 'Alex Johnson'}
                </div>
                <div style={{ ...professionStyle, textAlign: 'center', width: '100%' }}>
                    {data ? (data?.designation ? data?.designation : 'Web Developer') : 'Web Developer'}
                </div>
                {/* <div style={{ display: 'flex', marginLeft: '1rem', marginTop: '1.06rem', justifyContent: 'center', width: '100%' }}>
                    <img src={FiveStar} alt="Seller Profile" style={{ ...starImageStyle }} />
                    <span style={{ ...ratingTextStyle, marginLeft: '0.86rem' }}>{RATINGS_AVG}</span>
                </div>
                <div style={{ ...productReviews, textAlign: 'center', width: '100%', marginTop: '0.95rem' }}>
                    {PRODUCT_REVIEWS_COUNT}
                </div> */}
                <div style={{ display: 'flex', marginTop: '1.44rem', justifyContent: 'center', width: '100%' }}>
                    <img src={VerifiedCheck} alt="Seller Profile" style={{ ...tickStyle }} />
                    <span style={{ ...verifiedtext, marginLeft: '0.25rem', marginTop: '0.3rem' }} ><b>{VERIFIED_ACCOUNT}</b></span>
                </div>
                <LoginBoxBtn
                    style={{ width: '100%', marginTop: '0.56rem', justifyContent: 'center' }}
                    onClick={navigateToChat}
                >
                    {CONTACT_ME}
                </LoginBoxBtn>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1.75rem' }}>
                    <div style={{ display: 'flex' }}>
                        <PersonIcon style={{ color: 'rgba(116, 116, 116, 1)' }} />
                        <span style={{ ...userNameStyle, marginLeft: '0.8rem', marginTop: '0.2rem' }} >{USER_NAME}</span>
                        <div style={{ ...usernameValueStyle, marginLeft: 'auto', marginTop: '0.2rem' }}>{data ? '@' + data.email.split('@')[0] : '@alex_johson23'}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <LocationOnIcon style={{ color: 'rgba(116, 116, 116, 1)', marginTop: '1rem' }} />
                        <span style={{ ...userNameStyle, marginLeft: '0.8rem', marginTop: '1.2rem' }} >{LOCATION}</span>
                        <div style={{ ...usernameValueStyle, marginLeft: 'auto', marginTop: '1.2rem' }}>{data?.address?.country ? data.address.country : 'United States'}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <EmailIcon style={{ color: 'rgba(116, 116, 116, 1)', marginTop: '1rem' }} />
                        <span style={{ ...userNameStyle, marginLeft: '0.8rem', marginTop: '1.2rem' }} >{EMAIL + ':'}</span>
                        <div style={{ ...usernameValueStyle, marginLeft: 'auto', marginTop: '1.2rem' }}>{data?.email ? data.email : 'alex.johnson@cornupia.com'}</div>
                    </div>
                    {/* <div style={{ display: 'flex' }}>
                        <WatchLaterIcon style={{ color: 'rgba(116, 116, 116, 1)', marginTop: '1rem' }} />
                        <span style={{ ...userNameStyle, marginLeft: '0.8rem', marginTop: '1.2rem' }} >{AVG_RESPONSE_TIME}</span>
                        <div style={{ ...usernameValueStyle, marginLeft: 'auto', marginTop: '1.2rem' }}>{ONE_HOUR}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <SwapHorizIcon style={{ color: 'rgba(116, 116, 116, 1)', marginTop: '1rem' }} />
                        <span style={{ ...userNameStyle, marginLeft: '0.8rem', marginTop: '1.2rem' }} >{LAST_SALE}</span>
                        <div style={{ ...usernameValueStyle, marginLeft: 'auto', marginTop: '1.2rem' }}>{TWO_DAYS_AGO}</div>
                    </div> */}
                </div>
                <Line />
                <Card sx={{
                    width: '100%',
                    borderRadius: '0.3125rem',
                    border: '1px solid #D9D9D9',
                    marginTop: '2rem',
                    '& .MuiCardContent-root': { padding: '1rem 1.06rem 1.44rem 2.06rem', }
                }}>
                    <CardContent>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={descriptionStyle}>{DESCRIPTION}</div>
                            <div style={{ ...descriptionContentStyle, marginTop: '1rem' }}>
                                {data.bio ? data.bio : "Tech enthusiast turned seller! Offering cutting-edge gadgets and solutions to elevate your tech experience. Join the innovation journey!"}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </CardContent>
        </Card>
    );
};

export default SellerInfo;
