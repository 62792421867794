import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SadFace from '../../assets/images/sad_face.svg';
import GreenSmiley from '../../assets/images/green_smiley.svg'
import Rating from '@mui/material/Rating';
import { format } from 'date-fns';
import ReviewAvatar from '../ProductDetaisSeller/ReviewAvatar';
const customIcon = (
    <span style={{ color: '#E4A951', fontSize: '1rem' }}>
        &#9733;
    </span>
);

const customEmptyIcon = (
    <span style={{ color: 'rgba(217, 217, 217, 1)', fontSize: '1rem' }}>
        &#9733;
    </span>
);

const nameStyle = {
    color: '#58006B',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

const dateStyle = {
    color: '#8F8F8F',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

const ratingStyle = {
    color: '#000',
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontWeight: 500,
    lineHeight: 'normal',
};

const commentContent = {
    color: '#888',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
};


const ReviewCard = ({ alignment, rating, comment, fullName, date }) => {
    const formattedDate = format(new Date(date), 'dd MMM yyyy');

    const cardStyles = {
        width: alignment === 'center' ? '24.25rem' : '17.59656rem',
        borderRadius: '0.5rem',
        background: '#FFF',
        boxShadow: alignment === 'center' ? '0px 0px 32px 0px rgba(0, 0, 0, 0.14)' : '0px 0px 4px 0px rgba(0, 0, 0, 0.14)',
        boxSizing: 'border-box',
        paddingLeft: alignment === 'center' ? '0.87rem' : '0.36rem',
        paddingTop: alignment === 'center' ? '1rem' : '0.5rem',
    };

    const imgStyle = {
        width: alignment === 'center' ? '3.625rem' : '2.63044rem',
        height: alignment === 'center' ? '3.1875rem' : '2.31294rem',
        borderRadius: 'border-radius: 0.3125rem;'
    };

    const renderEmotion = () => {
        if (rating <= 2.5) {
            return (
                <img
                    style={{
                        marginLeft: '4rem',
                        width: '1rem',
                        height: '1rem',
                    }}
                    src={SadFace}
                    alt="Sad Face"
                />
            );
        } else {
            return (
                <img
                    style={{
                        marginLeft: '4rem',
                        width: '1rem',
                        height: '1rem',
                    }}
                    src={GreenSmiley}
                    alt="Green Smiley"
                />
            );
        }
    };

    return (
        <Card style={cardStyles}>
            <CardContent>
                <div style={{ display: 'flex' }}>
                    {/* <img style={imgStyle} src={ReviewImage} alt="" /> */}
                    <ReviewAvatar name={fullName} width='2.63rem' height='2.31rem' fontSize='0.5rem' topMargin='0rem'/>
                    <div style={{ flexDirection: 'column', marginLeft: alignment === 'center' ? '0.81rem' : '0.57rem' }}>
                        <div style={{
                            ...nameStyle, fontSize: alignment === 'center' ? '0.75rem' : '0.625rem',
                        }}>{fullName}</div>
                        <div style={{ ...dateStyle, fontSize: alignment === 'center' ? '0.5625rem' : '0.375rem' }}>{formattedDate}</div>
                    </div>
                    <div style={{ ...ratingStyle, marginLeft: alignment === 'center' ? '2.13rem' : '1.4rem', fontSize: alignment === 'center' ? '0.75rem' : '0.5625rem' }}>
                        {rating}/5
                    </div>
                    <div style={{ flexDirection: 'column', marginLeft: alignment === 'center' ? '0.94rem' : '0.44rem', marginTop: '-0.5rem' }}>
                        <Rating
                            value={rating}
                            precision={0.5}
                            icon={customIcon}
                            emptyIcon={customEmptyIcon}
                            readOnly
                            style={{ marginTop: '-0.5rem' }}
                        />
                        <div style={{marginLeft:'-0.5rem'}}>
                            {renderEmotion()}
                        </div>
                    </div>
                </div>
                <div style={{ ...commentContent, fontSize: alignment === 'center' ? '0.75rem' : '0.5625rem', marginTop: alignment === 'center' ? '1.69rem' : '1.22rem' }}>
                    {comment}
                </div>
            </CardContent>
        </Card>
    );
};

ReviewCard.propTypes = {
    alignment: PropTypes.oneOf(['center']),
};

export default ReviewCard;
