import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Typography, Button } from "@mui/material";
import Download from '../../assets/images/download.svg';
import { useMediaQuery } from "react-responsive";
import { FlexDiv } from "../../assets/styles/styles";
import axios from "axios";
import { IP_ADD } from "../../constants/api";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";

const modalContentStyle = {
    background: '#ffff',
    padding: '1.5rem',
    borderRadius: '1.75rem',
    textAlign: 'center',
    position: 'relative',
    width: '25rem'
};

const clickToDownload = {
    color: 'var(--gray-500, #667085)',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
};

const downloadLinkStyle = {
    color: '#46137C',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop: '2rem',
};

const imageStyle = {
    borderRadius: '1.75rem',
    border: '8px solid var(--primary-50, #F9F5FF)',
    backgroundColor: 'var(--primary-100, #F4EBFF)',
};

const downloadButtonStyle = {
    display: 'flex',
    padding: '0.625rem 1.125rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    flex: '1 0 0',
    borderRadius: '0.4375rem',
    background: '#3F0065',
    marginTop: '2rem'
};

const downloadTextStyle = {
    color: 'var(--White, #FFF)',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.5rem',
    textTransform: 'none',
};

const DownloadModal = ({ open, onClose, downloadLink, web = false, heading, subHeading, buttonText, productId }) => {
    const isMobile = useMediaQuery({ maxWidth: 450 });
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const userEmail = userData.response.email;
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
        setSnackbarMsg('');
    };

    const handleResendEmail = () => {
        setLoading(true);
        axios.post(`${IP_ADD}/api/auth/resend-onboarding-email`, {
            productId: productId,
            email: userEmail
        })
            .then(response => {
                setLoading(false);
                setSnackbarMsg('Email resent successfully');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            })
            .catch(error => {
                setLoading(false);
                setSnackbarMsg('Error resending email');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            });
    };

    const handleButtonClick = () => {
        onClose();
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <Modal open={open} onClose={onClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ ...modalContentStyle, width: isMobile ? '80%' : '25rem' }}>
                    <img src={Download} alt="Download" style={imageStyle} />
                    <Typography variant="h6" gutterBottom style={downloadLinkStyle}>
                        {heading}
                    </Typography>
                    <Typography variant="body1" style={clickToDownload}>
                        {subHeading}
                    </Typography>
                    <FlexDiv style={{ gap: web ? '0.75rem' : '' }}>
                        {web &&
                            <Button
                                variant="contained"
                                style={{
                                    ...downloadButtonStyle,
                                    width: web ? '50%' : '',
                                    color: '#8F8F8F', // Set the text color
                                    background: '#EDEDED',
                                    border: ' 1px solid #E2E2E2'
                                }}
                                onClick={handleResendEmail} // Handle resend email button click
                            >
                                {loading ?
                                    <CircularProgress color="secondary" style={{ width: '10px', height: '10px' }} />
                                    :
                                    <Typography variant="body1" style={{ ...downloadTextStyle, color: '#8f8f8f' }}>
                                        Resend Email
                                    </Typography>
                                }
                            </Button>
                        }
                        {!web &&
                            <Button
                                variant="contained"
                                color="primary"
                                href={downloadLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ ...downloadButtonStyle, width: web ? '50%' : '' }}
                                onClick={handleButtonClick} // Handle download button click
                            >

                                <Typography variant="body1" style={downloadTextStyle}>
                                    {buttonText}
                                </Typography>

                            </Button>
                        }
                        {web &&
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ ...downloadButtonStyle, width: web ? '50%' : '' }}
                                onClick={handleButtonClick} // Handle download button click
                            >

                                <Typography variant="body1" style={downloadTextStyle}>
                                    {buttonText}
                                </Typography>

                            </Button>
                        }
                    </FlexDiv>
                </div>
            </Modal>
        </>
    );
};

export default DownloadModal;