import styled from '@emotion/styled';
import { Card } from '@mui/material';

const Text = styled.div`
  font-family: Poppins;
  text-align: center;
`;

export const AlexJohnson = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 6.5rem;
  margin-bottom: 0.25rem;
`;

export const Designer = styled(Text)`
  color: #9d40b1;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  width: auto;
`;

export const LoremIpsum = styled(Text)`
  color: #3f3f3f;
  font-size: 0.75rem;
  font-weight: 400;
`;

export const StyledCard = styled(Card)`
  position: relative;
  border-radius: 0.5625rem;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${(props) => (props.isMobileTwo ? '4rem' : '1.5rem')};
  margin-top: 5rem;
  overflow: visible;
  height: ${(props) => (props.isMobileTwo ? '14rem' : '19rem')};
`;

export const CardImage = styled.img`
  position: absolute;
  top: ${(props) => (props.isMobileTwo ? '-5rem' : '-2.5rem')};
  left: 50%;
  transform: translateX(-50%);
  width: 9.0625rem;
  height: 9.0625rem;
  border-radius: 9.0625rem;
`;

export const ProductOwnerDiv = styled.div`
    padding:1rem;
    display:flex;
    flex-direction:column;
    align-items: center;
    height: max-content;
    border-radius: 0.5625rem;
    background: linear-gradient(180deg, #605568 0%, rgba(70, 69, 71, 0.53) 100%);
    backdrop-filter: blur(10px);
    margin-top: -4rem;
    max-width:12rem;
`;

export const ProductOwnerHeader = styled.h1`
    color: #FFF;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 700;
    text-align:center;
`;

export const ProductOwnerText = styled.div`
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    margin-top: 0.1rem;
`;