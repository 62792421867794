export const ConvertCentToDollar = (cents) => {
  cents = cents.replace("$", "")
  let intVal = parseInt(cents);
  let dollar = intVal / 100;
  let dollarString = dollar.toString();
  return "$" + dollarString;
};

export const cellArray = (timesToDisplay) => new Array(timesToDisplay).fill(null);

export const CategoryFilters = [
  'Business',
  'Learning',
  'Analytics',
  'NLP',
  'Computer Vision',
  'Speech Processing',
  'Audio Processing',
  'E-Commerce',
  'IoT',
  'Gaming',
  'Robotics',
  'Augmented Reality',
  'Virtual Reality',
  'Education Technology',
  'Predictive Maintenance',
  'Recommender Systems',
  ];

export const convertTo2D = (inputArray, subarrayLength) => {
  const result = [];
  for (let i = 0; i < inputArray.length; i += subarrayLength) {
    result.push(inputArray.slice(i, i + subarrayLength));
  }
  return result;
}
