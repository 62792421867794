
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import CircularProgress from '@mui/material/CircularProgress';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMediaQuery } from 'react-responsive';

const LogoutModal = ({ isOpen, onClose, onVerifyEmail, isVerifyingEmail, heading = 'Please verify your Email', subHeading = 'Are you sure you want to log out?', buttonText = 'Verify Email', showHeading = true, height = '17rem' }) => {
    const isMobile = useMediaQuery({ maxWidth: 450 });
    const handleVerify = () => {
        onVerifyEmail();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Terms and conditions Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                },
                content: {
                    width: isMobile ? '70%' : '25rem',
                    height: height,
                    margin: 'auto',
                    borderRadius: '0.75rem',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: '1.5rem',
                    zIndex: 1001,
                },
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                    style={{
                        width: '3.5rem',
                        height: '3.5rem',
                        borderRadius: '5rem',
                        border: '8px solid var(--primary-50, #F9F5FF)',
                        background: 'var(--primary-100, #F4EBFF)',
                        marginBottom: '1.25rem',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <LogoutIcon
                        sx={{
                            width: '1.75rem',
                            height: '1.75rem',
                            color: '#46137C',
                            marginLeft: '0.5rem'
                        }}
                    />
                </div>
            </div>
            {showHeading &&
                <h2
                    style={{
                        color: '#46137C',
                        fontFamily: 'Poppins',
                        fontSize: '1.5rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}
                >
                    {heading}
                </h2>
            }
            <div style={{ display: 'flex', justifyContent:'center'}}>
                <h2
                    style={{
                        color: '#667085',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center'

                    }}
                >
                    {subHeading}
                </h2>
            </div>
            <div style={{ display: 'flex', marginTop: '0rem' }}>
                <button
                    onClick={onClose}
                    style={{
                        background: '#EDEDED',
                        color: '#8F8F8F',
                        width: '45%',
                        borderRadius: '0.4375rem',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        marginTop: '2rem',
                        border: 'none',
                        display: 'block',
                        padding: '8px',
                        cursor: 'pointer',
                        marginLeft: '1rem',
                        marginRight: '1rem'
                    }}
                >
                    Cancel
                </button>
                <button
                    onClick={handleVerify}
                    style={{
                        background: '#3F0065', // Change background color based on the disabled state
                        color: '#FFF',
                        width: '45%',
                        borderRadius: '0.4375rem',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        marginTop: '2rem',
                        border: 'none',
                        display: 'block',
                        padding: '8px',
                        cursor: 'pointer',
                        transition: 'opacity 0.3s',
                        opacity: 1,
                    }}
                    onMouseOver={(e) => e.target.style.opacity = 0.8}
                    onMouseOut={(e) => e.target.style.opacity = 1}
                >
                    {isVerifyingEmail ? <CircularProgress color="secondary" style={{ width: '10px', height: '10px' }} /> : buttonText}
                </button>
            </div>
        </Modal>
    );
};

export default LogoutModal;
