import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const ImageSlider = ({ images }) => {
    const [index, setIndex] = useState(0);

    const handleSwipe = (direction) => {
        if (direction === 'left' && index < images.length - 1) {
            setIndex(index + 1);
        } else if (direction === 'right' && index > 0) {
            setIndex(index - 1);
        }
    };

    const props = useSpring({
        transform: `translateX(-${index * 100}%)`,
    });

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height:'19rem',
                overflow: 'hidden',
            }}
            onTouchStart={(e) => {
                const touchStartX = e.touches[0].clientX;

                const handleTouchMove = (e) => {
                    const touchEndX = e.touches[0].clientX;
                    const deltaX = touchEndX - touchStartX;

                    if (deltaX > 50) {
                        handleSwipe('right');
                    } else if (deltaX < -50) {
                        handleSwipe('left');
                    }
                };

                const handleTouchEnd = () => {
                    document.removeEventListener('touchmove', handleTouchMove);
                    document.removeEventListener('touchend', handleTouchEnd);
                };

                document.addEventListener('touchmove', handleTouchMove);
                document.addEventListener('touchend', handleTouchEnd);
            }}
        >
            <animated.div
                style={{
                    display: 'flex',
                    transition: 'transform 0.3s ease-out',
                    ...props,
                }}
            >
                {images.map((src, i) => (
                    <img
                        key={i}
                        src={src}
                        alt={`SwipeableImage-${i}`}
                        style={{ width: '100%', flex: '0 0 100%', height: '16.5rem', borderRadius: '0.375rem' }}
                    />
                ))}
            </animated.div>
            <div
                style={{
                    position: 'absolute',
                    marginTop:'2rem',
                    bottom: '10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    display: 'flex',
                    zIndex: 1,
                }}
            >
                {images.map((_, i) => (
                    <div
                        key={i}
                        style={{
                            width: i === index ? '0.5rem' : '0.375rem',
                            height: i === index ? '0.5rem' : '0.375rem',
                            borderRadius: '50%',
                            marginTop: i === index ? '-0.1rem' : '',
                            margin: '0 5px',
                            background: i === index ? '#58006B' : 'gray',
                        }}
                        onClick={() => setIndex(i)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;
