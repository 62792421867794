import Avatar from "@mui/material/Avatar";

const AvatarLogo = ({ width = "1.9rem", height = "1.9rem", mobile = false, name='Felix Johnson' }) => {
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));

    return (
        <Avatar
            sx={{
                bgcolor: '#ad4ead',
                width: width,
                height: height,
                fontSize: '0.8rem',
                margin: mobile ? '' : '0.4rem 0rem 0rem 0.6rem'
            }}
        >
            {userData ? `${userData.response.firstName[0]}${userData.response.lastName[0]}` : `${name[0]}${name[3]}`}
        </Avatar>

    );
};
export default AvatarLogo;