import axios from "axios";
import { IP_ADD } from "../constants/api";

export const clearNotification = async (notificationId) => {
    try {
        const response = await axios.delete(
            `${IP_ADD}/api/notification/delete-notification/${notificationId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error clearing notification:", error);
        throw error;
    }
};

export const clearAllNotifications = async (userId) => {
    try {
        const response = await axios.delete(
            `${IP_ADD}/api/notification/delete-all`,
            { data: { userId } }
        );
        return response.data;
    } catch (error) {
        console.error("Error clearing all notifications:", error);
        throw error;
    }
};

export const markNotificationAsRead = async (notificationId, userId) => {
    try {
        const response = await axios.patch(
            `${IP_ADD}/api/notification/mark-notification/${notificationId}`,
            { userId }
        );
        return response.data;
    } catch (error) {
        console.error("Error marking notification as read:", error);
        throw error;
    }
};

export const markAllNotificationsAsReadApi = async (userId) => {
    try {
        const response = await axios.patch(
            `${IP_ADD}/api/notification/mark-all-as-read`,
            { userId }
        );
        return response.data;
    } catch (error) {
        console.error("Error marking all notifications as read:", error);
        throw error;
    }
};

export const fetchNotificationsApi = async (userId) => {
    try {
        const response = await axios.get(`${IP_ADD}/api/notification/notifications/${userId}`, {});

        return {
            success: true,
            data: response.data.response,
        };
    } catch (error) {
        console.log("Error in notification fetching", error);

        return {
            success: false,
            error: 'Error fetching notifications.',
        };
    }
};

export const deleteNotificationApi = async (notificationId) => {
    try {
        const response = await axios.delete(`${IP_ADD}/api/notification/delete-notification/${notificationId}`);

        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        console.log("Error deleting notification", error);

        return {
            success: false,
            error: 'Error deleting notification.',
        };
    }
};

export const sendNotificationApi = async (recipientId, message, senderId, notificationType, productId) => {
    try {
        // Construct the request body
        const requestBody = {
            recipient: recipientId,
            message: message,
            sender: senderId,
            notificationType: notificationType,
        };

        // Include productId in the request body if it is provided
        if (productId != null) {
            requestBody.product = productId;
        }

        // Make the API request
        const response = await axios.post(`${IP_ADD}/api/notification/send-notification`, requestBody);

        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        console.log("Error in notification", error);
        return {
            success: false,
            error: 'Error sending notification.',
        };
    }
};
