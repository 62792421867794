import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import UploadVideo from '../../assets/images/upload_video.svg';
import ClearIcon from '@mui/icons-material/Clear';


const cardStyle = {
    width: '100%',
    height: '8.875rem',
    borderRadius: '0.4375rem',
    border: '1px dashed #9D40B1',
    background: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
};

const textStyles = {
    color: '#9D40B1',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 'normal',
};

const imageStyle = {
    width: '1.1875rem',
    height: '1.1875rem',
    marginBottom: '0.25rem',
};

const VideoUploadBox = ({ selectedVideo = null, setSelectedVideo }) => {
    const [thumbnailUrl, setThumbnailUrl] = useState(selectedVideo);

    const handleVideoSelect = (event) => {
        const file = event.target.files[0];
        setSelectedVideo(file);

        // Create a URL for the selected video to be used as the video source
        const videoUrl = URL.createObjectURL(file);
        setThumbnailUrl(videoUrl);
    };

    const clearSelectedVideo = () => {
        setSelectedVideo(null);
        setThumbnailUrl(null);
    };
    return (
        <Card elevation={0} style={cardStyle}>
            <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                {selectedVideo ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <video controls width="100" height="100" style={{ borderRadius: '0.4375rem' }}>
                            <source src={thumbnailUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div style={{ maxWidth: '20rem', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '3rem' }}>
                            {selectedVideo && selectedVideo.name ? (
                                selectedVideo.name
                            ) : (
                                selectedVideo
                            )}
                        </div>
                        <ClearIcon onClick={clearSelectedVideo} style={{ marginLeft: '3rem' }} />
                    </div>
                ) : (
                    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <input
                            type="file"
                            accept=".mp4"
                            style={{ display: 'none' }}
                            onChange={handleVideoSelect}
                        />

                        <img src={UploadVideo} alt="Upload Video" style={imageStyle} />
                        <div style={textStyles}>Click to upload</div>
                        <div style={{ ...textStyles, color: '#888', marginTop: '0' }}>or drag and drop</div>
                    </label>
                )}
            </CardContent>
        </Card>
    );
};

export default VideoUploadBox;