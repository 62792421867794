import React, { useState } from "react"
import { FlexDiv } from "../../../assets/styles/styles";
import { CodeSection, DetailText, Heading, apiResponse, codeContent } from "./constants";
import CopyIcon from '../../../assets/images/copy-icon.png'
import UserProfileSeller from "../UserProfileSeller";
import { useMediaQuery } from "react-responsive";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Integration = () => {

    const isSmallScreen = useMediaQuery({ maxWidth: 720 })
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedLanguage, setSelectedLanguage] = useState('javascript');

    const copyResponseToClipboard = () => {
        navigator.clipboard.writeText(apiResponse)
            .then(() => {
                setSnackbarMsg('Copied to clipboard');
                setSnackbarSeverity('success');
                setOpen(true);
            })
            .catch((error) => {
                console.error('Error copying code to clipboard:', error);
            });
    }

    const copyCodeToClipboard = () => {
        // Copy codeContent[selectedLanguage] to clipboard
        navigator.clipboard.writeText(codeContent[selectedLanguage])
            .then(() => {
                setSnackbarMsg('Copied to clipboard');
                setSnackbarSeverity('success');
                setOpen(true);
            })
            .catch((error) => {
                console.error('Error copying code to clipboard:', error);
            });
    };

    const copyUrlToClipboard = () => {
        // Copy codeContent[selectedLanguage] to clipboard
        navigator.clipboard.writeText(`url = "https://backend.cornucopia-ai.com/api/auth/web/login"`)
            .then(() => {
                setSnackbarMsg('Copied to clipboard');
                setSnackbarSeverity('success');
                setOpen(true);
            })
            .catch((error) => {
                console.error('Error copying code to clipboard:', error);
            });
    };

    const handleSnackbarClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <FlexDiv style={{ flexDirection: 'column' }}>
                <FlexDiv style={{ marginTop: '1.2rem', justifyContent: 'flex-end' }}>
                    <UserProfileSeller />
                </FlexDiv>
                <FlexDiv style={{ flexDirection: 'column', justifyContent: isSmallScreen ? 'center' : '', alignItems: isSmallScreen ? 'center' : '' }}>
                    <Heading style={{ marginTop: isSmallScreen ? '2rem' : '' }}>Integrate the Cornucopia AI api into your website</Heading>
                    <DetailText>The following code snippets contain the api code that can help you integrate Cornucopia AI with your website.</DetailText>

                    {/* <DetailText style={{ marginTop: '2rem', fontWeight:'800' }}>Base Url</DetailText> */}
                    <CodeSection style={{ marginTop: '0.5rem', marginBottom: '0rem', padding: '0px' }}>
                        <FlexDiv style={{ justifyContent: 'space-between', gap: '0.5rem', padding: '20px 20px 0px 20px', background: '#474e5a', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                            {/* <select
                                value={selectedLanguage}
                                onChange={(event) => setSelectedLanguage(event.target.value)}
                                style={{
                                    outline: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    backgroundColor: '#D9D9D9',
                                    width: '105px',
                                    height: '35px',
                                    borderRadius: '8px',
                                    fontFamily: 'Roboto',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    textAlign: 'center',
                                    borderRight: '16px solid transparent',
                                }}
                            >
                                <option value="flask">Flask</option>
                                <option value="django">Django</option>
                                <option value="javascript">React</option>
                            </select> */}
                            <DetailText style={{ fontWeight: '800', color: '#fff' }}>Base Url</DetailText>
                            <button
                                style={{
                                    width: '42px',
                                    height: '35px',
                                    borderRadius: '8px',
                                    backgroundColor: '#D9D9D9',
                                    cursor: 'pointer',
                                    outline: 'none',
                                    border: 'none',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '-0.5rem'
                                }}
                                onClick={copyUrlToClipboard}
                            >
                                <img src={CopyIcon} alt="Copy" />
                            </button>
                        </FlexDiv>
                        <pre style={{ padding: '20px 20px 5px 20px' }}>
                            <SyntaxHighlighter language='python' style={vscDarkPlus} customStyle={{ padding: '0rem', margin: '0rem' }}>
                                url = "https://backend.cornucopia-ai.com/api/auth/web/login"
                            </SyntaxHighlighter>
                        </pre>

                    </CodeSection>

                    <Heading style={{ marginTop: '2rem', fontSize: '20px' }}>Cornucopia API Integration Guide</Heading>
                    <DetailText style={{ fontSize: '1rem', marginBottom: '0rem' }}>This API facilitates seamless communication between your website and Cornucopia's system for user authentication. You can integrate this API into your existing login functionality or login page.
                        <br /><br />
                        <span style={{ color: '#3F0065', fontSize: '18px' }}> Integration Steps:</span><br />
                        <ol>
                            <li style={{ marginTop: '0.5rem' }}>
                                <span style={{ color: '#3F0065', fontSize: '16px', marginTop: '0.5rem' }}>Obtain Product ID:</span><br />
                                <div style={{ marginTop: '0.5rem' }}>Use the unique product ID assigned to you after listing your product on Cornucopia. This product ID is also available in the Product ID column of the Product table as well.</div>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}>
                                <span style={{ color: '#3F0065', fontSize: '18px' }}>API Request Parameters:</span><br />
                                <div style={{ marginTop: '0.5rem' }}>Include the following parameters in your API request:</div>
                                <ul style={{ marginTop: '0.5rem' }}>
                                    <li style={{ marginTop: '0.5rem' }}><code style={{ fontSize: '18px' }}>product_id</code>: Use the unique product ID assigned to you after listing your product.</li>
                                    <li style={{ marginTop: '0.5rem' }}><code style={{ fontSize: '18px' }}>email</code>: Enter the email address the user provides on your login page.</li>
                                    <li style={{ marginTop: '0.5rem' }}><code style={{ fontSize: '18px' }}>password</code>: Use the password the user provides on your login page.</li>
                                </ul>
                            </li>
                            <li style={{ marginTop: '0.5rem' }}>
                                <span style={{ color: '#3F0065', fontSize: '18px' }}>Example Integration Scenario:</span><br />
                                <ul style={{ marginTop: '0.5rem' }}>
                                    <li style={{ marginTop: '0.5rem' }}>Add a button to your login page titled "Login with Cornucopia."</li>
                                    <li style={{ marginTop: '0.5rem' }}>When the user enters their credentials on your login page and clicks the "Login with Cornucopia" button, initiate the API call.</li>
                                    <li style={{ marginTop: '0.5rem' }}>Construct the API request with the necessary parameters (product_id, email, and password).</li>
                                    <li style={{ marginTop: '0.5rem' }}>Upon successful authentication, proceed with the user login process on your website.</li>
                                </ul>
                            </li>
                        </ol>
                    </DetailText>

                    {/* <DetailText style={{ marginTop: '2rem', fontWeight: '800' }}>Making Request</DetailText> */}
                    <Heading style={{ marginTop: '0.5rem', fontSize: '1.2rem' }}>Making Request</Heading>
                    <DetailText style={{ fontSize: '1rem' }}>Here's an example of how to make an API request to the Cornucopia platform. Remember to replace the placeholder values in the payload with your own information as mentioned above.</DetailText>
                    <CodeSection style={{ marginTop: '0.5rem', padding: '0px', marginBottom: '1.5rem' }}>
                        <FlexDiv style={{ justifyContent: 'space-between', padding: '20px 20px 0px 20px', background: '#474e5a', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                            <DetailText style={{ fontWeight: '800', color: '#fff' }}>Making Request</DetailText>
                            <FlexDiv style={{ justifyContent: 'flex-end', gap: '0.5rem', marginTop: '-0.5rem' }}>
                                <select
                                    value={selectedLanguage}
                                    onChange={(event) => setSelectedLanguage(event.target.value)}
                                    style={{
                                        outline: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        backgroundColor: '#D9D9D9',
                                        width: '105px',
                                        height: '35px',
                                        borderRadius: '8px',
                                        fontFamily: 'Roboto',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '21px',
                                        textAlign: 'center',
                                        borderRight: '16px solid transparent',
                                    }}
                                >
                                    <option value="flask">Flask</option>
                                    <option value="django">Django</option>
                                    <option value="javascript">React</option>
                                    <option value="node">Node js</option>
                                </select>
                                <button
                                    style={{
                                        width: '42px',
                                        height: '35px',
                                        borderRadius: '8px',
                                        backgroundColor: '#D9D9D9',
                                        cursor: 'pointer',
                                        outline: 'none',
                                        border: 'none',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    onClick={copyCodeToClipboard}
                                >
                                    <img src={CopyIcon} alt="Copy" />
                                </button>
                            </FlexDiv>
                        </FlexDiv>
                        <pre style={{ marginTop: '1rem', padding: '20px 20px 5px 20px' }}>
                            <SyntaxHighlighter language={selectedLanguage === 'flask' || selectedLanguage === 'django' ? 'python' : selectedLanguage === 'node' ? 'javascript' : selectedLanguage} style={vscDarkPlus} customStyle={{ padding: '0rem', margin: '0rem' }}>
                                {codeContent[selectedLanguage]}
                            </SyntaxHighlighter>
                        </pre>

                    </CodeSection>

                    <CodeSection style={{ marginTop: '1rem', padding: '0px' }}>
                        <FlexDiv style={{ justifyContent: 'space-between', padding: '20px 20px 0px 20px', background: '#474e5a', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                            <DetailText style={{ fontWeight: '800', color: '#fff' }}>Api Response</DetailText>
                            <FlexDiv style={{ justifyContent: 'flex-end', gap: '0.5rem', marginTop: '-0.5rem' }}>
                                <button
                                    style={{
                                        width: '42px',
                                        height: '35px',
                                        borderRadius: '8px',
                                        backgroundColor: '#D9D9D9',
                                        cursor: 'pointer',
                                        outline: 'none',
                                        border: 'none',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    onClick={copyResponseToClipboard}
                                >
                                    <img src={CopyIcon} alt="Copy" />
                                </button>
                            </FlexDiv>
                        </FlexDiv>
                        <pre style={{ marginTop: '1rem', padding: '20px 20px 5px 20px' }}>
                            <SyntaxHighlighter language={'javascript'} style={vscDarkPlus} customStyle={{ padding: '0rem', margin: '0rem' }}>
                                {apiResponse}
                            </SyntaxHighlighter>
                        </pre>

                    </CodeSection>
                </FlexDiv>
            </FlexDiv>
        </>
    );
};

export default Integration;
