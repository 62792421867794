// api.js

import axios from 'axios';
import { IP_ADD } from '../constants/api';
import { pageSize } from '../components/SellerDashboard/Transactions/constants';
import { format } from 'date-fns';
import moment from 'moment';
import Papa from 'papaparse';

export const fetchTransactionsApi = async (userId, currentPage, searchQuery, selectedStartDate, selectedEndDate) => {
    try {
        let response;
        if (selectedStartDate && selectedEndDate) {
            const startDate = selectedStartDate ? format(selectedStartDate, 'yyyy-MM-dd') : '';
            const endDate = selectedEndDate ? format(selectedEndDate, 'yyyy-MM-dd') : '';

            response = await axios.get(`${IP_ADD}/api/transaction/transactions-by-purchase/${userId}?limit=12&page=${currentPage}&search=${searchQuery}&startDate=${startDate}&endDate=${endDate}`);
        } else {
            response = await axios.get(`${IP_ADD}/api/transaction/transactions-by-purchase/${userId}?limit=12&page=${currentPage}&search=${searchQuery}`);
        }

        // Check if the response is successful
        if (response.data.status === 200) {
            const apiRows = response.data.response.results.map((transaction, index) => {
                const productName = transaction.productId ? transaction.productId.name : 'N/A';
                const productImage = transaction.productId ? transaction.productId.images[0] : 'default-image-url';
                const serial = (currentPage - 1) * pageSize + index + 1;
                return {
                    key: index + 1,
                    serial: serial,
                    id: transaction.id,
                    buyerInitials: `${transaction?.buyerId?.firstName?.charAt(0) || 'N/'}${transaction?.buyerId?.lastName?.charAt(0) || 'A'}`,
                    buyerName: `${transaction?.buyerId?.firstName || 'N'} ${transaction?.buyerId?.lastName || '/A'}`,
                    productName: productName,
                    productImage: productImage,
                    dated: new Date(transaction.orderDate).toLocaleDateString('en-GB'),
                    status: transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1),
                    amount: `$${(transaction.amount)}`,
                };
            });
            return {
                totalPages: response.data.response.totalPages,
                rows: apiRows,
            };
        } else {
            console.error('Error fetching transactions:', response.data.message);
            return null;
        }
    } catch (error) {
        console.error('Error fetching transactions:', error.message);
        return null;
    }
};

export const fetchTransactionDataApi = async (userId, limit) => {
    try {
        const response = await axios.get(`${IP_ADD}/api/transaction/transactions-by-purchase/${userId}?limit=${limit}`);
        return response.data.response.results;
    } catch (error) {
        console.error('Error fetching transaction data:', error);
        return [];
    }
};
export const exportTransactionsApi = async (userId) => {
    try {
        const response = await axios.get(`${IP_ADD}/api/transaction/transactions-without-pagination?userid=${userId}`);

        if (response.data.status === 200) {
            const csvData = response.data.response.map((transaction, index) => ({
                id: transaction.id,
                buyerName: `${transaction?.buyerId?.firstName || 'N/A'} ${transaction?.buyerId?.lastName || 'N/A'}`,
                productName: transaction.productId ? transaction.productId.name : 'N/A',
                dated: moment(transaction.orderDate).format('DD/MM/YYYY'),
                status: transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1),
                amount: `$${(transaction.amount / 100).toFixed(2)}`, // Divide by 100 and format to 2 decimal places
            }));

            const csvContent = Papa.unparse(csvData);
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            return {
                success: true,
                data: blob,
            };
        } else {
            return {
                success: false,
                error: response.data.message,
            };
        }
    } catch (error) {
        console.error('Error exporting data:', error);

        return {
            success: false,
            error: 'An error occurred while exporting data.',
        };
    }
};