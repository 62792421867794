import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'; // Import the DatePicker component
import 'react-datepicker/dist/react-datepicker.css';

const DateCheckerContainer = styled.div`
  /* Apply your custom styles to the date picker container */
  width: 7.5625rem;
  height: 2.125rem;
  flex-shrink: 0;
  border-radius: 1.0625rem;
  border: 1px solid rgba(66, 0, 81, 0.62);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 0.75rem;
  color: #6D0085;
  position: relative;
  cursor: pointer;
`;

const FromTo = styled.div`
  color: #767676;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: ${(props) => props.toMarginRight || '0'};
  margin-left: ${(props) => props.toMarginLeft || '0'};
`;
const buttonStyles = {
    marginLeft: '0.5rem',
    background: '#fff',
    display: 'inline-flex',
    padding: '0.625rem 1.125rem',
    alignItems: 'center',
    gap: '2.8125rem',
    borderRadius: '1.0625rem',
    border: '1px solid #DB0000',
    color: '#DB0000',
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    width: '4rem',
    height: '2.125rem'
};
const DateChecker = ({
    fromMarginRight = '0rem',
    fromMarginLeft = '0rem',
    toMarginRight = '0.5rem',
    toMarginLeft = '0.5rem',
    flexDirec = 'row',
    onStartdateSelected = (date) => { },
    onEndDateSelected = (date) => { },
}) => {
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const startDatePickerRef = useRef(null);
    const endDatePickerRef = useRef(null);
    const isSmallDesktop = useMediaQuery({ minWidth: 1000 });

    const handleStartDateContainerClick = () => {
        startDatePickerRef.current.setOpen(true);
    };

    const handleEndDateContainerClick = () => {
        endDatePickerRef.current.setOpen(true);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        // Call the callback function when start date is selected
        onStartdateSelected(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
        // Call the callback function when end date is selected
        onEndDateSelected(date);
    };

    const handleClearButtonClick = () => {
        setSelectedStartDate(null);
        onStartdateSelected(null);
        setSelectedEndDate(null);
        onEndDateSelected(null);

    };
    return (
        <>
            <div style={{ display: 'flex', flexDirection: isSmallDesktop ? 'row' : flexDirec }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {isSmallDesktop && (
                        <FromTo
                            style={{ marginTop: '0.6rem' }}
                            toMarginRight={toMarginRight}
                            toMarginLeft={toMarginLeft}
                        >
                            From
                        </FromTo>
                    )}
                    <DateCheckerContainer onClick={handleStartDateContainerClick}>
                        {selectedStartDate
                            ? selectedStartDate.toLocaleDateString('en-GB')
                            : 'Select Date'}
                        <DatePicker
                            ref={startDatePickerRef}
                            selected={selectedStartDate}
                            onChange={handleStartDateChange}
                            dateFormat="dd/MM/yyyy"
                            popperPlacement="top-end"
                            showTimeInput={false}
                            customInput={<div style={{ display: 'none' }}></div>}
                        />
                    </DateCheckerContainer>
                    <FromTo
                        style={{ marginTop: '0.6rem' }}
                        toMarginRight={toMarginRight}
                        toMarginLeft={toMarginLeft}
                    >
                        To
                    </FromTo>
                    <DateCheckerContainer onClick={handleEndDateContainerClick}>
                        {selectedEndDate
                            ? selectedEndDate.toLocaleDateString('en-GB')
                            : 'Select Date'}
                        <DatePicker
                            ref={endDatePickerRef}
                            selected={selectedEndDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            popperPlacement="top-end"
                            showTimeInput={false}
                            customInput={<div style={{ display: 'none' }}></div>}
                            minDate={selectedStartDate} // Set the minimum selectable date
                        />
                    </DateCheckerContainer>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: (!isSmallDesktop && flexDirec != 'row') ? '1rem' : '' }}>
                    {(selectedStartDate != null || selectedEndDate != null) ? (
                        <button style={buttonStyles} onClick={handleClearButtonClick}>Clear</button>
                    ) : null}
                </div>
            </div>

        </>
    );
};

export default DateChecker;
