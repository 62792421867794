import React from 'react';
import Modal from 'react-modal';
import { YOUR_BROWSER_DOES_NOT } from '../../constants/text';
const MediaModal = ({ isOpen, onRequestClose, mediaUrl }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '80%', // Adjust the maximum width as needed
                    margin: 'auto',
                },
            }}
        >
            {mediaUrl.endsWith('.svg') || mediaUrl.endsWith('.png') || mediaUrl.endsWith('.jpg') || mediaUrl.endsWith('.jpeg') ? (
                // Display image
                <img src={mediaUrl} alt="Media" style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }} />
            ) : mediaUrl.endsWith('.mp4') ? (
                // Display video
                <video controls width="100%" style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }}>
                    <source src={mediaUrl} type="video/mp4" />
                    {YOUR_BROWSER_DOES_NOT}
                </video>
            ) : (
                // Handle other media types as needed
                <span>{mediaUrl}</span>
            )}
        </Modal>
    );
};

export default MediaModal;
