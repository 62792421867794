// api.js
import axios from 'axios';
import { IP_ADD } from '../constants/api';

export const createNewReview = async (userID, productID, rating, comment) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/products/reviews`, {
            userId: userID,
            productId: productID,
            rating,
            comment,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateReview = async (userID, productID, rating, comment) => {
    try {
        const response = await axios.put(
            `${IP_ADD}/api/review/update-user-review`,
            {
                userId: userID,
                productId: productID,
                data: {
                    rating,
                    comment,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchReviewUser = async (userID, productID) => {
    try {
        const response = await axios.post(
            `${IP_ADD}/api/review/product-review-by-user`,
            {
                userId: userID,
                productId: productID,
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteReviewUser = async (userID, productID) => {
    try {
        const response = await axios.delete(
            `${IP_ADD}/api/review/delete-user-review`,
            {
                data: {
                    userId: userID,
                    productId: productID,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
