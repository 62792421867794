import { FlexDiv, SellerProfileDiv } from "../assets/styles/styles";
import notificationBell from '../assets/images/notification_bell.svg';
import downArrow from '../assets/images/down_arrow.svg';
import { useMediaQuery } from 'react-responsive'
import styled from "@emotion/styled";
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import Notifications from "./Notifications/Notifications";
import { useState, useRef, useEffect } from "react";
import { useNotification } from "../Context/NotificationContext"
import { logout } from "../Services/Authentication";
import Badge from '@mui/material/Badge';
import AvatarLogo from "./AvatarLogo";
import ToggleButton from "./ToggleButton";

export const NotificationDiv = styled.div`
  display: block;
  position: absolute;
  top: 4.8rem;
  transition: height 0.3s;
  height: ${props => props.open ? '28rem' : '0px'};
  overflow: hidden;
  width: ${props => props.open ? '' : '0px'};
  z-index: 1000;
  right: ${props => props.rightMargin};
  ${props => props.open ? '' : 'overflow: hidden'};
`;


const UserProfileBox = ({ mobileNavOpen, setMobileNavOpen, isAMobile = false, openModal }) => {
    const isDesktop = useMediaQuery({ minWidth: 1125 });
    const isLaptop = useMediaQuery({ minWidth: 620 });
    const isLaptopTwo = useMediaQuery({ minWidth: 690 });
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const { notification } = useNotification();
    const unreadNotificationsCount = notification.filter(item => !item.read).length;
    const unreadMessages = notification.filter(
        (item) => item.notificationType === 'message' && !item.read
    ).length;
    const notificationRef = useRef(null);

    const handleClickOutside = (event) => {
        if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            // Click outside the notifications area
            setNotificationOpen(false);
        }
    };
    const handleNotificationClick = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const navigate = useNavigate();

    const openMobileNav = () => {
        setMobileNavOpen(!mobileNavOpen);
    }

    const handleLogout = async () => {
        const token = localStorage.getItem('refresh-token');
        await logout(token);
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('access-token-expiry');
        localStorage.removeItem('refresh-token-expiry');
        localStorage.removeItem('Cornucopia-user');
        navigate('/login');
    }


    const inboxNavigation = () => {
        navigate('/inboxbuyer');
    }

    const rightMargin = () => {
        if (isDesktop) {
            return '7.5rem';
        } else if (isLaptop) {
            return '1rem';
        } else {
            return '4rem'
        }
    }

    return (
        <FlexDiv>
            {!isAMobile
                &&
                <>
                    <button style={{ display: 'contents' }} onClick={openMobileNav}>
                        <SellerProfileDiv style={{ height: '2.8rem' }}>
                            <FlexDiv>
                                <FlexDiv style={{ marginRight: '0.5rem' }}>
                                    {userData?.response?.displayPicture ?
                                        <img src={userData.response.displayPicture} alt="" style={{ width: '1.9rem', height: '1.9rem', margin: '0.4rem 0rem 0rem 0.6rem', borderRadius: '1.9rem' }} /> :
                                        <div style={{ margin: '0.4rem 0rem 0rem 0.6rem' }}>
                                            <AvatarLogo width={"1.9rem"} height={"1.9rem"} mobile={true} />
                                        </div>
                                    }
                                    {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <h1 style={{ color: '#000', fontFamily: 'Poppins', fontSize: '0.75rem', fontWeight: '500', margin: '0.5rem 0rem 0rem 0.5rem' }}>{userData ? userData.response.firstName : 'Felix Jhonson'}</h1>
                                        <div style={{ color: '#8F8F8F', fontFamily: 'Poppins', fontSize: '0.5625rem', fontWeight: '500', marginLeft: '0.5rem', maxWidth: '7rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData ? userData.response.email : 'felix@gmail.com'}</div>
                                    </div> */}
                                </FlexDiv>
                                {/* {!isDesktop && */}
                                <FlexDiv>
                                    <img src={downArrow} alt="" style={{ margin: '0.3rem 0.7rem 0rem 0rem', width:'10px' }} />
                                </FlexDiv>
                                {/* } */}
                            </FlexDiv>
                        </SellerProfileDiv>
                    </button>
                    <div style={{ width: '1.43rem', height: '1px', background: '#A5A5A5', transform: 'rotate(-90deg)', marginTop: '1.4rem' }}></div>
                </>
            }
            <NotificationDiv
                open={notificationOpen}
                rightMargin={rightMargin()}
                onClick={handleNotificationClick}
            >
                <Notifications />
            </NotificationDiv>

            <button style={{ display: 'contents' }} onClick={inboxNavigation}>
                <Tooltip title="Inbox">
                    <div style={{ borderRadius: '1.4rem', backgroundColor: '#F9F9F9', width: '2.8rem', height: '2.8rem', textAlign: 'center' }}>
                        <EmailIcon sx={{ marginTop: '0.6rem', marginLeft: '0.4rem', color: '#B295C5' }} />
                        <Badge badgeContent={unreadMessages} color="error" style={{ marginBottom: '0.7rem', marginLeft: '0.5rem' }}></Badge>
                    </div>
                </Tooltip>
            </button>
            <button style={{ display: 'contents' }} onClick={() => { setNotificationOpen(!notificationOpen) }} ref={notificationRef} >
                <FlexDiv style={{ height: '2.8125rem', width: '2.8125rem', borderRadius: '1.40625rem', backgroundColor: '#F9F9F9', marginLeft: '0.5rem' }}>
                    <img src={notificationBell} alt="" style={{ width: '1rem', height: '1.0625rem', margin: '0.87rem 0rem 0rem 0.94rem' }} />
                </FlexDiv>
                {unreadNotificationsCount > 0 && (
                    <div style={{ width: '1.3125rem', height: '1.3125rem', backgroundColor: '#DB0000', borderRadius: '1.40625rem', marginLeft: '-1.4rem', marginRight: '1rem', textAlign: 'center', color: 'white', fontSize: '0.625rem', paddingTop: '0.2rem' }}>
                        {unreadNotificationsCount}
                    </div>
                )}
            </button>
            {isAMobile
                &&
                <div style={{ marginTop: "0.2rem", marginLeft: '0.5rem' }}>
                    <button style={{ display: 'contents' }} onClick={openMobileNav}>
                        <AvatarLogo width={"2.5rem"} height={"2.5rem"} mobile={true} />
                    </button>
                </div>
            }
            {/* {isDesktop &&
                <button style={{ display: 'contents' }} onClick={openModal}>
                    <Tooltip title="LogOut">
                        <div style={{ borderRadius: '1.4rem', backgroundColor: '#F9F9F9', width: '2.8rem', height: '2.8rem', marginLeft: '0.5rem', textAlign: 'center' }}>
                            <LogoutIcon sx={{ marginTop: '0.6rem', marginLeft: '0.2rem' }} />
                        </div>
                    </Tooltip>
                </button>
            } */}
            {!isDesktop && isLaptopTwo && <ToggleButton />}
        </FlexDiv>
    )
}
export default UserProfileBox;