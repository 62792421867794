import React from 'react';
import Grid from '@mui/material/Grid';
import InboxChat from './InboxChat';
import { useState } from 'react';
import { useEffect } from 'react';
import MessageListBlank from '../MessageListBlankState';
import axios from 'axios';
import { IP_ADD } from '../../constants/api';
import { useNotification } from '../../Context/NotificationContext';
import { fetchNotificationsApi } from '../../Services/Notification';
import CircularProgress from '@mui/material/CircularProgress';

const chatListStyle = {
    height: 'calc(100vh)',
    maxWidth: '27rem'
};

const ChatList = ({ sellerId = null, searchTerm }) => {
    const userId = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const [isChatFetching, setIsChatFetching] = useState(true);
    const accessToken = localStorage.getItem('access-token');
    const colorArray = ["#ad4ead", "#3f4b8b", "#5b59b6", "#3498db"];
    const [chats, setChats] = useState([]);

    const {
        setNotificationUpdated,
        notification,
        setNotification,
        setChatId,
        setFirstName,
        setLastName,
        setChatsCopy,
        fetchAgain,
        setFetchAgain,
        setChatListClicked,
        setDisplayPicture,
        displayPicture,
        setFetchedDisplayPic,
        setAvatarColor
    } = useNotification();
    const fetchChats = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            const { data } = await axios.get(`${IP_ADD}/api/chats`, config);
            if (data.response.length > 0) {
                // Filter chats based on the search term
                const filteredChats = data.response.filter(chat => {
                    // Check if users array and its elements are defined
                    if (chat.users && chat.users[0] && chat.users[1]) {
                        const firstNameMatch = chat.users[0].firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            chat.users[1].firstName?.toLowerCase().includes(searchTerm.toLowerCase());
                        const lastNameMatch = chat.users[0].lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            chat.users[1].lastName?.toLowerCase().includes(searchTerm.toLowerCase());

                        return firstNameMatch || lastNameMatch;
                    }

                    return false; // If users array or its elements are undefined, exclude the chat
                });

                setChats(filteredChats);
                setChatsCopy(filteredChats);
            } else {
                setChats([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsChatFetching(false);
        }
    };



    const handleChatClick = async (chat, index) => {
        setChatId(chat._id);
        setFirstName(userId.response.id === chat.users[0].id ? chat.users[1].firstName : chat.users[0].firstName);
        setLastName(userId.response.id === chat.users[0].id ? chat.users[1].lastName : chat.users[0].lastName);
        setDisplayPicture(() => {
            if (
                userId.response.id &&
                chat.users[0] &&
                chat.users[1] &&
                (userId.response.id === chat.users[0].id || userId.response.id === chat.users[1].id)
            ) {
                return userId.response.id === chat.users[0].id
                    ? chat.users[1].displayPicture || null
                    : chat.users[0].displayPicture || null;
            }
            return null;
        });
        setChatListClicked(true);
        setAvatarColor(() => {
            if (
                userId.response.id &&
                chat.users[0] &&
                chat.users[1] &&
                (userId.response.id === chat.users[0].id || userId.response.id === chat.users[1].id)
            ) {
                return userId.response.id === chat.users[0].id
                    ? colorArray[index % 4] || null
                    : colorArray[index % 4] || null;
            }
            return null;
        });
        // Filter notifications based on conditions
        const matchingNotifications = notification.filter(item => (
            item.sender &&
            item.sender.id &&
            (item.sender.id === chat.users[0]?.id || item.sender.id === chat.users[1]?.id) &&
            item.notificationType === 'message' &&
            !item.read
        ));

        // Mark matching notifications as read
        if (matchingNotifications.length > 0) {
            try {
                const userId = JSON.parse(localStorage.getItem('Cornucopia-user'));

                for (const notificationItem of matchingNotifications) {
                    await axios.patch(
                        `${IP_ADD}/api/notification/mark-notification/${notificationItem._id}`,
                        { userId: userId.response.id }
                    );
                }
                const notificationApiResult = await fetchNotificationsApi(userId.response.id);
                setNotification([...notificationApiResult.data]);
                setFetchAgain(true);
                setNotificationUpdated(true);
            } catch (error) {
                console.error('Error marking notifications as read', error);
            }
        }
    };


    useEffect(() => {
        fetchChats();
        setFetchAgain(false);
    }, [fetchAgain]);

    useEffect(() => {
        setFetchedDisplayPic(true);
    }, [displayPicture]);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            {isChatFetching ? (
                <CircularProgress color='secondary' />
            ) : (
                <Grid style={{ ...chatListStyle, overflowY: chats.length === 0 ? 'hidden' : 'scroll' }}>
                    {chats && chats.length > 0 ? (
                        chats.map((chat, index) => (
                            <Grid item xs={12} key={index} style={{ marginBottom: '0.14rem !important' }}>
                                <div style={{ marginTop: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem', marginBottom: '0.5rem' }}>
                                    <button
                                        onClick={() => handleChatClick(chat, index)}
                                        style={{
                                            border: 'none',
                                            background: 'none',
                                            padding: '0',
                                            margin: '0',
                                            fontSize: 'inherit',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <InboxChat
                                            firstName={chat.users[0]?.id ? (userId.response.id === chat.users[0].id ? chat.users[1]?.firstName : chat.users[0]?.firstName) : ''}
                                            lastName={chat.users[0]?.id ? (userId.response.id === chat.users[0].id ? chat.users[1]?.lastName : chat.users[0]?.lastName) : ''}
                                            content={chat.latestMessage ? chat.latestMessage.content : ''}
                                            time={chat.latestMessage ? chat.latestMessage.createdAt : null}
                                            notification={
                                                notification.some(
                                                    item =>
                                                        item.sender &&
                                                        item.sender.id &&
                                                        (item.sender.id === chat.users[0]?.id || item.sender.id === chat.users[1]?.id) &&
                                                        item.notificationType === 'message' &&
                                                        item.read === false
                                                )
                                            }
                                            chatID={chat._id}
                                            displayPicture={
                                                userId.response.id === chat.users[0]?.id ? (chat.users[1]?.displayPicture ? chat.users[1]?.displayPicture : null) : (chat.users[0]?.displayPicture ? chat.users[0]?.displayPicture : null)
                                            }
                                            color={colorArray[index % 4]}
                                        />

                                    </button>
                                </div>
                            </Grid>
                        ))
                    ) : (
                        <MessageListBlank />
                    )}
                </Grid>
            )}
        </div>
    );
};

export default ChatList;