import React, { useState, useEffect, useContext } from 'react';
import { Card, Typography, Checkbox, FormControlLabel, Slider } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FlexDiv } from "../../assets/styles/styles";
import { useMediaQuery } from "react-responsive";
import { CLEAR_ALL, DONE } from '../../constants/text';
import { checkboxLabels, secondaryCheckboxLabels, SliderMinMax, SliderValue, ClearAll, FilterTextStyle, ClearAllDiv, ClearAllBtn, DoneBtn, ExpansionDiv } from './constants';
import { useNotification } from '../../Context/NotificationContext';
import { useNavigate } from 'react-router-dom';

const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
};
const ProductsFilters = ({ setMinPrice, setMaxPrice, isFreeChecked, setIsFreeChecked, maxWidth='100%' }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { selectedCheckboxKeys,
        setSelectedCheckboxKeys,
        selectedTopCheckboxes,
        setSelectedTopCheckboxes,
        selectedFilters,
        setSelectedFilters, selectedCategory, setSelectedCategory } = useNotification();
    const [minSliderValue, setMinSliderValue] = useState(0);
    const [maxSliderValue, setMaxSliderValue] = useState(10000);
    // const [selectedCheckboxKeys, setSelectedCheckboxKeys] = useState([]);
    // const [selectedTopCheckboxes, setSelectedTopCheckboxes] = useState({});
    // const [selectedFilters, setSelectedFilters] = useState([])
    const [initialClick, setInitialClick] = useState(true);
    const [isPriceRangeChecked, setIsPriceRangeChecked] = useState(false);
    const isTablet = useMediaQuery({ maxWidth: 1200 });
    const isSmallTablet = useMediaQuery({ maxWidth: 700 });
    const navigate = useNavigate();
    const chunkedSecondaryCheckboxLabels = chunkArray(secondaryCheckboxLabels, 4);

    const handleCheckboxChange = () => {
        if (initialClick) {
            setInitialClick(false);
        } else {
            setIsFreeChecked(!isFreeChecked);
        }
    };

    const handleDoneClick = () => {
        toggleExpansion();
        setSelectedCategory([...selectedFilters]);

        // Check if the current route is '/'
        if (window.location.pathname === '/') {
            // Navigate to '/products'
            navigate('/products');
        }
    };

    const toggleExpansion = () => {
        setIsExpanded(prevExpanded => !prevExpanded);
    };
    const handleClearAll = () => {
        setSelectedTopCheckboxes({});
        setSelectedCheckboxKeys([]);
        setSelectedFilters([]);
        // setIsFreeChecked(false);
        // setMinSliderValue(0);
        // setMaxSliderValue(10000);
    };

    const handleSecondaryCheckboxChange = (label, index, secondaryLabel, secondaryIndex) => {
        const key = `secondary-checkbox-${label}-${index}-${secondaryLabel}-${secondaryIndex}`;

        if (selectedCheckboxKeys.includes(key)) {
            setSelectedCheckboxKeys(prevSelected => prevSelected.filter(k => k !== key));
            setSelectedFilters(prevCategory => prevCategory.filter(category => category !== secondaryLabel));
        } else {
            setSelectedCheckboxKeys(prevSelected => [...prevSelected, key]);
            setSelectedFilters(prevCategory => [...prevCategory, secondaryLabel]);
        }
    };
    return (
        <>
            <Card
                sx={{
                    maxWidth: isSmallTablet ? maxWidth : isTablet ? '80%' : '90%',
                    borderRadius: '0.75rem',
                    background: '#FFF',
                    boxShadow: '0px 4px 11px 0px rgba(0, 0, 0, 0.25)',
                    position: 'relative',
                    margin: isSmallTablet?'1rem auto':'3rem auto',
                    flexDirection: isTablet ? 'column' : 'row',
                }}
            >
                <ExpansionDiv
                    onClick={toggleExpansion}
                    style={{ position: isExpanded ? 'absolute' : 'relative' }}
                >
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <Typography style={FilterTextStyle}>
                            Filters
                        </Typography>
                    </div>
                    <ArrowDropDownIcon style={{ color: '#FFF', fontSize: '1.5rem', marginLeft: 'auto' }} />
                </ExpansionDiv>
                {/* Checkboxes */}
                {isExpanded && (
                    <>
                        <div
                            style={{
                                overflowX: 'auto',
                                // Add any additional styling for the div if needed
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: isTablet ? 'column' : 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start', // Align checkboxes to the top
                                    marginTop: '2.625rem',
                                    padding: '1.5rem',
                                }}
                            >
                                {checkboxLabels.map((label, index) => (
                                    label !== 'Price Range' && // Exclude 'Price Range' label
                                    <div key={`checkbox-${label}-${index}`}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="secondary"
                                                    sx={{
                                                        borderRadius: '0.0625rem',
                                                    }}
                                                    checked={selectedTopCheckboxes[index] || false}
                                                    onChange={() => {
                                                        setSelectedTopCheckboxes((prevTopCheckboxes) => {
                                                            const newSelectedTopCheckboxes = {
                                                                ...prevTopCheckboxes,
                                                                [index]: !prevTopCheckboxes[index],
                                                            };

                                                            const subCheckboxIndices = chunkedSecondaryCheckboxLabels[index];
                                                            const subCheckboxKeys = subCheckboxIndices.map((subLabel, subIndex) =>
                                                                `secondary-checkbox-${label}-${index}-${subLabel}-${subIndex}`
                                                            );

                                                            setSelectedCheckboxKeys((prevSelected) => {
                                                                const newKeys = newSelectedTopCheckboxes[index]
                                                                    ? [...prevSelected, ...subCheckboxKeys]
                                                                    : prevSelected.filter((key) => !subCheckboxKeys.includes(key));

                                                                return newKeys;
                                                            });

                                                            setSelectedFilters((prevCategory) => {
                                                                const newFilters = newSelectedTopCheckboxes[index]
                                                                    ? [...prevCategory, ...subCheckboxIndices]
                                                                    : prevCategory.filter((filter) => !subCheckboxIndices.includes(filter));

                                                                return newFilters;
                                                            });

                                                            return newSelectedTopCheckboxes;
                                                        });
                                                    }}

                                                />
                                            }
                                            label={label}
                                            labelPlacement="end"
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                color: '#3F0065',
                                                fontFamily: 'Poppins',
                                                fontSize: '0.9375rem',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '102.5%',
                                                letterSpacing: '0.00938rem',
                                            }}
                                        />
                                        {label !== 'Price Range' && (
                                            <FlexDiv style={{ marginTop: '0.81rem', flexDirection: 'column' }}>
                                                {chunkedSecondaryCheckboxLabels.map((column, columnIndex) => (
                                                    // Only render the checkboxes for the current column
                                                    columnIndex === index && (
                                                        <React.Fragment key={`secondary-checkbox-column-${index}`}>
                                                            {column.map((secondaryLabel, secondaryIndex) => (
                                                                <FormControlLabel
                                                                    key={`secondary-checkbox-${label}-${index}-${secondaryLabel}-${secondaryIndex}`}
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={() => handleSecondaryCheckboxChange(label, index, secondaryLabel, secondaryIndex)}
                                                                            checked={selectedCheckboxKeys.includes(`secondary-checkbox-${label}-${index}-${secondaryLabel}-${secondaryIndex}`)}
                                                                            color="secondary"
                                                                            sx={{
                                                                                borderRadius: '0.0625rem',
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={secondaryLabel}
                                                                    labelPlacement="end"
                                                                    sx={{
                                                                        whiteSpace: 'nowrap',
                                                                        marginLeft: '0.5rem',
                                                                        color: selectedCheckboxKeys.includes(`secondary-checkbox-${label}-${index}-${secondaryLabel}-${secondaryIndex}`) ? '#480078' : '#686868',
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: '0.8125rem',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: '102.5%',
                                                                        letterSpacing: '0.00813rem',
                                                                    }}
                                                                />
                                                            ))}
                                                        </React.Fragment>
                                                    )
                                                ))}
                                            </FlexDiv>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <ClearAllDiv>
                                <ClearAllBtn onClick={handleClearAll}>
                                    <ClearAll>
                                        {CLEAR_ALL} ({Object.values(selectedCheckboxKeys).filter(value => value).length})
                                    </ClearAll>
                                </ClearAllBtn>
                                <DoneBtn
                                    onClick={handleDoneClick}
                                >
                                    {DONE}
                                </DoneBtn>
                            </ClearAllDiv>
                        </div>
                    </>
                )}
            </Card >
        </>
    );
};
export default ProductsFilters;
