import React from 'react';
import BagIcon from '../../assets/images/ion_bag.svg';
import BlankState from '../Utils/BlankState';

const NoProduct = ({width='100%'}) => {
    return (
        <BlankState text={'No product found'} icon={BagIcon} height={'43.875rem'} marginLeft={'3rem'} width={width} />
    );
};

export default NoProduct;
