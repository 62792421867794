import React from 'react'
import { Paper } from '@mui/material'

const Item = ({item}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        height: '600px',
        userSelect: 'text'
      }}
    >
      {item}
    </Paper>
  )

}

export default Item