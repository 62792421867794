import styled from 'styled-components';

export const Heading = styled.h1`
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.5rem;  
    line-height: 2rem; 
    color: #3F0065;
    @media (max-width: 720px) {
        text-align:center;
    }
`;

export const DetailText = styled.p`
    font-family: Poppins;
    font-weight: 400;
    font-size: 1rem; 
    line-height: 1.25rem; 
    color: #888;
    width:90%;
`;

export const CodeSection = styled.div`
    width: 90%;
    top: 200px;
    margin-bottom:10rem;
    left: 240px;
    border-radius: 16px;
    padding: 20px;
    gap: 10px;
    background-color: rgb(30,30,30);
`;

export const apiResponse = `{
  "status": 200,
  "message": "Login successful",
  "response": {
      "user": {
          "user": {
              "_id": "65a02f48f42d7abf7e1d2c03",
              "email": "johndoe@gmail.com",
              "firstName": "John",
              "lastName": "Doe",
              "role": "buyer",
              "isEmailVerified": true
          }
      },
      "tokens": {
          "access": {
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NWEwMmY0OGY0MmQ3YWJmN2UxZDJjMDMiLCJpYXQiOjE3MDgwOTUxNTUsImV4cCI6MTcwODA5Njk1NSwidHlwZSI6ImFjY2VzcyJ9.ECNGfFoV6j3Jtu3HW2X-t7pi09TdRQyT8nIdl2Z6IUQ",
              "expires": "2024-02-16T15:22:35.581Z"
          },
          "refresh": {
              "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NWEwMmY0OGY0MmQ3YWJmN2UxZDJjMDMiLCJpYXQiOjE3MDgwOTUxNTUsImV4cCI6MTcxMDY4NzE1NSwidHlwZSI6InJlZnJlc2gifQ.OhiFwOD_Rq40L1nGYfOB95H-6o7S86wKvuQliaxZZUc",
              "expires": "2024-03-17T14:52:35.582Z"
          }
      }
  }
}`;

export const codeContent = {
  flask: `from flask import Flask
import requests

app = Flask(__name__)

@app.route('/')
def call_api():
    # API endpoint URL
    url = "https://backend.cornucopia-ai.com/api/auth/web/login"

    # Data to be sent in the request body
    # This is dummy data 
    payload = {
        "email": "example@xyz.com",
        "password": "example123",
        "productId": "abc123"
    }

    try:
        # Sending POST request to the API
        response = requests.post(url, json=payload)

        # Checking if the request was successful (status code 200)
        if response.status_code == 200:
            # Printing the response content
            return( response.json())
            # return "API called successfully"
        else:
            return f"Failed to call API. Status code: {response.status_code}"
    except Exception as e:
        return f"An error occurred: {str(e)}"
`,
  django: `# views.py
from django.shortcuts import render
import requests

def call_api(request):
    if request.method == 'POST':
        # Make the API call when the button is clicked
        # This is dummy data
        api_url = 'https://backend.cornucopia-ai.com/api/auth/web/login'
        data = {
            "email": "example@xyz.com",
            "password": "example123",
            "productId": "abc123"
        }
        response = requests.post(api_url, json=data)
        result = response.json() if response.ok else {'error': 'Failed to login'}

        return render(request, 'result.html', {'result': result})
    else:
        return render(request, 'result.html', {'result': None})
`,
  javascript: `
  const [error, setError] = useState(null);

  const callApi = async () => {
    try {
      const url = 'https://backend.cornucopia-ai.com/api/auth/web/login';
      const payload = {
        email: 'example@xyz.com',
        password: 'example123',
        productId: 'abc123'
      };

      const response = await axios.post(url, payload);
      if(response.status === 200){
        // Place your login function here
        login()
      }else{
        // Display a msg showing user credentials are invalid
        console.log('Invalid credentials')
      }
    } catch (error) {
      setError(error.message);
    }
  };
`,
node: `const fetch = require('node-fetch');

async function callApi() {
  const url = 'https://backend.cornucopia-ai.com/api/auth/web/login';
  const payload = JSON.stringify({
    email: 'example@xyz.com',
    password: 'example123',
    productId: 'abc123'
  });

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: payload
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("HTTP error!");
    }
    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}

// Example usage
callApi()
  .then(data => {
    console.log('Response:', data);
    // Do something with the response data
  })
  .catch(error => {
    console.error('Error:', error.message);
  });
  `
};


export const CodeText = styled.code`
    margin-top:2.375rem;
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #ffffff; /* Assuming white text for code */
    align-text:left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
`;