import NavBar from "../../components/Navbar";
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import FooterDivider from "../../components/FooterDivider";
import Footer from "../../components/Footer";
import { FlexDiv } from "../../assets/styles/styles";
import { useMediaQuery } from "react-responsive";
import ChatList from "../../components/Inbox/ChatList";
import ChatWindow from "../../components/Inbox/ChatWindow";
import VerticalDivider from '../../assets/images/vertical_divider.png';
import ChatSearch from "../../components/Inbox/ChatSearch";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { IP_ADD } from "../../constants/api";
import { useNotification } from "../../Context/NotificationContext";
import { Helmet } from "react-helmet";
import { HEADER_DATA, HeaderText, TokenExpired } from "./constants";
import { Container } from "@mui/material";

const InboxBuyer = () => {
    const isDesktop = useMediaQuery({ minWidth: 1000 });
    const isLargeDesktop = useMediaQuery({ minWidth: 1400 });
    const [searchTerm, setSearchTerm] = useState('');
    const isTab = useMediaQuery({ minWidth: 750 });
    const isMobile = useMediaQuery({ maxWidth: 550 });
    const location = useLocation();
    const [isExpired, setIsExpired] = useState(true);
    const userData = (localStorage.getItem('access-token'));

    const {
        chatId,
        setChatId,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        chatAvailable,
        setChatAvailable,
        chatsCopy,
        setChatsCopy,
        fetchAgain,
        setFetchAgain,
        chatListClicked,
        setChatListClicked,
        messages,
        setMessages,
    } = useNotification();

    const queryParams = new URLSearchParams(location.search);
    let sellerId = queryParams.get('sellerId');

    if (sellerId === null || sellerId === undefined) {
        sellerId = undefined;
    }

    const accessChat = async () => {
        const userData = localStorage.getItem('access-token');
        const userId = sellerId;
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userData}`,
                },
            };
            const response = await axios.post(`${IP_ADD}/api/chats`, { userId }, config);
            const chatId = response.data.response._id;
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };


    const refreshtoken = async () => {
        try {
            const token = localStorage.getItem('refresh-token');
            const response = await axios.post(`${IP_ADD}/api/auth/refresh-tokens`, {
                refreshToken: token
            });
            if (response.status === 200) {
                localStorage.setItem('access-token', response.data.response.response.tokens.access.token);
                localStorage.setItem('refresh-token', response.data.response.response.tokens.refresh.token);
                localStorage.setItem('access-token-expiry', response.data.response.response.tokens.access.expires);
                localStorage.setItem('refresh-token-expiry', response.data.response.response.tokens.refresh.expires);
                setIsExpired(false);
                if (sellerId != undefined)
                    accessChat();
            }
        } catch {
            console.log('error')
        }
    }

    // useEffect(() => {
    //     const accessExpiryToken = localStorage.getItem('access-token-expiry');
    //     if (TokenExpired(accessExpiryToken)) {
    //         refreshtoken();
    //     } else {
    //         setIsExpired(false);
    //     }
    // }, [])

    useEffect(() => {
        const accessExpiryToken = localStorage.getItem('access-token-expiry');
        if (sellerId) {
            if (TokenExpired(accessExpiryToken)) {
                refreshtoken();
            } else {
                setIsExpired(false);
                accessChat();
                setFetchAgain(true);
            }

        }
        else {
            if (TokenExpired(accessExpiryToken)) {
                refreshtoken();
            } else {
                setIsExpired(false);
            }
        }
    }, [sellerId]);
    useEffect(() => {
        return () => {
            setChatId(null); // Reset chatId when leaving the InboxBuyer page
            setChatListClicked(false);
        };
    }, []);

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <Helmet>
                    <title>Cornucopia AI | Inbox-Buyer</title>
                </Helmet>
                <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} authenticated={'true'} />
                {isExpired ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress style={{ width: '27px', height: '25px' }} color="secondary" />
                    </div>
                    :
                    <div style={{ marginBottom: '-200px' }}>
                        <FlexDiv
                            style={{ marginLeft: isDesktop ? '5.37rem' : '1rem', fontSize: isTab ? '' : '1.5rem', justifyContent: 'space-between', marginRight: isDesktop ? '3rem' : '1rem', flexDirection: isTab ? 'row' : 'column', alignItems: isTab ? '' : 'center', textAlign: isTab ? '' : 'center', }}
                        >
                            <HeaderText style={{ textAlign: isTab ? '' : 'center' }}>My Inbox</HeaderText>
                            <div
                                style={{
                                    display: 'flex', marginTop: '1.5rem', width: isLargeDesktop ? '75%' : isMobile ? '100%' : '70%', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
                                }}
                            >
                                {isTab &&
                                    <ChatSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} onSearchSubmit={() => setFetchAgain(true)} />
                                }
                                {(!chatListClicked && !isTab) &&
                                    <ChatSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} onSearchSubmit={() => setFetchAgain(true)} />
                                }
                            </div>
                        </FlexDiv>
                        <div style={{ display: isTab ? 'flex' : 'none', flexDirection: isTab ? 'row' : 'column', marginLeft: isDesktop ? '-1rem' : '1rem', marginTop: '1.37rem', marginRight: isDesktop ? '3.5rem' : '1.5rem', }}>
                            {isTab &&
                                <div style={{ marginLeft: isDesktop ? '5.37rem' : '1rem', marginTop: '1rem', marginRight: isDesktop ? '1rem' : '1.5rem', width: isTab ? '28rem' : '' }}>
                                    <ChatList searchTerm={searchTerm} onSearchSubmit={() => setFetchAgain(true)} />
                                </div>
                            }
                            {isTab && (
                                <img src={VerticalDivider} alt=" " style={{ marginLeft: '2.75rem', marginRight: '2.44rem', height: '100vh' }} />
                            )}
                            <div style={{ marginTop: isTab ? '' : '2rem', width: '100%' }}>
                                {isTab && <ChatWindow sellerId={sellerId} listChatId={chatId} firstName={firstName} lastName={lastName} chatsCopy={chatsCopy} setFetchAgain={setFetchAgain} setChatListClicked={setChatListClicked} messages={messages} setMessages={setMessages} />}
                            </div>
                        </div>

                        {!isTab &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '3rem',
                                marginLeft: isDesktop ? '5.37rem' : '1rem',
                                marginRight: isDesktop ? '3.5rem' : '1.5rem',
                                height: '100vh',
                            }}>
                                {!chatListClicked &&
                                    <div style={{ width: '100%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <ChatList sellerId={sellerId} setChatId={setChatId} setFirstName={setFirstName} setLastName={setLastName} setChatAvailable={setChatAvailable} setChatsCopy={setChatsCopy} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} setChatListClicked={setChatListClicked} searchTerm={searchTerm} />
                                    </div>
                                }
                                {chatListClicked &&
                                    <div style={{ marginTop: '2rem', width: '100%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <ChatWindow sellerId={sellerId} listChatId={chatId} firstName={firstName} lastName={lastName} setFetchAgain={setFetchAgain} chatsCopy={chatsCopy} setChatListClicked={setChatListClicked} messages={messages} setMessages={setMessages} />
                                    </div>
                                }
                            </div>
                        }

                    </div>
                }
            </Container>
            <FooterDivider />
            <Footer />
        </>
    );
};

export default InboxBuyer