import NavBar from "../../components/Navbar";
import React, { useState, useEffect } from 'react';
import FooterDivider from "../../components/FooterDivider";
import Footer from "../../components/Footer";
import SellerOptions from "../../components/ViewAllSellers/SellerOptions";
import SellerSearch from "../../components/ViewAllSellers/SellerSearch";
import { FlexDiv } from "../../assets/styles/styles";
import { useMediaQuery } from "react-responsive";
import SalesForce from "../../components/LandingPage/SalesForce";
import ShowRow from "../../components/ViewAllSellers/ShowRow";
import { SALESFORCE } from "../../constants/text";
import NoSeller from "../../components/ViewAllSellers/NoSeller";
import { IP_ADD } from "../../constants/api";
import { getAllSellers } from "../../Services/User";
import CustomPagination from "../../components/CustomPagination";
import { Helmet } from "react-helmet";
import { ITEMS_PER_PAGE, HEADER_DATA, HeaderText } from './constants'
import SpinnerComponent from "../../components/Spinner";
import { Container } from "@mui/material";

const AllSellers = () => {
    const isDesktop = useMediaQuery({ minWidth: 1000 });
    const isTab = useMediaQuery({ minWidth: 750 });
    const isMobile = useMediaQuery({ maxWidth: 590 });
    const isSmallMobile = useMediaQuery({ maxWidth: 400 });
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSellers, setFilteredSellers] = useState([]);
    const [selectedOption, setSelectedOption] = useState('All'); // Initialize with default value
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [searchChange, setSearchChange] = useState(false);
    const [currentScreen, setCurrentScreen] = useState(4);
    const [isNewSellerClicked, setIsNewSellerClicked] = useState(false);
    const [row, setRow] = useState(4)
    const [totalSellers, setTotalSellers] = useState(0);
    // Calculate starting and ending indexes for pagination
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    // Subset of filteredSellers for current page
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        if (currentScreen === 4)
            setIsFetching(true);

        let apiUrl;
        if (isNewSellerClicked === true) {
            apiUrl = `${IP_ADD}/api/users/sellers?limit=${currentScreen * row}&page=${currentPage}&search=${searchQuery}&new_sellers=true`;
            getAllSellers(apiUrl, 'get', setFilteredSellers, setTotalPages, setIsFetching, searchChange, setCurrentPage, setTotalSellers);
        }
        else if (searchQuery === '') {
            apiUrl = `${IP_ADD}/api/users/sellers?limit=${currentScreen * row}&page=${currentPage}`;
            getAllSellers(apiUrl, 'get', setFilteredSellers, setTotalPages, setIsFetching, searchChange, setCurrentPage, setTotalSellers);
        } else {
            apiUrl = `${IP_ADD}/api/users/query-users?limit=${currentScreen * row}&page=${currentPage}&search=${searchQuery}&role=seller`;
            getAllSellers(apiUrl, 'post', setFilteredSellers, setTotalPages, setIsFetching, searchChange, setCurrentPage, setTotalSellets);
        }

        if (currentPage > totalPages && totalPages > 0) {
            setCurrentPage(1);
        }
        const itemsPerPage = row * currentScreen;
        const totalPage = Math.ceil((totalSellers - 2) / itemsPerPage);

        setFilteredSellers(filteredSellers);
        setTotalPages(totalPage);
        setIsFetching(false);
    }, [currentPage, searchQuery, row, currentScreen, isNewSellerClicked]);



    const handleSearch = (query) => {
        const formattedQuery = query.replace(/ /g, '-'); // Replace spaces with underscores
        setSearchQuery(formattedQuery);
        setSearchChange(true);
    };
    useEffect(() => {
        if (currentPage > totalPages && totalPages > 0) {
            setCurrentPage(1);
        }
    }, [totalPages]);

    const screenSizes = {
        isLargeScreen: useMediaQuery({ query: '(min-width: 1200px)' }),
        isMediumScreen: useMediaQuery({ query: '(min-width: 900px) and (max-width: 1199.99px)' }),
        isSmallScreen: useMediaQuery({ query: '(min-width: 600px) and (max-width: 899.99px)' }),
        isExtraSmallScreen: useMediaQuery({ query: '(max-width: 599.99px)' }),
    };

    useEffect(() => {
        const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } = screenSizes;
        if (isLargeScreen) {
            setCurrentScreen(4);
        } else if (isMediumScreen) {
            setCurrentScreen(3);
        } else if (isSmallScreen) {
            setCurrentScreen(2);
        } else if (isExtraSmallScreen) {
            setCurrentScreen(1);
        }

        const itemsPerPage = row * currentScreen;
        const totalPage = Math.ceil((totalSellers - 2) / itemsPerPage);
        setFilteredSellers(filteredSellers);
        setTotalPages(totalPage);
        setIsFetching(false);

    }, [screenSizes, setCurrentScreen]);

    return (
        <>
            <Helmet>
                <title>Cornucopia AI | Sellers</title>
            </Helmet>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <div style={{ marginBottom: '-200px' }}>
                    <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} />
                    <HeaderText style={{ marginLeft: isDesktop ? '5.37rem' : '', fontSize: isTab ? '' : '1.5rem', textAlign: isMobile && 'center' }}>{SALESFORCE}</HeaderText>
                    <FlexDiv style={{ justifyContent: 'center', marginTop: '2rem', marginLeft: isDesktop ? '5.37rem' : '1.5rem', marginRight: isDesktop ? '3.5rem' : '1.5rem' }}>
                        <SellerOptions isNewSellerClicked={isNewSellerClicked} setIsNewSellerClicked={setIsNewSellerClicked} />
                    </FlexDiv>
                    <FlexDiv style={{
                        flexDirection: isTab ? 'row' : 'column', justifyContent: isTab ? 'space-between' : 'center', marginLeft: isDesktop ? '5.37rem' : '1rem', marginRight: isDesktop ? '3.5rem' : (isSmallMobile ? '3.7rem' : '1rem'), marginTop: '1.5rem', gap: isTab ? '' : '2rem', alignItems: isTab ? '' : 'center'
                    }}>
                        <SellerSearch onSearch={handleSearch} />
                        {/* <SortBy selectedOption={selectedOption} onSortByChange={handleSortByChange} /> */}
                    </FlexDiv>
                    {isFetching ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', marginBottom: '-200px' }}>
                            {/* <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} /> */}
                            <SpinnerComponent />
                        </div>
                    ) : filteredSellers.length === 0 ? (
                        <div style={{ marginTop: '2rem', marginLeft: '1rem' }}>
                            <NoSeller width={'89%'} />
                        </div>
                    ) : (
                        <>
                            <div style={{ marginLeft: isMobile ? '' : '2.5rem' }}>
                                <SalesForce filteredSellers={filteredSellers}
                                    selectedOption={selectedOption}
                                    totalSalesForce={filteredSellers.length} cardWidth='13.875rem' lg={3} header={false} viewBtn={false} setCurrentScreen={setCurrentScreen} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '6.4rem', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '1', marginLeft: isMobile ? '0' : '11rem' }}>
                                    <CustomPagination
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                    />
                                </div>
                                {!isMobile &&
                                    <div style={{ marginRight: isMobile ? '0.5rem' : '3rem' }}>
                                        <ShowRow setRow={setRow} row={row} />
                                    </div>
                                }
                            </div>
                        </>
                    )}
                </div>
            </Container>
            <FooterDivider />
            <Footer />
        </>
    )
}
export default AllSellers;
