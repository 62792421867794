import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ZipFile from '../../assets/images/zip-file.svg';
import ZipFileTwo from '../../assets/images/zip-file-two.svg';
import ClearIcon from '@mui/icons-material/Clear';
import FolderZipIcon from '@mui/icons-material/FolderZip';

const cardStyle = {
    width: '100%',
    height: '8.875rem',
    borderRadius: '0.4375rem',
    border: '1px dashed #9D40B1',
    background: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
};

const textStyles = {
    color: '#9D40B1',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 'normal',
};

const imageStyle = {
    width: '1.1875rem',
    height: '1.1875rem',
    marginBottom: '0.25rem',
};

const ZipUploadBox = ({ selectedFile = null, setSelectedFile }) => {
    const [thumbnailUrl, setThumbnailUrl] = useState(selectedFile);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        // Create a URL for the selected file to be used as the thumbnail source
        const fileUrl = URL.createObjectURL(file);
        setThumbnailUrl(fileUrl);
    };

    const clearSelectedFile = () => {
        setSelectedFile(null);
        setThumbnailUrl(null);
    };

    return (
        <Card elevation={0} style={cardStyle}>
            <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                {selectedFile ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FolderZipIcon style={{ marginRight: '0.5rem' }} />
                        <div style={{ maxWidth: '20rem', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '3rem' }}>
                            {selectedFile && selectedFile.name ? (
                                selectedFile.name
                            ) : (
                                selectedFile
                            )}
                        </div>
                        <ClearIcon onClick={clearSelectedFile} style={{ marginLeft: '3rem' }} />
                    </div>
                ) : (
                    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <input
                            type="file"
                            accept=".zip, .rar, .7z, .tar, .gz, .bz2, .xz, .tar.gz, .tar.bz2, .tar.xz"
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                        />

                        <div style={{ display: 'flex' }}>
                            <img src={ZipFile} alt="Upload File" style={imageStyle} />
                            <img src={ZipFileTwo} alt="Upload File" style={imageStyle} />
                        </div>
                        <div style={textStyles}>Click to upload (zip, rar, etc.)</div>
                        <div style={{ ...textStyles, color: '#888', marginTop: '0' }}>or drag and drop</div>
                    </label>
                )}
            </CardContent>
        </Card>
    );
};

export default ZipUploadBox;
