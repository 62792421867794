function MiddleEllipsisText({ text, maxLength }) {
    if (text.length <= maxLength) {
      return <span>{text}</span>;
    }
  
    const ellipsis = '...';
    const middle = maxLength / 2;
    const start = text.slice(0, middle - ellipsis.length / 2);
    const end = text.slice(-middle + ellipsis.length / 2);
  
    return (
      <span>
        {start}
        {ellipsis}
        {end}
      </span>
    );
}
export default MiddleEllipsisText;