import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import { ConvertCentToDollar } from '../../../constants/helper';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';

export const pageSize = 12;

const circleColors = ['#6BBF8A', '#6B8FFF', '#FF9BBF', '#FF7F7F'];

export const DateCheckerContainer = styled.div`
  /* Apply your custom styles to the date picker container */
  width: 7.5625rem;
  height: 2.125rem;
  flex-shrink: 0;
  border-radius: 1.0625rem;
  border: 1px solid rgba(66, 0, 81, 0.62);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 0.75rem;
  color: #6D0085;
  position: relative;
  cursor: pointer;
`;

export const FromTo = styled.div`
    color: #767676;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const TableColumn = styled.div`
    margin-left: 0.7rem; /* Adjust this value as needed */
`;

export const AmountCell = styled.div`
  color: #58006B;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: bold;
  line-height: 137.188%; /* 1.20038rem */
  letter-spacing: 0.00438rem;
`;

export const DatedCell = styled.div`
color: #656565;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 137.188%; /* 1.20038rem */
letter-spacing: 0.00438rem;
`;

export const BuyerNameCell = styled.div`
display:flex;
color: #797979;
font-family: Poppins;
font-size: 0.9375rem;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const BuyerImage = styled.img`
  width: 1.5625rem;
  height: 1.5625rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
`;

export const ProductImage = styled.img`
    width: 1.5625rem;
    height: 1.5625rem;
    flex-shrink: 0;
     border-radius: 0.125rem;
`;

export const StatusCell = styled.div`
  width: 4.25rem;
  height: 1.3125rem;
  flex-shrink: 0;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusText = styled.span`
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 137.188%;
  letter-spacing: 0.00313rem;
`;

export const PaidStatus = styled(StatusCell)`
  background: #D6EEDD;
`;

export const PendingStatus = styled(StatusCell)`
  background: #EFE9D4;
`;

export const OverdueStatus = styled(StatusCell)`
background: #ECD7D7;
`;

export const CustomTable = styled(DataGrid)`
  margin: -0.75rem; /* Appllied negative margin to pull contents back */
  border: none; /* Remove the default table border */
`;

export const TableContainer = styled.div`
  padding: 1rem 2rem;
  margin-left: -0.5rem;
  border: 1px solid #DEDEDE;
  border-radius: 0.5625rem;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const MarketplaceText = styled.h1`
    color: #6D0085;
    font-family: Poppins;
    font-size: 1.4375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const FilterContainer = styled.div`
  display: flex;
  border-radius: 0.375rem;
  background: #F9F9F9;
  justify-content: space-between;
  height: 2.8125rem;
`;

export const AddNewProductBtn = styled.div`
    display: flex;
    width: 8.3125rem;
    height: 2.8125rem;
    border-radius: 0.375rem;
    background: #3F0065;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-weight: 600;
    transition: opacity 0.3s;
    &:hover {
      opacity:0.8;
    }
`;

export const TagDiv = styled.div`
    display: flex;
    height: 2.125rem;
    border-radius: 0.3125rem;
    background: #F9F9F9;
    color: #8F8F8F;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-weight: 500;
`;

export const TagData = ['Business', 'Analytics', 'Learning', 'Sales & Advertisement']

export const theme = createTheme({ components: { MuiDataGrid: {}, }, });

export const columns = [
    {
        field: 'serial', headerName: '#', flex: 0.5, align: 'center', cellClassName: 'custom-cell', headerClassName: 'transaction-header', headerAlign: 'center',
        renderCell: (params) => <TableColumn> <BuyerNameCell>{params.value}</BuyerNameCell></TableColumn>, minWidth: 50
    },
    {
        field: 'id', headerName: 'ID', flex: 0.5, align: 'center', cellClassName: 'custom-cell', headerClassName: 'transaction-header', headerAlign: 'center',
        renderCell: (params) => <TableColumn style={{ marginLeft: '1.5rem' }}><Tooltip title={params.value}><BuyerNameCell>{'#' + params.value.slice(0, 6) + '...'}</BuyerNameCell></Tooltip></TableColumn>, minWidth: 120,
    },
    {
        field: 'buyerName',
        headerName: 'Buyer Name',
        flex: 1,
        align: 'left',
        minWidth: 250,
        headerClassName: 'transaction-header',
        cellClassName: 'custom-cell',
        headerAlign: 'center',
        renderCell: (params) => {
            // Generate a unique color based on the row id
            const colorIndex = params.row.serial % circleColors.length;

            return (
                <TableColumn>
                    <BuyerNameCell style={{ marginLeft: '2rem' }}>
                        <Avatar
                            sx={{
                                bgcolor: circleColors[colorIndex],
                                width: '1.6rem',
                                height: '1.6rem',
                                fontSize: '0.8rem',
                                marginRight: '0.4rem',
                            }}
                        >
                            {params.row.buyerInitials}
                        </Avatar>
                        <Typography sx={{ fontFamily: 'Poppins', marginTop: '0.07rem' }}>{params.value}</Typography>
                    </BuyerNameCell>
                </TableColumn>
            );
        },
    },
    {
        field: 'productName',
        headerName: 'Product Name',
        flex: 1,
        align: 'left',
        minWidth: 250,
        headerClassName: 'transaction-header',
        cellClassName: 'custom-cell',
        renderCell: (params) => <DatedCell><ProductImage style={{ marginRight: '0.5rem' }} src={params.row.productImage} alt="Product" />{params.value}</DatedCell>,
    },
    { field: 'dated', headerName: 'Dated', flex: 1, align: 'left', headerClassName: 'transaction-header', minWidth: 140, renderCell: (params) => <DatedCell>{params.value}</DatedCell>, },
    {
        field: 'status', headerName: 'Status', flex: 1, align: 'left', minWidth: 110,
        headerClassName: 'transaction-header',
        cellClassName: 'custom-cell',
        renderCell: (params) => {
            const status = params.value;
            if (status === 'Paid') {
                return (
                    <PaidStatus style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                        <StatusText style={{ color: '#00976A' }}>{status}</StatusText>
                    </PaidStatus >
                );
            } else if (status === 'Pending') {
                return (
                    <PendingStatus style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <StatusText style={{ color: '#978800' }}>{status}</StatusText>
                    </PendingStatus>
                );
            }
            else if (status === 'Overdue') {
                return (
                    <OverdueStatus style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <StatusText style={{ color: '#970000' }}>{status}</StatusText>
                    </OverdueStatus>
                );
            }
        },
    },
    {
        field: 'amount', headerName: 'Amount', flex: 1, align: 'left', minWidth: 100, headerClassName: 'transaction-header',
        cellClassName: 'custom-cell', renderCell: (params) => <TableColumn style={{ marginleft: '0' }}><AmountCell>{ConvertCentToDollar(params.value)}</AmountCell></TableColumn>,
    },
];