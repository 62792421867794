import styled from "@emotion/styled";

export const ManageProfileText = styled.h1`
    color: #6D0085;
    font-family: Poppins;
    font-size: 1.4375rem;
    font-weight: 500;
`;

export const SaveBtn = styled.div`
    width: 10.125rem;
    height: 3.0625rem;
    border-radius: 0.3125rem;
    background: #3F0065;
    text-align: center;
    padding-top:0.6rem;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.25rem;
    font-weight: 500;        
    &:hover {
        border: 1px solid #3F0065;
        color: #3F0065;
        background: #FFF;
    }          
`;

export const ProductText = {
    color: "rgba(7, 7, 7, 0.67)",
    fontFamily: "Poppins",
    fontSize: "1.0625rem",
    fontWeight: '500',
    marginBottom: '0.5rem'
};

export const placeholderStyles = {
    color: '#747474',
    fontFamily: 'Poppins',
    fontSize: '0.9375rem',
    fontWeight: 500,
};

export const NotificationsDiv = styled.div`
    width: 100%;
    border-radius: 0.3125rem;
    border: 1px solid #D8D8D8;
`;

export const NotificationsHeading = {
    color: "rgba(64, 63, 64, 0.67)",
    fontFamily: "Poppins",
    fontSize: "1.0625rem",
    fontWeight: 500
}

export const NotificationsText = {
    color: "rgba(64, 63, 64, 0.67)",
    fontFamily: "Poppins",
    fontSize: "0.75rem",
    fontWeight: 400
}

export const HeaderCoverStyle = {
    height: '8.8125rem',
    borderRadius: '1.125rem',
    border: '1px solid rgba(66, 0, 81, 0.62)',
    width: '100%',
};

export const TokenExpired = (tokenDate) => {
    const convertedTime = new Date(tokenDate);
    const currentTime = new Date();
    if (currentTime >= convertedTime) {
        return true;
    }
    return false;
}

export const DisplayPicDiv = styled.div`
    position: absolute;
    left: 2.7rem;
    top: 100%;
    transform: translateY(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const EditDisplayDiv = styled.div`
    color: #626262;
    font-family: Poppins;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.12rem;
`;

export const DisplayPicImg = styled.img`
    width: 9.375rem;
    height: 9.375rem;
    border-radius: 9.375rem;
`;

export const UsernameDiv = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    width: 16.1875rem;
    height: 3.1875rem;
    border-radius: 0.3125rem;
    border: 1px solid #AFAFAF;
    background: #DADADA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.31rem;
`;

export const UsernameIconDiv = styled.div`
    width: 1rem;
    height: 1.25rem;
    margin-right: 0.75rem;
    margin-bottom: 0.5rem;
`;

export const UsernameText = styled.div`
    color: #747474;
    font-family: Poppins;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
