import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ScrollToTop from "./components/ScrollToTop";
import { NotificationProvider } from "./Context/NotificationContext";
import ReactGA4 from 'react-ga4';
import SpinnerComponent from "./components/Spinner";
import { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

// import { Container } from "@mui/material"; // Import Container from Material-UI

ReactGA4.initialize('G-TLZNSXGVF0');

ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time (you can replace this with your actual loading logic)
    const fakeLoading = setTimeout(() => {
      setLoading(false);
    }, 1500);

    // Clear the timeout on component unmount or when loading is done
    return () => clearTimeout(fakeLoading);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <NotificationProvider>
          {loading ? (
            <SpinnerComponent />
          ) : (
            <BrowserRouter>
              <ScrollToTop />
              {/* <Container maxWidth="xl" disableGutters> */}
              <Router />
              {/* </Container> */}
            </BrowserRouter>
          )}
        </NotificationProvider>
      </GoogleOAuthProvider>
    </LocalizationProvider>
  );
}

export default App;
