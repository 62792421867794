import React from 'react';
import BlankState from '../Utils/BlankState';
import TransactionIcon from '../../assets/images/ion_transaction.svg';

const NoTransactions = ({state_height='43.675rem'}) => {
    return (
        <BlankState text={'No transactions found'} icon={TransactionIcon} height={state_height} marginLeft={'4rem'} />
    );
};

export default NoTransactions;
