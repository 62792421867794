import Avatar from "@mui/material/Avatar";

const ReviewAvatar = ({ width = "1.9rem", height = "1.9rem", mobile = false, name='Felix Johnson', fontSize='0.8rem', topMargin = '0.4rem' }) => {

    const [firstName, lastName] = name.split(' ');
    const initials = `${firstName[0]}${lastName ? lastName[0] : ''}`;

    return (
        <Avatar
            sx={{
                bgcolor: '#ad4ead',
                width: width,
                height: height,
                fontSize: {fontSize},
                margin: mobile ? '' : `${topMargin} 0rem 0rem 0rem`,
            }}
            variant="rounded"
        >
            {`${initials}`}
        </Avatar>

    );
};
export default ReviewAvatar;