import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import CornucopiaIcon from '../../assets/images/cornucopia_landing_icon.png';
import CircularProgress from '@mui/material/CircularProgress';
import OnBoard from '../../assets/images/onboard_img.svg';
import { LoginBoxBtn } from '../../assets/styles/styles';
import { useTheme, useMediaQuery } from '@mui/material';
import Footer from '../../components/Footer';
import FooterDivider from '../../components/FooterDivider';
import { CONTINUE, LOREM_IPSUM_ONBOARD, ONBOARD_WITH_STRIPE } from '../../constants/text';
import { handleUpdateUser } from '../../Services/User';
import { onboardUserApi } from '../../Services/Payment';
import { Container } from '@mui/material';
import { SkipForNowDiv } from './style';

const Onboard = () => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false); // Add loading state
    const storedUser = localStorage.getItem('Cornucopia-user');
    const user = JSON.parse(storedUser);
    const userId = user.response.id;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate(); // Initialize useNavigate

    const textStyle = {
        color: '#6D0085',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: isMobile ? '1.3rem' : '2rem', // Adjusted font size for mobile
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.75rem',
    };

    const loremIpsumStyle = {
        color: '#676767',
        fontFamily: 'Poppins',
        fontSize: isMobile ? '0.8rem' : '1.375rem', // Adjusted font size for mobile
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.62rem',
        padding: isMobile ? '1rem' : ''
    };

    const updateUser = async (redirection_url) => {
        try {
            await handleUpdateUser(userId)
            setTimeout(() => {
                window.location.href = redirection_url;
            }, 200);
        } catch {
            console.log('Error');
        }
    }

    const handleConnectOnboarding = async (e) => {
        e.preventDefault();
        setLoading(true);

        const apiResponse = await onboardUserApi(userId);

        setLoading(false);

        if (apiResponse.success) {
            setResponse(apiResponse.data);

            // Check if the response contains a URL
            if (apiResponse.data.response) {
                updateUser(apiResponse.data.response);
            }
        } else {
            console.error(apiResponse.error);
            setResponse({ error: apiResponse.error });
        }
    };

    const handleSkipForNowClick = () => {
        const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
        userData.response.stripe_connect_account = {}; // Add an empty stripe_connect_account property
        localStorage.setItem('Cornucopia-user', JSON.stringify(userData)); // Update the localStorage
        navigate('/seller');
    };

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5.5rem', width: '100%', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={CornucopiaIcon} alt='' />
                        <img src={OnBoard} alt='' style={{ marginTop: '5.69rem' }} />
                    </div>
                    <SkipForNowDiv onClick={handleSkipForNowClick}>
                        Skip for now
                        <ArrowRightIcon style={{ marginLeft: '5px' }} /> {/* Add arrow icon */}
                    </SkipForNowDiv>
                </div>
                <div style={textStyle}>{ONBOARD_WITH_STRIPE}</div>
                <div style={loremIpsumStyle}>{LOREM_IPSUM_ONBOARD}</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <LoginBoxBtn
                        onClick={handleConnectOnboarding}
                        style={{
                            marginTop: '2.75rem',
                            width: isMobile ? '70%' : '18rem',
                            height: isMobile ? '3rem' : '3.6875rem',
                            flexShrink: 0,
                            borderRadius: '2.125rem',
                            background: '#6A007C',
                            color: '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: isMobile ? '16px' : '20px', // Adjusted font size for mobile
                        }}
                    >
                        {loading ?
                            <CircularProgress color="secondary" style={{ width: '27px', height: '25px' }} />
                            : CONTINUE}
                    </LoginBoxBtn>
                </div>
            </Container>
            <FooterDivider />
            <Footer />
        </>
    );
};

export default Onboard;
