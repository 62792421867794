import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const VideoModal = ({ open, onClose, videoURL }) => {
    useEffect(() => {
        console.log("Video URL:", videoURL);
    }, [videoURL]); // Log the videoURL whenever it changes

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogContent>
                {videoURL && (
                    <video width="100%" height="auto" controls autoPlay>
                        <source src={videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default VideoModal;
