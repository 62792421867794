// api.js
import axios from 'axios';
import {IP_ADD, LOCAL_HOST} from '../constants/api';

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/auth/login`, {
            email: email,
            password: password,
        });

        if (response.statusText === 'OK' || response.status=== 200) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
};
export const registerUser = async (userData) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/auth/register`, userData);
        return response;
    } catch (error) {
        throw error;
    }
};
export const buyerSignup = async (firstName, lastName, email, password, role) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/auth/register`, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            role: role,
        });
        let commonStructure = response.data.response.user
        if (commonStructure.message === 'User created successfully' || commonStructure.status === 200) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
};

export const logout = async (token) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/auth/logout`, {
            refreshToken: token,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const handleRefreshToken = async () => {
    try {
        const token = localStorage.getItem('refresh-token');
        const response = await axios.post(`${IP_ADD}/api/auth/refresh-tokens`, {
            refreshToken: token
        });
        if (response.status === 200) {
            localStorage.setItem('access-token', response.data.response.response.tokens.access.token);
            localStorage.setItem('refresh-token', response.data.response.response.tokens.refresh.token);
            localStorage.setItem('access-token-expiry', response.data.response.response.tokens.access.expires);
            localStorage.setItem('refresh-token-expiry', response.data.response.response.tokens.refresh.expires);
            return true;
        }
    } catch (error) {
        console.log('error', error);
        return false;
    }
};

export const sendVerificationEmail = async (email) => {
    try {
        const verificationResponse = await axios.post(`${IP_ADD}/api/auth/send-verification-email`, {
            email: email,
        });

    } catch (error) {
        console.error('Error sending verification email:', error);
        // Handle error sending verification email if needed
    }
};

export const verifyEmailApi = async (tokenFromUrl) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/auth/verify-email?token=${tokenFromUrl}`);

        if (response.status === 200) {
            return response.status; 
        }
    } catch (error) {
        console.error('Error verifying email:', error);
        throw error; 
    }
};

export const authenticateWithGoogle = async (googleToken) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/auth/google-auth`, { token: googleToken });

        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Failed to authenticate with Google. Backend error:', response.statusText);
            throw new Error('Failed to authenticate with Google');
        }
    } catch (error) {
        console.error('Error during Google Sign-In:', error);
        throw error;
    }
};

