import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { FlexDiv } from "../../assets/styles/styles";
import { MARK_READ, NOTIFICATIONS, ALL, CLEAR_ALL } from "../../constants/text";
import ChatNotification from "./ChatNotification";
import PurchaseNotification from "./PurchaseNotification";
import { useNotification } from "../../Context/NotificationContext";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { clearNotification, clearAllNotifications, markNotificationAsRead, markAllNotificationsAsReadApi, } from "../../Services/Notification";
import EmailNotification from "./EmailNotificaion";
const NotificationsContainer = styled.div`
    ${({ isScrollable }) => isScrollable && `
        max-height: 20rem;
        overflow-y: auto;
    `}
`;
const NotificationsHeading = {
    color: "#6D0085",
    fontFamily: "Poppins",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal"
}

const MarkAsRead = {
    color: "#9D40B1",
    fontFamily: "Poppins",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
}

const MarkAsReadLine = styled.div`
    width: 5.3125rem;
    height: 0.0625rem;    
    background: #9D40B1;
`;

const AllCount = styled.div`
    height: 0.9375rem;
    border-radius: 0.150rem;
    background: #9D40B1;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.65rem;
    font-style: normal;
    font-weight: 600; 
    text-align: center;  
    padding: 0rem 0.2rem;     
`;

const Notifications = () => {
    const { notification, notificationUpdated, setNotification, setNotificationUpdated } = useNotification();
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const isMobile = useMediaQuery({ maxWidth: 500 });
    const userRole = userData.response.role
    const userId = userData.response.id
    const navigate = useNavigate();
    const colorArray = ["#ad4ead", "#8a6e8b", "#5b59b6", "#3498db"];
    const isScrollable = notification.length > 4;

    const handleClearNotification = async (notificationId) => {
        try {
            await clearNotification(notificationId);

            const updatedNotifications = notification.filter(
                (item) => item._id !== notificationId
            );

            setNotification(updatedNotifications);
            setNotificationUpdated(true);
        } catch (error) {
            console.error("Error handling clear notification:", error);
        }
    };

    const handleClearAllNotifications = async () => {
        try {
            await clearAllNotifications(userId);

            setNotification([]);
            setNotificationUpdated(true);
        } catch (error) {
            console.error("Error clearing all notifications:", error);
        }
    };

    const markAllNotificationsAsRead = async () => {
        try {
            await markAllNotificationsAsReadApi(userId);
        } catch (error) {
            console.error("Error in reading notifications:", error);
        }

        const updatedNotifications = notification.map((item) => ({
            ...item,
            read: true,
        }));
        setNotification(updatedNotifications);
        setNotificationUpdated(true);
    };

    const handleMarkAsRead = async (notificationId, type) => {
        try {
            await markNotificationAsRead(notificationId, userId);
        } catch (error) {
            console.error("Error in reading notifications:", error);
        }

        const updatedNotifications = notification.map((item) =>
            item._id === notificationId ? { ...item, read: true } : item
        );

        setNotification(updatedNotifications);
        setNotificationUpdated(true);
        if (type === 'message') {
            if (userRole === "buyer") {
                navigate("/inboxbuyer");
            } else if (userRole === "seller") {
                navigate("/seller/inbox");
            }
        }
    };

    const reversedNotifications = [...notification].reverse();
    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * colorArray.length);
        return colorArray[randomIndex];
    };
    return (
        <>
            <div style={{ maxWidth: isMobile ? '19rem' : '31rem', borderRadius: '0.6875rem', border: '#9D40B1 0.5px solid', paddingBottom: '1rem', background: 'white' }}>
                <div style={{ padding: '1.44rem 1.5rem 0rem 1.69rem', width: isMobile ? '18rem' : '28rem' }}>
                    <FlexDiv style={{ justifyContent: 'space-between' }}>
                        <Typography sx={{ ...NotificationsHeading, fontSize: isMobile ? '1rem' : '1.5rem' }}>{NOTIFICATIONS}</Typography>
                        <button style={{ display: 'contents' }} onClick={markAllNotificationsAsRead} >
                            <div style={{ marginTop: isMobile ? '0.2rem' : '0.5rem', }}>
                                <Typography sx={MarkAsRead}>{MARK_READ}</Typography>
                                <MarkAsReadLine />
                            </div>
                        </button>
                    </FlexDiv>
                    <FlexDiv style={{ justifyContent: 'space-between', marginTop: '1rem' }}>
                        <FlexDiv>
                            <Typography sx={MarkAsRead} style={{ fontSize: '0.9375rem', fontWeight: '600' }}>{ALL}</Typography>
                            {(notification?.length > 0) &&
                                <AllCount style={{ marginLeft: '0.3rem', marginTop: isMobile ? '0.25rem' : '0.2rem', paddingBottom: '0.2rem' }}>{notification.length}</AllCount>
                            }
                        </FlexDiv>
                        <button style={{
                            display: 'contents', transition: 'opacity 0.3s',
                            opacity: 1,
                        }}
                            onMouseOver={(e) => e.target.style.opacity = 0.8}
                            onMouseOut={(e) => e.target.style.opacity = 1} onClick={handleClearAllNotifications}>
                            <Typography sx={{ ...MarkAsRead }}>{CLEAR_ALL}</Typography>
                        </button>
                    </FlexDiv>
                </div>
                <div style={{ height: '0.0625rem', background: "#D9D9D9" }} />
                <NotificationsContainer isScrollable={isScrollable}>
                    {notificationUpdated && reversedNotifications.map((notificationItem, index) => (
                        notificationItem.notificationType === 'message' ? (
                            <ChatNotification
                                key={index}
                                firstName={notificationItem?.sender?.firstName}
                                lastName={notificationItem?.sender?.firstName}
                                id={notificationItem._id}
                                onClearNotification={handleClearNotification}
                                onMarkAsRead={handleMarkAsRead}
                                newNotification={!notificationItem.read}
                                time={notificationItem.createdAt}
                                color={getRandomColor()}
                            />
                        ) : notificationItem.notificationType === 'payment' ? (
                            <PurchaseNotification
                                key={index}
                                newNotification={!notificationItem.read}
                                id={notificationItem._id}
                                firstName={notificationItem?.sender?.firstName}
                                lastName={notificationItem?.sender?.lastName}
                                onClearNotification={handleClearNotification}
                                onMarkAsRead={handleMarkAsRead}
                                message={notificationItem.message}
                                time={notificationItem.createdAt}
                                color={getRandomColor()}

                            />
                        ) : notificationItem.notificationType === 'webPayment' ? (
                            <EmailNotification
                                key={index}
                                newNotification={!notificationItem.read}
                                id={notificationItem._id}
                                name={notificationItem?.sender?.firstName}
                                onClearNotification={handleClearNotification}
                                onMarkAsRead={handleMarkAsRead}
                                message="Purchase successful please check your email"
                                time={notificationItem.createdAt}
                                productId={notificationItem.product}
                            />
                        ) : null
                    ))}

                </NotificationsContainer>
            </div>
        </>
    )
}
export default Notifications;