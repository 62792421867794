import React, { useState } from 'react';
import { InputFilter, InputFilterDiv, InputFilterButton, MagnifyGlassImg } from '../../assets/styles/styles';
import { HOW_CAN_WE_HELP } from '../../constants/text';
import Magnify from '../../assets/images/magnifying_glass.svg';
import { useMediaQuery } from 'react-responsive';

const LandingPageInputBox = ({ onSearch = () => { }, padding_top }) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });
    const [searchTerm, setSearchTerm] = useState('');

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Execute search when Enter key is pressed
            onSearch(searchTerm);
        }
    };

    const handleMagnifyClick = () => {
        onSearch(searchTerm);
    };

    return (
        <InputFilterDiv style={{
            paddingTop: padding_top, paddingLeft: !isMobile ? '6.38rem' : '0rem', paddingRight: !isMobile ? '4rem' : '0rem', marginLeft: isMobile ? '1rem' : '', marginRight: isMobile ? '1rem' : ''
        }}>
            <InputFilter
                placeholder={HOW_CAN_WE_HELP}
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
                onKeyPress={handleKeyPress}
            />
            <InputFilterButton onClick={handleMagnifyClick}>
                <MagnifyGlassImg alt="" src={Magnify} />
            </InputFilterButton>
        </InputFilterDiv >
    );
};

export default LandingPageInputBox;
