import React from "react";
import { FlexDiv } from "../../assets/styles/styles";
import { Typography, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavBar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { saveAs } from 'file-saver';
import FooterDivider from "../../components/FooterDivider";
import { useState, useEffect } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import eye from '../../assets/images/eye.svg';
import VerticalLine from '../../assets/images/vertical_line.svg';
import DateChecker from "../../components/SellerDashboard/DateChecker";
import { useMediaQuery } from "react-responsive";
import { FILTERS, MY_PRODUCTS } from "../../constants/text";
import NoProduct from "../../components/SellerDashboard/NoProducts";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup'
import { useNavigate } from "react-router-dom";
import { cellArray, CategoryFilters, convertTo2D } from "../../constants/helper";
import { fetchBuyerPurchasedProducts } from "../../Services/Products";
import CustomPagination from "../../components/CustomPagination";
import { Helmet } from "react-helmet";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import SpinnerComponent from "../../components/Spinner";
import { Heading, ActiveText, FilterContainer, TagDiv, HEADER_DATA, TableHeaders } from "./constants";
import { PaidStatus, StatusText } from "../../components/SellerDashboard/Transactions/constants";
import { Container } from "@mui/material";
import { useNotification } from "../../Context/NotificationContext";

function constructAbsoluteUrl(url) {
    // Check if the URL is absolute
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        // If URL is not absolute, prepend "https://"
        return `https://${url}`;
    }

    // If URL is absolute, return it as is
    return url;
}

const BuyerPurchasedProducts = () => {
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const isLaptop = useMediaQuery({ minWidth: 930 });
    const isTab = useMediaQuery({ minWidth: 640 });
    const isMobile = useMediaQuery({ maxWidth: 450 });
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [searchQuery, setSearchQuery] = useState('');
    const [apiData, setApiData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeCategory, setActiveCategory] = useState('All');
    const storedUser = localStorage.getItem('Cornucopia-user');
    const user = JSON.parse(storedUser);
    const userId = user.response.id;
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [loading, setLoading] = useState(true);
    const { selected } = useNotification();
    const navigate = useNavigate();

    const handleProductClick = (id) => {
        navigate(`/product-details/${id}`);
    };

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };

    const addOrRemoveFilter = (value) => {
        setSelectedFilters((prevFilters) =>
            prevFilters.includes(value)
                ? prevFilters.filter((filter) => filter !== value)
                : [...prevFilters, value]
        );
    }

    const handleFilterChange = (event) => {
        event.stopPropagation();
        const value = event.target.value;
        addOrRemoveFilter(value);
    };

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };
    const fetchData = async () => {
        try {
            setLoading(true);
            if (searchQuery !== '') {
                setCurrentPage(1);
            }
            let typeParam = ''; // Initialize type parameter
            if (activeCategory === 'website') {
                typeParam = 'web';
            } else if (activeCategory === 'deskapp') {
                typeParam = 'desktop';
            }
            const { products, totalPages } = await fetchBuyerPurchasedProducts({
                userId,
                currentPage,
                selectedStartDate,
                selectedEndDate,
                selectedFilters,
                searchQuery,
                type: selected,
            });
            if (currentPage > totalPages) {
                setCurrentPage(1);
            }
            setApiData(products);
            setTotalPages(totalPages);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [userId, currentPage, selectedStartDate, selectedEndDate, selectedFilters, selected]);

    useEffect(() => {
        const handleClickAway = (event) => {
            if (anchorEl && !anchorEl.contains(event.target)) {
                handleFilterClose();
            }
        };

        window.addEventListener('click', handleClickAway);

        return () => {
            window.removeEventListener('click', handleClickAway);
        };
    }, [anchorEl]);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const dynamicProducts = apiData.map((data) => {
        const dateObject = new Date(data.createdAt);
        const formattedDate = dateObject.toLocaleDateString('en-GB');
        return {
            id: data.originalProduct,
            productImg: data.images[0],
            title: data.name,
            price: `$${data.price}`,
            purchasedOn: formattedDate, // Use the formatted date
            category: data.category.join(', '),
            totalReviews: data.totalReviews,
            productFile: data.productFiles ? data.productFiles[0] : null,
            type: data.type,
            url: data.url
        };
    });

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const handleDownloadClick = (id, productFile) => {
        if (productFile) {
            downloadFile(productFile);
        } else {
            setSnackbarMsg('Product file not found');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };
    const downloadFile = (fileUrl) => {
        saveAs(fileUrl, 'downloaded-file');
    };

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <Helmet>
                    <title>Cornucopia AI | My Products</title>
                </Helmet>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        severity={snackbarSeverity}
                    >
                        {snackbarMsg}
                    </MuiAlert>
                </Snackbar>
                <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'260px'} authenticated={true} />
                <div style={{ margin: isTab ? '2rem 3rem -12rem 3rem' : '2rem 1.5rem -12rem 1.5rem' }}>
                    <FlexDiv style={{ justifyContent: isTab ? 'space-between' : 'center', flexDirection: isTab ? 'row' : 'column' }}>
                        <Typography sx={Heading} style={{ fontSize: isTab ? '' : '2rem' }}>{MY_PRODUCTS}</Typography>
                        {/* <FlexDiv style={{ gap: '1rem', marginTop: '1.5rem', justifyContent: isTab ? '' : 'center' }}>
                            <Typography sx={{ ...ActiveText, color: activeCategory === 'All' ? '#6D0085' : '#C2C2C2', transition: 'color 0.3s', cursor: 'pointer', ':hover': { color: '#6D0085' } }} onClick={() => handleCategoryClick('All')}>All</Typography>
                            <Typography sx={{ ...ActiveText, color: activeCategory === 'website' ? '#6D0085' : '#C2C2C2', transition: 'color 0.3s', cursor: 'pointer', ':hover': { color: '#6D0085' } }} onClick={() => handleCategoryClick('website')}>Website</Typography>
                            <Typography sx={{ ...ActiveText, color: activeCategory === 'deskapp' ? '#6D0085' : '#C2C2C2', transition: 'color 0.3s', cursor: 'pointer', ':hover': { color: '#6D0085' } }} onClick={() => handleCategoryClick('deskapp')}>DeskApp</Typography>
                        </FlexDiv> */}
                    </FlexDiv>
                    <FlexDiv style={{ justifyContent: isLaptop ? 'space-between' : 'center', marginTop: '1rem', flexDirection: isLaptop ? 'row' : 'column', gap: isLaptop ? '' : '1rem' }}>
                        <TextField
                            placeholder="Search for Products"
                            variant="outlined"
                            size="small"
                            onChange={event => setSearchQuery(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    fetchData();
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <>
                                        <SearchIcon style={{ color: '#9E9E9E', marginRight: '0.5rem' }} />
                                        <div style={{ width: '1.43rem', height: '1.3px', background: '#DEDEDE', transform: 'rotate(90deg)' }}></div>
                                    </>
                                ),
                                sx: {
                                    width: isLaptop ? '38.8125rem' : '100%',
                                    height: '2.8125rem',
                                    borderRadius: '0.375rem',
                                    background: '#F9F9F9',
                                    '& .MuiOutlinedInput-root': {
                                        border: 'none',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none', // Remove the outlined border
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: 'rgba(35, 35, 35, 0.9)',
                                    fontFamily: 'Poppins',
                                    fontSize: '0.9375rem',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                },
                            }}
                        />
                        <button style={{ display: 'contents' }}>
                            <FilterContainer onClick={handleFilterClick}>
                                <FlexDiv>
                                    <SearchIcon sx={{ color: '#8F8F8F', margin: '0.9rem 0rem 0rem 0.75rem', width: '1.2rem', height: '1.2rem' }} onClick={fetchData} />
                                    <div style={{ width: '1.43rem', height: '1.3px', background: '#DEDEDE', transform: 'rotate(90deg)', margin: '1.4rem 0.3rem 0rem 0.5rem' }}></div>
                                </FlexDiv>
                                <h1 style={{ color: '#6D0085', fontFamily: 'Poppins', fontSize: '1rem', fontWeight: '500', marginTop: '0.8rem', marginLeft: '1.5rem', marginRight: '1.5rem' }}>{FILTERS}</h1>
                                <ExpandMoreIcon style={{ color: '#76108C', margin: '0.65rem 0.3rem 0rem 1rem' }} />
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleFilterClose}
                                    elevation={1}
                                    PaperProps={{
                                        style: {
                                            width: anchorEl ? anchorEl.clientWidth : 'auto', // Set the width to match the button
                                            maxHeight: '200px',
                                        },
                                    }}
                                >
                                    <FormGroup>
                                        {CategoryFilters.map((filter) => (
                                            <MenuItem key={filter}>
                                                <label>
                                                    <Checkbox
                                                        value={filter}
                                                        checked={selectedFilters.includes(filter)}
                                                        onChange={handleFilterChange}
                                                    />
                                                    {filter}
                                                </label>
                                            </MenuItem>
                                        ))}
                                    </FormGroup>
                                </Menu>
                            </FilterContainer>
                        </button>
                    </FlexDiv>
                    <FlexDiv style={{ marginTop: '1rem', justifyContent: isLaptop ? 'space-between' : 'center' }}>
                        {isLaptop &&
                            <FlexDiv style={{ flexDirection: 'column', gap: '0.5rem' }}>
                                {convertTo2D(selectedFilters, 4).map((listData) => (
                                    <FlexDiv style={{ gap: '0.5rem' }}>
                                        {listData.map((data) => (
                                            <TagDiv key={data}>
                                                <div style={{ margin: '0.5rem 0.3rem 0rem 0.5rem' }}>{data}</div>
                                                <IconButton onClick={() => addOrRemoveFilter(data)}>
                                                    <CloseIcon sx={{ color: '#8F8F8F' }} />
                                                </IconButton>
                                            </TagDiv>
                                        ))}
                                    </FlexDiv>
                                ))}
                            </FlexDiv>
                        }
                        <FlexDiv>
                            <DateChecker onStartdateSelected={handleStartDateChange} onEndDateSelected={handleEndDateChange} toMarginRight="0.5rem" toMarginLeft="0.5rem" flexDirec="column" />
                        </FlexDiv>
                    </FlexDiv>
                    {loading ? (
                        // <CircularProgress color='secondary' style={{ margin: '1rem auto', display: 'block' }} />
                        <SpinnerComponent />
                    ) : dynamicProducts.length === 0 ? (
                        <div style={{ marginTop: '1rem' }}>
                            <NoProduct />
                        </div>
                    ) : (
                        <>
                            <div>
                                <TableContainer style={{ marginTop: '1rem', padding: '0rem 0.5rem 1rem 0.2rem' }}>
                                    <Table aria-label="customized table"
                                        sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                borderBottom: "none"
                                            }
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {TableHeaders.map((data) => (
                                                    <TableCell key={data.name} align={`${data.align}`} sx={{ color: 'rgba(66, 0, 81, 0.62)', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: 'bold', }}>{data.name}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dynamicProducts.map((data, index) => (
                                                <React.Fragment key={data.id}>
                                                    <TableRow key={`row-${data.id}`} sx={{ borderRadius: '0.375rem', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.22)', '&:hover': { border: '1px solid rgba(157, 64, 177, 0.53)', boxShadow: '0px 0px 13px 0px rgba(157, 64, 177, 0.23)' } }}>
                                                        <TableCell ><img src={data.productImg} alt="" style={{ width: '3.5rem', height: '3.25rem' }} /></TableCell>
                                                        <TableCell component="th" scope="row" sx={{ color: '#393939', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: '600' }}>{data.title}</TableCell>
                                                        <TableCell sx={{ color: '#434343', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: '400' }}>{data.purchasedOn}</TableCell>
                                                        <TableCell sx={{ color: '#434343', fontFamily: 'Poppins', fontSize: '0.875rem', fontWeight: '400' }}>
                                                            <Tooltip title={Array.isArray(data.category) ? data.category.join(', ') : data.category}>
                                                                <span>{((Array.isArray(data.category) ? data.category.join(', ') : data.category).length > 29) ? ((Array.isArray(data.category) ? data.category.join(', ') : data.category).slice(0, 29) + '...') : (Array.isArray(data.category) ? data.category.join(', ') : data.category)}</span>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell>
                                                            <PaidStatus style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', background: data.type === 'web' ? '#D6EEDD' : '#EFE9D4', width: data.type === 'web' ? '4.25rem' : '4.75rem' }}>
                                                                <StatusText style={{ color: data.type === 'web' ? '#00976A' : '#978800' }}>{data.type === 'web' ? 'Website' : 'Desktop App'}</StatusText>
                                                            </PaidStatus>
                                                        </TableCell>
                                                        <TableCell sx={{ color: '#58006B', fontFamily: 'Poppins', fontSize: '0.9375rem', fontWeight: '600' }}>{data.price}</TableCell>
                                                        <TableCell sx={{ color: '#434343', fontFamily: 'Poppins', fontSize: '0.9375rem', fontWeight: '500' }}><div style={{ marginLeft: '1rem' }}>{data.totalReviews}</div></TableCell>
                                                        <TableCell align="center">
                                                            <FlexDiv style={{ justifyContent: 'center' }}>
                                                                <Tooltip title="Details">
                                                                    <img src={eye} alt="" onClick={() => handleProductClick(data.id)} style={{ cursor: 'pointer' }} />
                                                                </Tooltip>
                                                                <img src={VerticalLine} alt="" style={{ margin: '0rem 1rem' }} />
                                                                {data.type === 'web' ?
                                                                    <Tooltip title="Url">
                                                                        <a href={constructAbsoluteUrl(data.url)} target="_blank" rel="noopener noreferrer">
                                                                            <LinkIcon color="disabled" sx={{ marginTop: '0.5rem', cursor: 'pointer' }} />
                                                                        </a>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title="Download File">
                                                                        <DownloadIcon color="disabled" sx={{ marginTop: '0.5rem', cursor: 'pointer' }} onClick={() => handleDownloadClick(data.id, data.productFile)} />
                                                                    </Tooltip>
                                                                }
                                                            </FlexDiv>
                                                        </TableCell>
                                                    </TableRow>
                                                    {/* Add an empty row for visual spacing */}
                                                    <TableRow key={`empty-row-${data.id}`}>
                                                        {cellArray(7).map((_, cellIndex) => (
                                                            <TableCell key={`empty-cell-${cellIndex}`} />
                                                        ))}
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <FlexDiv style={{ justifyContent: isMobile ? 'center' : 'space-between' }}>
                                {!isMobile && (
                                    <FlexDiv style={{ marginTop: '0.3rem' }}>
                                        <Typography variant="body2" color="textSecondary">
                                            {`Showing 1 to ${Math.min(7, dynamicProducts.length)} of ${dynamicProducts.length} entries`}
                                        </Typography>
                                    </FlexDiv>
                                )}
                                <FlexDiv style={{ justifyContent: isMobile ? 'center' : 'center' }}>
                                    <CustomPagination
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                    />
                                </FlexDiv>
                            </FlexDiv>
                        </>
                    )}
                </div>
            </Container>
            <FooterDivider />
            <Footer />
        </>
    )
}
export default BuyerPurchasedProducts;