import styled from "@emotion/styled";

export const checkboxLabels = ['Business', 'E-commerce', 'E-commerce', 'E-commerce', 'Price Range'];

export const secondaryCheckboxLabels = [
    'Business',
    'Learning',
    'Analytics',
    'NLP',
    'Computer Vision',
    'Speech Processing',
    'Audio Processing',
    'E-Commerce',
    'IoT',
    'Gaming',
    'Robotics',
    'Augmented Reality',
    'Virtual Reality',
    'Education Technology',
    'Predictive Maintenance',
    'Recommender Systems',
];

export const SliderMinMax = styled.div`
    color: #3F0065;
    font-family: Poppins;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 102.5%;
    letter-spacing: 0.005rem;
    margin-right: 0.5rem; 
    color: #3F0065; 
    font-family: Poppins;
    font-size: 0.625rem; 
    font-style: italic; 
    font-weight: 400; 
    line-height: 102.5%; 
    letter-spacing: 0.00625rem;    
`;

export const ClearAll = styled.div`
    color: #878787;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const SliderValue = styled.div`
    color: #3F0065;
    font-family: Poppins;
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 102.5%; /* 0.64063rem */
    letter-spacing: 0.00625rem;
    margin-left: 0.44rem; 
    color: #3F0065;
    font-family: Poppins; 
    font-size: 0.625rem; 
    font-style: italic; 
    font-weight: 400; 
    line-height: 102.5%; 
    letter-spacing: 0.00625rem; 
    white-space: nowrap;     
`;

export const FilterTextStyle = {
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '102.5%',
    letterSpacing: '0.01rem',
}

export const ClearAllDiv = styled.div`
    display: flex; 
    justify-content: flex-end; 
    margin-top: 1.5rem;
    margin-bottom: 2rem; 
    padding-right: 1.5rem;
`;

export const ClearAllBtn = styled.button`
    height: 1.875rem;
    border-radius: 0.25rem;
    border: 1px solid #878787;
    margin-right: 1rem;
    background-color: transparent;
`;

export const DoneBtn = styled.button`
    width: 5.625rem;
    height: 1.875rem;
    border-radius: 0.25rem;
    background: #9803fa;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition: opacity 0.3s;
    &:hover {
        opacity: 0.8;
    }
`;

export const ExpansionDiv = styled.div`
    width: 100%;
    height: 2.625rem;
    border-radius: 0.75rem 0.75rem 0rem 0rem;
    border-top: 1px solid #9803fa;
    background: #9803fa;
    box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.31);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    top: 0;
    cursor: pointer;
    zIndex: 1;
    overflow-x: auto;
`;