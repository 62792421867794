import styled from "@emotion/styled";
import { TableCell } from "@mui/material";
import totalBuyersLine from '../../../assets/images/total_buyers_line.svg';
import ProductsLine from '../../../assets/images/products_line.svg';
import ExpensesLine from '../../../assets/images/expenses_line.svg';
import ProfitLine from '../../../assets/images/profit_line.svg';
import totalBuyersImg from '../../../assets/images/total_buyers_img.svg';
import GreenArrow from '../../../assets/images/green_arrow.svg';
import RedArrow from '../../../assets/images/red_arrow.svg';
import ProductsImg from '../../../assets/images/analytics_products_img.svg';
import ExpensesImg from '../../../assets/images/expenses_dollar.svg';
import ProfitImg from '../../../assets/images/profit_dollar.svg';
import magnify_glass from '../../../assets/images/magnify_glass.svg';
import profilePicture from '../../../assets/images/profilePicture.png'
import ItemImg from '../../../assets/images/productImage.png'
import Calendar from '../../../assets/images/calendar.svg';
import GrayDownArrow from '../../../assets/images/gray_down_arrow.svg'

export { magnify_glass, profilePicture, ItemImg, Calendar, GrayDownArrow };

export const DashboardHeading = styled.h1`
    font-family: Poppins;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
`;

export const DashboardSubHeading = styled.h3`
    color: #9A9A9A;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const DownloadReport = styled.div`
    width: 11.4375rem;
    height: 2.8125rem;
    border-radius: 1.40625rem;
    background: #3F0065;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    align-items: center;   
`;

export const DownloadReportText = styled.h3`
    color: #FFF;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    margin-top: 0.7rem;
`;

export const TransactionText = styled.h1`
    color: #808080;
    font-family: Poppins;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const AnalyticsDiv = styled.div`
    width: 14.375rem;
    height: 6.5rem;
    border-radius: 0.5625rem;
    background: #F9F9F9;
`;

export const ThisMonthText = styled.h3`
    color: #8F8F8F;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const TableOuterDiv = styled.div`
    border-radius: 0.5625rem;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const TransactionStatusDiv = styled.div`
    width: 4.25rem;
    height: 1.3125rem;
    border-radius: 0.75rem;
    background: #D6EEDD;
    color: #00976A;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 137.188%;
    letter-spacing: 0.00313rem;    
    text-align: center;
    padding-top: 0.2rem;
`;

export const StyledTableCell = styled(TableCell)`
  color: #797979;
  font-family: Poppins;
  font-size: 0.9375rem;
  font-weight: 400;
`;

export const AnalyticsData = [
    {
        name: 'Total Buyers',
        total: '0',
        percent: '+6.57%',
        lastUpdated: 'since last month',
        percentColor: '#00A44B',
        arrow: GreenArrow,
        image: totalBuyersImg,
        line: totalBuyersLine,
        allMargins: '1.5rem 0rem 0rem 1.6rem'
    },
    {
        name: 'Net Profit',
        total: '0',
        percent: '-0.27%',
        percentColor: '#C50000',
        lastUpdated: 'since last month',
        arrow: RedArrow,
        image: ProfitImg,
        line: ProfitLine,
        allMargins: '1.5rem 0rem 0rem 2.5rem'
    },
    {
        name: 'Products',
        total: '0',
        percent: '-3.23%',
        percentColor: '#C50000',
        lastUpdated: 'since last month',
        arrow: RedArrow,
        image: ProductsImg,
        line: ProductsLine,
        allMargins: '1.5rem 0rem 0rem 2.5rem'
    },
    {
        name: 'Expenses',
        total: '0',
        percent: '+1.23%',
        lastUpdated: 'since last month',
        percentColor: '#00A44B',
        arrow: GreenArrow,
        image: ExpensesImg,
        line: ExpensesLine,
        allMargins: '1.5rem 0rem 0rem 2.5rem'
    },
];

export const data = [
    { name: 'Jan', value: 400 },
    { name: 'Feb', value: 600 },
    { name: 'Mar', value: 200 },
    { name: 'Apr', value: 1000 },
    { name: 'May', value: 2200 },
    { name: 'Aug', value: 700 },
    { name: 'Sept', value: 900 },
    { name: 'Oct', value: 1500 },
    { name: 'Nov', value: 2100 },
    { name: 'Dec', value: 440 },
];

export const TableHeaders = ['No.', 'Id Customers', 'Customers Name', 'Items Name', 'Order Date', 'Status', 'Price'];

export const options = {
    chart: {
        type: 'donut',
    },
    labels: ['Total Paid', 'Total Overdue', 'Total Unpaid'],
    colors: ['#3F0065', 'rgba(136, 33, 158, 1)', 'rgba(219, 211, 221, 1)'],
    dataLabels: {
        style: {
            fontSize: '10px',
        },
    },
    legend: {
        show: true,
        position: 'right',
        labels: {
            colors: ['#6C6C6C', '#6C6C6C', '#6C6C6C'],
        },
    },
    plotOptions: {
        pie: {
            donut: {
                labels: {
                    show: true,
                    total: {
                        show: true,
                        showAlways: true,
                        label: 'Transactions',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontWeight: '500',
                    }
                }
            }
        }
    },

    responsive: [
        {
            breakpoint: 440,
            options: {
                legend: {
                    position: 'bottom',
                },
            },
        },
    ],

};

export const pageSize = 4;

export const series = [43, 27, 30];

export const circleColors = ['#6BBF8A', '#6B8FFF', '#FF9BBF', '#FF7F7F'];

