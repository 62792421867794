import React, { useState, useEffect, useMemo } from 'react';
import { FlexDiv } from '../../../assets/styles/styles';
import { useMediaQuery } from 'react-responsive';
import exportDataIcon from '../../../assets/images/export_data.svg';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import { ThemeProvider } from '@mui/material/styles';
import '../../../App.css';
import { InputAdornment } from "@mui/material";
import DateChecker from '../DateChecker';
import moment from 'moment';
import UserProfileSeller from '../UserProfileSeller';
import { TRANSACTIONS, EXPORT_DATA } from '../../../constants/text';
import NoTransactions from '../NoTransactions'
import { MarketplaceText, AddNewProductBtn, TableContainer, CustomTable, theme, columns, pageSize } from './constants';
import { fetchTransactionsApi, exportTransactionsApi } from '../../../Services/Transaction';
import CustomPagination from '../../CustomPagination';
import { Helmet } from 'react-helmet';
import CircularProgress from '@mui/material/CircularProgress';
import SpinnerComponent from '../../Spinner';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Transactions = () => {
    const isDesktop = useMediaQuery({ minWidth: 1280 });
    const isDesktopTwo = useMediaQuery({ minWidth: 1200 });
    const isSmallDesktop = useMediaQuery({ minWidth: 1000 });
    const isLargeLaptop = useMediaQuery({ minWidth: 720 });
    const isLaptop = useMediaQuery({ minWidth: 620 });
    const isMobile = useMediaQuery({ minWidth: 420 });
    const isSmallMobile = useMediaQuery({ maxWidth: 410 });
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [rows, setRows] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [exportingData, setExportingData] = useState(false);
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const userId = userData.response.id
    const navigate = useNavigate();

    // Function to fetch transactions from the API
    const fetchTransactions = async () => {
        try {
            const apiResponse = await fetchTransactionsApi(userId, currentPage, searchQuery, selectedStartDate, selectedEndDate);

            if (apiResponse) {
                setTotalPages(apiResponse.totalPages);
                setRows(apiResponse.rows);
            }
        } finally {
            setLoading(false); // Set loading to false after the API request is complete
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, [currentPage, selectedStartDate, selectedEndDate]);

    const handleNavigateEarning = () => {
        navigate('/seller/earnings')
    }
    const filteredRows = useMemo(() => {
        return rows.filter((row) => {
            const lowerSearchQuery = searchQuery.toLowerCase();
            const transactionDate = moment(row.dated, 'DD/MM/YYYY HH:mm');
            const isAfterStartDate =
                !selectedStartDate || transactionDate.isSameOrAfter(selectedStartDate, 'day');
            const isBeforeEndDate =
                !selectedEndDate || transactionDate.isSameOrBefore(selectedEndDate, 'day');
            return (
                isAfterStartDate &&
                isBeforeEndDate &&
                (row.buyerName.toLowerCase().includes(lowerSearchQuery) ||
                    row.productName.toLowerCase().includes(lowerSearchQuery) ||
                    row.id.toString().includes(lowerSearchQuery))
            );
        });
    }, [rows, searchQuery, selectedStartDate, selectedEndDate]);


    useEffect(() => {
        if (searchQuery !== '') {
            setCurrentPage(1);
        }
    }, [searchQuery]);

    useEffect(() => {
        if (selectedStartDate || selectedEndDate) {
            setCurrentPage(1);
        }
    }, [selectedStartDate, selectedEndDate]);

    const handleExportButtonClick = async () => {
        try {
            setExportingData(true);
            const apiResponse = await exportTransactionsApi(userId);

            if (apiResponse.success) {
                const { data: blob } = apiResponse;

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'transactions.csv';
                link.click();
            } else {
                console.error(apiResponse.error);
            }
            setExportingData(false);
        } catch (error) {
            console.error('Error exporting data:', error.message);
        }
    };


    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    useEffect(() => {
        setLoading(true);
    }, [])
    useEffect(() => {
        // Calculate the start and end indices based on the current page and page size
        const newStartIndex = (currentPage - 1) * pageSize + 1;
        const newEndIndex = Math.min(newStartIndex + pageSize - 1, filteredRows.length);
        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);
    }, [currentPage, pageSize, filteredRows]);

    return (
        <>
            <Helmet>
                <title>Cornucopia AI | Transactions</title>
            </Helmet>
            <FlexDiv style={{ justifyContent: isLargeLaptop ? 'space-between' : 'flex-end', alignItems: 'center' }}>
                {isLargeLaptop && <MarketplaceText style={{ marginTop: '1.8rem' }}>{TRANSACTIONS}</MarketplaceText>}
                <FlexDiv style={{ marginTop: '1.2rem', gap: isLaptop ? '1rem' : '0.1rem' }}>
                    <UserProfileSeller />
                </FlexDiv>
            </FlexDiv>
            {!isLargeLaptop && <MarketplaceText style={{ marginTop: '2rem', textAlign: 'center', fontSize: '2rem' }}>{TRANSACTIONS}</MarketplaceText>}
            <FlexDiv style={{ justifyContent: isSmallDesktop ? 'space-between' : 'center', flexDirection: isSmallDesktop ? 'row' : 'column', alignItems: !isSmallDesktop && 'center' }}>
                {isSmallDesktop ?
                    <TextField
                        placeholder="Search Transactions"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <>
                                    <SearchIcon style={{ color: '#9E9E9E', marginRight: '0.5rem' }} />
                                    <div style={{ width: '1.43rem', height: '1.3px', background: '#DEDEDE', transform: 'rotate(90deg)' }}></div>
                                </>
                            ),
                            sx: {
                                width: isDesktop ? '35rem' : '18rem',
                                marginTop: '1.38rem',
                                height: '2.8125rem',
                                borderRadius: '0.375rem',
                                background: '#F9F9F9',
                                '& .MuiOutlinedInput-root': {
                                    border: 'none',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                color: 'rgba(35, 35, 35, 0.9)',
                                fontFamily: 'Poppins',
                                fontSize: '0.9375rem',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                            },
                        }}
                        onChange={event => setSearchQuery(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                fetchTransactions();
                            }
                        }}
                    />
                    :
                    <TextField
                        sx={{
                            marginTop: '1.38rem',
                            marginRight: isLargeLaptop ? '3rem' : '1.5rem',
                            marginLeft: isLargeLaptop ? '0rem' : '1.5rem',
                            borderRadius: '0.375rem',
                            width: '95%',
                            background: '#F9F9F9',
                            "& fieldset": { border: 'none' },
                        }}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: '#9E9E9E', marginRight: '0.5rem' }} /><div style={{ width: '1.43rem', height: '1.3px', background: '#DEDEDE', transform: 'rotate(90deg)' }} /></InputAdornment>),
                        }}
                        inputProps={{
                            style: {
                                color: 'rgba(35, 35, 35, 0.9)',
                                fontFamily: 'Poppins',
                                fontSize: '0.9375rem',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                            },
                        }}
                        placeholder="Search Transactions"
                        onChange={event => setSearchQuery(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                // Update searchQuery when Enter key is pressed
                                fetchTransactions();
                            }
                        }}
                    />
                }
                <FlexDiv style={{ gap: isMobile ? '1rem' : '', flexDirection: isMobile ? 'row' : 'column', justifyContent: 'center' }}>
                    <AddNewProductBtn style={{ cursor: 'pointer', marginTop: '1.38rem', width: !isMobile && '161px', textDecoration: 'none !important', width: '11.3125rem' }} onClick={handleNavigateEarning}>
                        <InfoOutlinedIcon style={{
                            width: '1.0625rem',
                            height: '1.0625rem',
                            color: '#BB02C3',
                            marginTop: '0.8rem', marginLeft: '0.8rem', marginRight: '0.6rem',
                        }} />
                        <div style={{ marginTop: '0.8rem', marginLeft: !isMobile && '0.7rem', }}>Earning Guidelines</div>
                    </AddNewProductBtn>

                    <AddNewProductBtn
                        style={{
                            cursor: 'pointer',
                            marginTop: '1.38rem',
                            marginRight: isSmallDesktop ? '2rem' : '',
                            width: !isMobile && '161px',
                            textDecoration: 'none !important',
                            width: isSmallMobile ? '11.3125rem' : '8.3125rem',
                            display: 'flex', // Ensuring the container is flex
                            alignItems: 'center', // Centering vertically
                            justifyContent: 'center', // Centering horizontally
                        }}
                        onClick={handleExportButtonClick}
                    >
                        {exportingData ? (
                            <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} />
                        ) : (
                            <>
                                <img
                                    src={exportDataIcon}
                                    style={{
                                        width: '1.0625rem',
                                        height: '1.0625rem',
                                        marginTop: '-0.2rem',
                                        // marginLeft: '0.8rem',
                                        marginRight: '0.6rem',
                                    }}
                                    alt='Export data'
                                />
                                <div style={{ marginLeft: !isMobile && '0.7rem' }}>{EXPORT_DATA}</div>
                            </>
                        )}
                    </AddNewProductBtn>

                </FlexDiv>
            </FlexDiv >
            {isSmallDesktop &&
                <FlexDiv style={{ marginTop: '1.9rem', gap: '0.8rem', marginRight: '1.6rem', alignItems: 'right', justifyContent: 'right' }}>
                    <DateChecker onStartdateSelected={handleStartDateChange} onEndDateSelected={handleEndDateChange} flexDirec='column' />
                </FlexDiv>
            }
            {
                !isSmallDesktop &&
                <FlexDiv style={{ justifyContent: isDesktopTwo ? 'flex-end' : 'center' }}>
                    <FlexDiv style={{ marginTop: '1.2rem', marginRight: isSmallDesktop ? '2rem' : '', gap: '0.8rem' }}>
                        <DateChecker onStartdateSelected={handleStartDateChange} onEndDateSelected={handleEndDateChange} flexDirec='column' />
                    </FlexDiv>
                </FlexDiv>
            }
            {
                loading ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}>
                        {/* <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} /> */}
                        <SpinnerComponent />
                    </div>
                    :

                    <div style={{
                        marginTop: '1.8rem', marginRight: isLargeLaptop ? '1.8rem' : '1rem', marginBottom: '2rem', marginLeft: isLargeLaptop ? '0rem' : '1rem', height: 'inherit',
                    }}>
                        <ThemeProvider theme={theme}>
                            {rows.length > 0 ? (
                                <>
                                    <TableContainer>
                                        <CustomTable
                                            rows={rows}
                                            columns={columns.map((column, index) => ({
                                                ...column,
                                            }))}
                                            autoHeight
                                            disableSelectionOnClick
                                            hideFooterPagination
                                            hideFooterSelectedRowCount
                                            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'odd-row' : 'even-row')}
                                            sx={{
                                                border: 'none',
                                                boxShadow: 'none',
                                                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                                    marginTop: "0.9em",
                                                    marginBottom: "1em"
                                                }
                                            }}
                                        />
                                    </TableContainer>
                                    <div style={{ display: 'flex', justifyContent: isSmallMobile ? 'center' : 'space-between', alignItems: 'center', marginTop: '1.7rem' }}>
                                        {!isSmallMobile &&
                                            <Typography variant="body2" color="textSecondary">
                                                Showing {startIndex} to {rows.length < 12 ? rows.length : currentPage * pageSize} of {rows.length < 12 ? rows.length : currentPage * pageSize} entries
                                            </Typography>
                                        }
                                        <CustomPagination
                                            count={totalPages}
                                            sx={{ marginRight: isLargeLaptop ? '0rem' : '0.5rem' }}
                                            page={currentPage}
                                            onChange={(event, newPage) => setCurrentPage(newPage)}
                                        />
                                    </div>
                                </>
                            ) : (
                                <NoTransactions />
                            )}
                        </ThemeProvider>
                    </div>
            }
        </>
    );
};
export default Transactions;