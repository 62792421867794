import React, { useState, useEffect } from 'react';
import { AnalyticsNumber } from '../../../Pages/SellerDetails/constants';

const abbreviateNumber = (number) => {
    const abbreviations = [
        { value: 1e9, symbol: "B" },
        { value: 1e6, symbol: "M" },
        { value: 1e3, symbol: "K" },
    ];

    for (let i = 0; i < abbreviations.length; i++) {
        if (number >= abbreviations[i].value) {
            return (number / abbreviations[i].value).toFixed(1) + abbreviations[i].symbol;
        }
    }
    return number.toString();
};

const AnimatedNumber = ({ value }) => {
    const [displayValue, setDisplayValue] = useState(0);
    const endValue = Number(value);
    const isIncreasing = endValue >= 50;

    useEffect(() => {
        if (endValue !== 0) {
            let startValue = isIncreasing ? 0 : 50;
            let step = isIncreasing ? 70 : -1;

            const interval = setInterval(() => {
                setDisplayValue(startValue);
                startValue += step;

                if ((isIncreasing && startValue > endValue) || (!isIncreasing && startValue < endValue)) {
                    clearInterval(interval);
                    setDisplayValue(endValue);
                }
            }, 10);

            return () => clearInterval(interval);
        }
    }, [endValue, isIncreasing]);

    return <span style={AnalyticsNumber}>{abbreviateNumber(displayValue)}</span>;
};

export default AnimatedNumber;
