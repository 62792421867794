import NavBar from "../../components/Navbar";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Grid from "@mui/material/Grid"
import { FlexDiv, SellerSignUpHeader, SellerSignUpText, LoginBox, LoginBoxHeader, LoginBoxText, LoginBoxBtn, OrText, ContinueSignText, SignUpPageBlur } from "../../assets/styles/styles";
import SignUpImageOne from "../../assets/images/signup_image_one.svg";
import SignUpImageTwo from "../../assets/images/signup_image_two.svg";
import SignUpImageThree from "../../assets/images/signup_image_three.svg";
import Footer from "../../components/Footer";
import TextField from '@mui/material/TextField';
import { OR, CONTINUE_SIGNING, LIFE_IS_BETTER, LOREM_IPSUM_SIGNUP, FILL_YOUR_INFO, FILL_CREDIT_INFO, SUBSCRIBE_TO_OUR, FIRST_NAME, LAST_NAME, PHONE, STREET, COUNTRY, CITY, COMPANY_NAME, EMAIL, HOUSE_NUMBER, COMPANY_EMAIL, PASSWORD, CONFIRM_PASSWORD } from "../../constants/text";
import { useMediaQuery } from "react-responsive";
import FooterDivider from "../../components/FooterDivider";
import CircularProgress from '@mui/material/CircularProgress';
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import VerificationModal from "../../components/VerificationModal";
import { registerUser, sendVerificationEmail } from "../../Services/Authentication";
import { SIGNUP_HEADER_DATA } from "../../constants/data";
import PasswordField from "../../components/PasswordField";
import ReactGA4 from 'react-ga4';
import { Helmet } from "react-helmet";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import { Container } from "@mui/material";

const SellerSignUp = () => {
    const isDesktop = useMediaQuery({ minWidth: '1200px' });
    const isLaptop = useMediaQuery({ minWidth: '1350px' });
    const isMobile = useMediaQuery({ maxWidth: '600px' });
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dataSubmission, setDataSubmission] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isOpen, setIsOpen] = useState(false);
    const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleVerifyEmail = async () => {
        try {
            setIsVerifyingEmail(true);
            sendVerificationEmail(formData.email);
            closeModal();
            setTimeout(() => {
                navigate("/login");
            }, 1000);
        } catch (error) {
            console.error('Error sending verification email:', error);
        } finally {
            setIsVerifyingEmail(false);
        }
    };

    const handleCreateAccount = async () => {
        try {
            if (formData.password !== confirmPassword) {
                console.error('Password and confirm password do not match');
                return;
            }

            const userData = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                address: {
                    house: formData.house,
                    street: formData.street,
                    city: formData.city,
                    country: formData.country,
                },
                company: {
                    companyName: formData.companyName,
                    companyEmail: formData.companyEmail,
                },
                role: 'seller',
                email: formData.email,
                password: formData.password,
                designation: formData.designation,
            };

            const response = await registerUser(userData);
            let commonStructure = response.data.response.user
            if (commonStructure.message === 'User created successfully' || commonStructure.status === 200) {
                setDataSubmission(false);
                setSnackbarMsg("Registration Successful");
                setSnackbarSeverity("success");
                setOpen(true);
                // setTimeout(() => {
                //     localStorage.setItem('access-token', response.data.response.tokens.access.token)
                //     localStorage.setItem('refresh-token', response.data.response.tokens.refresh.token)
                //     localStorage.setItem('access-token-expiry', response.data.response.tokens.access.expires);
                //     localStorage.setItem('refresh-token-expiry', response.data.response.tokens.refresh.expires);
                //     localStorage.setItem('Cornucopia-user', JSON.stringify(response.data.response.user))
                //     navigate('/');
                // }, 1000);
                ReactGA4.event({
                    category: 'Conversion',
                    action: 'Signup',
                    label: 'User Signed Up',
                });
                openModal();
            }
        } catch (error) {
            console.error('Login error:', error);
            setDataSubmission(false);
            if (error?.response?.data?.message) {
                setSnackbarMsg(error.response.data.message);
                setSnackbarSeverity("error")
            } else if (error?.message) {
                setSnackbarMsg(error.message);
                setSnackbarSeverity("error")
            } else {
                setSnackbarMsg('Error');
                setSnackbarSeverity("error")
            }
            setOpen(true);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        house: '',
        street: '',
        country: '',
        city: '',
        companyName: '',
        companyEmail: '',
        password: '',
        designation: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'password') {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const [errors, setErrors] = useState({}); // Initialize an errors state

    const validateForm = () => {
        const newErrors = {};

        // Validate firstName and lastName (should not be numeric)
        if (!/^[a-zA-Z]+$/.test(formData.firstName)) {
            newErrors.firstName = 'Name should only contain alphabets';
        }
        if (!/^[a-zA-Z]+$/.test(formData.lastName)) {
            newErrors.lastName = 'Name should only contain alphabets';
        }

        // Validate email format
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Invalid email address';
        }
        if (!emailPattern.test(formData.companyEmail)) {
            newErrors.companyEmail = 'Invalid email address';
        }
        // Validate required fields (house, street, country, city, companyName, creditCard, holderName, cvv)
        const requiredFields = ['house', 'street', 'country', 'city', 'companyName', 'phone'];
        requiredFields.forEach(field => {
            if (!formData[field].trim()) {
                newErrors[field] = 'This field is required';
            }
        });

        // Validate password (at least 8 characters, 1 number, 1 capital, 1 special character)
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        if (!passwordPattern.test(formData.password)) {
            newErrors.password = 'Password must be at least 8 characters long and contain atleast 1 letter,number and special character';
        }

        // Validate confirmPassword
        if (formData.password !== confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        if (!formData.designation.trim()) {
            newErrors.designation = 'This field is required';
        }
        if (formData.designation.trim().length > 20) {
            newErrors.designation = 'Occupation should not be longer than 20 characters';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSignUp = () => {
        if (validateForm()) {
            setDataSubmission(true);
            handleCreateAccount();
        }
    };
    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <Helmet>
                    <title>Cornucopia AI | Signup</title>
                </Helmet>
                <Snackbar
                    open={open}
                    autoHideDuration={2000} // Adjust as needed
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        severity={snackbarSeverity}
                    >
                        {snackbarMsg}
                    </MuiAlert>
                </Snackbar>
                {isDesktop && <SignUpPageBlur />}
                <NavBar header_data={SIGNUP_HEADER_DATA} header_button={{ label: 'Login', navigation_url: '/login' }} mobile_nav_height={'210px'} />
                <Grid container spacing={0} style={{ marginTop: '3rem', marginBottom: '-370px' }}>
                    {isDesktop &&
                        <Grid item lg={6} sm={0} display="flex" flexDirection="column" alignItems="flex-start" paddingLeft={isLaptop ? '50px' : '0px'}>
                            <SellerSignUpHeader style={{ width: '17rem', marginTop: '4rem', marginLeft: "90px" }}>{LIFE_IS_BETTER}<span style={{ color: '#700189' }}>AI</span></SellerSignUpHeader>
                            <SellerSignUpText style={{ marginLeft: "90px", maxWidth: '650px' }}>{LOREM_IPSUM_SIGNUP}</SellerSignUpText>
                            <FlexDiv style={{ marginTop: '5rem', marginLeft: "90px" }}>
                                <img alt="" src={SignUpImageOne} />
                                <SellerSignUpText style={{ width: '15rem', marginTop: '4rem', marginLeft: '3rem' }}>{FILL_YOUR_INFO}</SellerSignUpText>
                            </FlexDiv>
                            <FlexDiv style={{ marginTop: '8.75rem' }}>
                                <SellerSignUpText style={{ width: '12.5rem', marginTop: '7rem', marginLeft: "90px", marginRight: '0.8rem' }}>{FILL_CREDIT_INFO}</SellerSignUpText>
                                <img alt="" src={SignUpImageTwo} />
                            </FlexDiv>
                            <FlexDiv style={{ marginTop: '15rem', marginLeft: "60px", marginBottom: '5rem' }}>
                                <img alt="" src={SignUpImageThree} />
                                <SellerSignUpText style={{ maxWidth: '19rem', marginTop: '2rem', marginLeft: '-1rem' }}>{SUBSCRIBE_TO_OUR}</SellerSignUpText>
                            </FlexDiv>
                        </Grid>
                    }
                    <Grid item lg={6} xs={12} display="flex" alignItems="center" justifyContent="center">
                        <LoginBox style={{ height: '100%', width: isMobile ? '100%' : '', marginRight: isMobile ? '1rem' : '', marginLeft: isMobile ? '1rem' : '', paddingBottom: "150px", }}>
                            <LoginBoxHeader style={{ marginTop: '2.65rem', textAlign: 'center' }}>Join <span style={{ fontFamily: "calibri", fontWeight: "bold", fontSize: "30px" }}>O</span>ur Venture</LoginBoxHeader>
                            <FlexDiv style={{ marginTop: '2.75rem', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', marginLeft: isMobile ? '1.3rem' : '', marginRight: isMobile ? '1.3rem' : '' }}>
                                <div>
                                    <LoginBoxText>{FIRST_NAME}</LoginBoxText>
                                    <TextField
                                        type="text"
                                        name='firstName'
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        error={!!errors.firstName}
                                        helperText={errors.firstName}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: isMobile ? '2rem' : '' }}>
                                    <LoginBoxText>{LAST_NAME}</LoginBoxText>
                                    <TextField
                                        type="text"
                                        name='lastName'
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                            </FlexDiv>
                            <FlexDiv style={{ marginTop: '2rem', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', marginLeft: isMobile ? '1.3rem' : '', marginRight: isMobile ? '1.3rem' : '' }}>
                                <div>
                                    <LoginBoxText>{EMAIL}</LoginBoxText>
                                    <TextField
                                        type="Email"
                                        name='email'
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: isMobile ? '2rem' : '' }}>
                                    <LoginBoxText>{PHONE}</LoginBoxText>
                                    <TextField
                                        type="number"
                                        name='phone'
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& input[type=number]': {
                                                '-moz-appearance': 'textfield'
                                            },
                                            '& input[type=number]::-webkit-outer-spin-button': {
                                                '-webkit-appearance': 'none',
                                                margin: 0
                                            },
                                            '& input[type=number]::-webkit-inner-spin-button': {
                                                '-webkit-appearance': 'none',
                                                margin: 0
                                            }
                                        }}
                                    />
                                </div>
                            </FlexDiv>
                            <FlexDiv style={{ marginTop: '2rem', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', marginLeft: isMobile ? '1.3rem' : '', marginRight: isMobile ? '1.3rem' : '' }}>
                                <div>
                                    <LoginBoxText>{HOUSE_NUMBER}</LoginBoxText>
                                    <TextField
                                        type="text"
                                        name='house'
                                        value={formData.house}
                                        onChange={handleInputChange}
                                        error={!!errors.house}
                                        helperText={errors.house}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: isMobile ? '2rem' : '' }}>
                                    <LoginBoxText>{STREET}</LoginBoxText>
                                    <TextField
                                        type="text"
                                        name='street'
                                        value={formData.street}
                                        onChange={handleInputChange}
                                        error={!!errors.street}
                                        helperText={errors.street}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                            </FlexDiv>
                            <FlexDiv style={{ marginTop: '2rem', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', marginLeft: isMobile ? '1.3rem' : '', marginRight: isMobile ? '1.3rem' : '' }}>
                                <div>
                                    <LoginBoxText>{COUNTRY}</LoginBoxText>
                                    <TextField
                                        type="text"
                                        name='country'
                                        value={formData.country}
                                        onChange={handleInputChange}
                                        error={!!errors.country}
                                        helperText={errors.country}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: isMobile ? '2rem' : '' }}>
                                    <LoginBoxText>{CITY}</LoginBoxText>
                                    <TextField
                                        type="text"
                                        name='city'
                                        value={formData.city}
                                        onChange={handleInputChange}
                                        error={!!errors.city}
                                        helperText={errors.city}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                            </FlexDiv>
                            <FlexDiv style={{ marginTop: '2rem', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', marginLeft: isMobile ? '1.3rem' : '', marginRight: isMobile ? '1.3rem' : '' }}>
                                <div>
                                    <LoginBoxText>{COMPANY_NAME}</LoginBoxText>
                                    <TextField
                                        type="text"
                                        name='companyName'
                                        value={formData.companyName}
                                        onChange={handleInputChange}
                                        error={!!errors.companyName}
                                        helperText={errors.companyName}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: isMobile ? '2rem' : '' }}>
                                    <LoginBoxText>{COMPANY_EMAIL}</LoginBoxText>
                                    <TextField
                                        type="Email"
                                        name='companyEmail'
                                        value={formData.companyEmail}
                                        onChange={handleInputChange}
                                        error={!!errors.companyEmail}
                                        helperText={errors.companyEmail}
                                        sx={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                            </FlexDiv>
                            {/* <FlexDiv style={{ flexDirection: 'column', marginTop: '2rem', marginLeft: '1.3rem', marginRight: '1.3rem' }}>
                            <LoginBoxText>{CREDIT_CARD_NUMBER}</LoginBoxText>
                            <TextField
                                type="text"
                                name='creditCard'
                                value={formData.creditCard}
                                onChange={handleInputChange}
                                error={!!errors.creditCard}
                                helperText={errors.creditCard}
                                sx={{
                                    height: "51px",
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                }}
                            />
                        </FlexDiv> */}
                            {/* <FlexDiv style={{ flexDirection: 'column', marginTop: '2rem', marginLeft: '1.3rem', marginRight: '1.3rem' }}>
                            <LoginBoxText>{HOLDER_NAME}</LoginBoxText>
                            <TextField
                                type="text"
                                name='holderName'
                                value={formData.holderName}
                                onChange={handleInputChange}
                                error={!!errors.holderName}
                                helperText={errors.holderName}
                                sx={{
                                    height: "51px",
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                }}
                            />
                        </FlexDiv> */}
                            {/* <FlexDiv style={{ marginTop: '2rem', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', marginLeft: isMobile ? '1.3rem' : '', marginRight: isMobile ? '1.3rem' : '' }}>
                            <div>
                                <LoginBoxText>{EXPIRY_DATE}</LoginBoxText>
                                <DatePicker
                                    sx={{
                                        width: isMobile ? '100%' : '15.5rem',
                                        height: "51px",
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: isMobile ? '2rem' : '' }}>
                                <LoginBoxText>{CVV}</LoginBoxText>
                                <TextField
                                    type="text"
                                    name='cvv'
                                    value={formData.cvv}
                                    onChange={handleInputChange}
                                    error={!!errors.cvv}
                                    helperText={errors.cvv}
                                    sx={{
                                        width: isMobile ? '100%' : '15.5rem',
                                        height: "51px",
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                        '& .MuiOutlinedInput.root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                    }}
                                />
                            </div>
                        </FlexDiv> */}
                            <FlexDiv style={{ marginTop: '2rem', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', marginLeft: isMobile ? '1.3rem' : '', marginRight: isMobile ? '1.3rem' : '' }}>
                                <div>
                                    <LoginBoxText>{PASSWORD}</LoginBoxText>
                                    <PasswordField
                                        name='password'
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        empty={!!errors.password}
                                        helperText={errors.password}
                                        sxProps={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: isMobile ? ((errors.password && isMobile) ? "4rem" : "2rem") : '' }}>
                                    <LoginBoxText>{CONFIRM_PASSWORD}</LoginBoxText>
                                    <PasswordField
                                        name='confirmPassword'
                                        value={confirmPassword}
                                        onChange={handleInputChange}
                                        empty={!!errors.confirmPassword}
                                        helperText={errors.confirmPassword}
                                        sxProps={{
                                            width: isMobile ? '100%' : '15.5rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                            </FlexDiv>
                            <FlexDiv style={{ marginTop: '2rem', flexDirection: isMobile ? 'column' : 'row', marginLeft: isMobile ? '1.3rem' : '1.55rem', marginRight: isMobile ? '1.3rem' : '' }}>
                                <div style={{ marginTop: errors.password && !isMobile ? '2.5rem' : '' }}>
                                    <LoginBoxText>Occupation</LoginBoxText>
                                    <TextField
                                        type="text"
                                        name='designation'
                                        value={formData.designation}
                                        onChange={handleInputChange}
                                        error={!!errors.designation}
                                        helperText={errors.designation}
                                        sx={{
                                            width: isMobile ? '100%' : '33.7rem',
                                            height: "51px",
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' },
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9D40B1' }
                                        }}
                                    />
                                </div>
                            </FlexDiv>
                            <FlexDiv style={{ justifyContent: 'center' }}>
                                <LoginBoxBtn style={{ width: isMobile ? '95%' : '' }} onClick={handleSignUp}> {!dataSubmission ? "Create an account" : <CircularProgress color="secondary" style={{ width: '27px', height: '25px' }} />}</LoginBoxBtn>
                            </FlexDiv>
                            <FlexDiv style={{ margin: '2.5rem 2rem 1.5rem 2rem' }}>
                                <div style={{ height: '0.0625rem', background: '#BDBDBD', width: '100%', marginTop: '0.5rem' }} />
                                <OrText style={{ margin: '0rem 0.2rem' }}>{OR}</OrText>
                                <div style={{ height: '0.0625rem', background: '#BDBDBD', width: '100%', marginTop: '0.5rem' }} />
                            </FlexDiv>
                            <FlexDiv style={{ justifyContent: 'center' }}>
                                <ContinueSignText>{CONTINUE_SIGNING}</ContinueSignText>
                            </FlexDiv>
                            <FlexDiv style={{ margin: "2rem 0rem 3rem 0rem", justifyContent: "center" }}>
                                <GoogleLoginButton />
                            </FlexDiv>

                        </LoginBox>
                    </Grid>
                </Grid>
                <VerificationModal isOpen={isOpen} onClose={closeModal} onVerifyEmail={handleVerifyEmail} isVerifyingEmail={isVerifyingEmail} signup={true} email={formData.email} />

            </Container>
            <FooterDivider />
            <Footer />
        </>
    )
};

export default SellerSignUp;
