import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import RevenueLine from '../../assets/images/revenue_line.svg';
import BuyerLine from '../../assets/images/buyer_line.svg';
import DollarSign from '../../assets/images/dollar_sign.svg';
import DollarCircle from '../../assets/images/dollar_circle.png';
import DollarCircleBuyer from '../../assets/images/dollar_sign_buyer.svg';
import UpArrow from '../../assets/images/up_arrow.svg';
import JanuarySales from '../../assets/images/january_sales.svg';
import FebruarySales from '../../assets/images/february_sales.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BUYERS, BUYERS_NUM, FEBRUARY, JANUARY, JAN_SALES, REVENUE, REVENUE_INCREASE, SALES_NUM, SINCE_LAST_MONTH } from '../../constants/text';



const cardStyles = {

    borderRadius: '0.5625rem',
    background: '#FFF',
};
const revenueTextStyles = {
    color: '#666',
    fontFamily: 'Poppins',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginLeft: '0.87rem',
};
const revenueAmountStyles = {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginLeft: '0.13rem',
    marginTop: '0.13rem',
};
const dollarCircle = {
    width: '2.625rem',
    height: '2.625rem',
};
const upArrowStyle = {
    width: '0.75rem',
    height: '0.8125rem',
};
const revenueIncreaseStyle = {
    color: '#00A44B',
    fontFamily: 'Poppins',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};
const sinceLastMonth = {
    color: '#808080',
    fontFamily: 'Poppins',
    fontSize: '0.8125rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};
const monthStyle = {
    color: '#676767',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};
const RevenueStats = ({buyers = 0, totalRevenue = 0}) => {
    const isSmallScreen = useMediaQuery('(max-width:440px)');
    const isSmallerScreen = useMediaQuery('(max-width:380px)');


    return (
        <Card sx={{
            ...cardStyles, width: isSmallScreen ? '36rem' : '26.625rem', '& .MuiCardContent-root': { padding: '1.56rem 0.69rem 0.5rem 1.37rem', marginLeft: isSmallScreen ? (isSmallerScreen ? '3rem' : '6rem') : '0' }
        }}>
            <CardContent>
                <div style={{ display: 'flex' }} >
                    <img
                        src={RevenueLine}
                        alt=""
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={revenueTextStyles}>{REVENUE}</div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginLeft: '0.98rem' }}>
                                <img
                                    src={DollarSign}
                                    alt=""
                                />
                            </div>
                            <div style={revenueAmountStyles}>{totalRevenue}</div>
                        </div>
                    </div>
                    <img
                        style={{ ...dollarCircle, marginLeft: '2.8rem', marginTop: '0.2rem' }}
                        src={DollarCircle}
                        alt=""
                    />
                    {!isSmallScreen && (
                        <div style={{ display: 'flex' }}>
                            <img
                                style={{ marginLeft: '1.56rem' }}
                                src={BuyerLine}
                                alt=""
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={revenueTextStyles}>{BUYERS}</div>
                                <div style={{ ...revenueAmountStyles, marginLeft: '1.06rem' }}>{buyers}</div>
                            </div>
                            <img
                                style={{ ...dollarCircle, marginLeft: '2.9rem', marginTop: '0.2rem' }}
                                src={DollarCircleBuyer}
                                alt=""
                            />
                        </div>
                    )}

                </div>
                <div style={{ display: 'flex', marginTop: '1.06rem', alignItems: 'center' }} >
                    <img
                        style={upArrowStyle}
                        src={UpArrow}
                        alt=""
                    />
                    <div style={{ ...revenueIncreaseStyle, marginLeft: '0.38rem' }}>
                        {REVENUE_INCREASE}
                    </div>
                    <div style={{ ...sinceLastMonth, marginLeft: '0.25rem' }}>{SINCE_LAST_MONTH}</div>
                    {!isSmallScreen && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                style={{ upArrowStyle, marginLeft: '1.7rem' }}
                                src={UpArrow}
                                alt=""
                            />
                            <div style={{ ...revenueIncreaseStyle, marginLeft: '0.38rem' }}>
                                {REVENUE_INCREASE}
                            </div>
                            <div style={{ ...sinceLastMonth, marginLeft: '0.25rem' }}>{SINCE_LAST_MONTH}</div>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', marginTop: '1.06rem', alignItems: 'center' }} >
                    <div style={{ ...monthStyle, marginLeft: '0.12rem' }}>{JANUARY}</div>
                    <div style={{ ...monthStyle, fontSize: '0.5rem', color: '#838383', marginLeft: '6.89rem' }}>{JAN_SALES}</div>
                    {!isSmallScreen && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ ...monthStyle, marginLeft: '1.6rem' }}>{JANUARY}</div>
                            <div style={{ ...monthStyle, fontSize: '0.5rem', color: '#838383', marginLeft: '6.89rem' }}>{JAN_SALES}</div>
                        </div>
                    )}
                </div>
                <div style={{ marginTop: '-0.5rem' }}>
                    <img style={{ width: '10.71rem' }} src={JanuarySales} alt="" />
                    {!isSmallScreen && (
                        <img style={{ width: '10.71rem', marginLeft: '1.6rem' }} src={JanuarySales} alt="" />
                    )}
                </div>
                <div style={{ display: 'flex', marginTop: '0.6rem', alignItems: 'center' }} >
                    <div style={{ ...monthStyle, marginLeft: '0.12rem' }}>{FEBRUARY}</div>
                    <div style={{ ...monthStyle, fontSize: '0.5rem', color: '#838383', marginLeft: '6.89rem' }}>{JAN_SALES}</div>
                    {!isSmallScreen && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ ...monthStyle, marginLeft: '1.6rem' }}>February</div>
                            <div style={{ ...monthStyle, fontSize: '0.5rem', color: '#838383', marginLeft: '6.89rem' }}>{JAN_SALES}</div>
                        </div>
                    )}
                </div>
                <div style={{ marginTop: '-0.5rem' }}>
                    <img style={{ width: '10.71rem' }} src={FebruarySales} alt="" />
                    {!isSmallScreen && (
                        <img style={{ width: '10.71rem', marginLeft: '1.6rem' }} src={FebruarySales} alt="" />
                    )}
                </div>
                {isSmallScreen && (
                    <div>
                        <div style={{ display: 'flex', marginTop: '1rem' }}>
                            <img
                                style={{}}
                                src={BuyerLine}
                                alt=""
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={revenueTextStyles}>{BUYERS}</div>
                                <div style={{ ...revenueAmountStyles, marginLeft: '1.06rem' }}>{buyers}</div>
                            </div>
                            <img
                                style={{ ...dollarCircle, marginLeft: '2.9rem', marginTop: '0.2rem' }}
                                src={DollarCircleBuyer}
                                alt=""
                            />
                        </div>
                        <div style={{ display: 'flex', marginTop: '1.06rem', alignItems: 'center' }} >
                            <img
                                style={upArrowStyle}
                                src={UpArrow}
                                alt=""
                            />
                            <div style={{ ...revenueIncreaseStyle, marginLeft: '0.38rem' }}>
                                {REVENUE_INCREASE}
                            </div>
                            <div style={{ ...sinceLastMonth, marginLeft: '0.25rem' }}>{SINCE_LAST_MONTH}</div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '1.06rem', alignItems: 'center' }} >
                            <div style={{ ...monthStyle, marginLeft: '0.12rem' }}>{JANUARY}</div>
                            <div style={{ ...monthStyle, fontSize: '0.5rem', color: '#838383', marginLeft: '6.89rem' }}>{JAN_SALES}</div>
                        </div>
                        <div style={{ marginTop: '-0.5rem' }}>
                            <img style={{ width: '10.71rem' }} src={JanuarySales} alt="" />
                        </div>
                        <div style={{ display: 'flex', marginTop: '0.6rem', alignItems: 'center' }} >
                            <div style={{ ...monthStyle, marginLeft: '0.12rem' }}>{FEBRUARY}</div>
                            <div style={{ ...monthStyle, fontSize: '0.5rem', color: '#838383', marginLeft: '6.89rem' }}>{JAN_SALES}</div>
                        </div>
                        <div style={{ marginTop: '-0.5rem' }}>
                            <img style={{ width: '10.71rem' }} src={FebruarySales} alt="" />
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default RevenueStats;