import React, { useState } from 'react';
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { authenticateWithGoogle } from '../Services/Authentication';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import styled from 'styled-components';
import GoogleIcon from '../assets/images/google.svg'

const GoogleBtn = styled.button`
    border-radius: 16px;
    border: 2px solid #EBD6EF;
    color: #9803fa;
    font-family: Poppins;
    font-size: calc(var(--font-medium) * 1);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 21.625rem;
    height: 2.8125rem;
    background:none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 650px) {
        width: 90%;
    }

`;
const GoogleLoginButton = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 400 });
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };
    const loginWithGoogle = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const res = await axios.get(
                `https://www.googleapis.com/oauth2/v1/userinfo`,
                {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                        Accept: "application/json",
                    },
                }
            )
            try {
                const googleToken = tokenResponse.access_token;
                const responseData = await authenticateWithGoogle(googleToken);
                setSnackbarMsg("Login Successful");
                setSnackbarSeverity("success");
                setOpen(true);
                setTimeout(() => {
                    localStorage.setItem('access-token', responseData.response.tokens.access.token);
                    localStorage.setItem('refresh-token', responseData.response.tokens.refresh.token);
                    localStorage.setItem('access-token-expiry', responseData.response.tokens.access.expires);
                    localStorage.setItem('refresh-token-expiry', responseData.response.tokens.refresh.expires);
                    localStorage.setItem('Cornucopia-user', JSON.stringify(responseData.response.user));
                    if (responseData.response.user.response.role === 'user')
                        navigate('/selectrole')
                    else if (responseData.response.user.response.role === 'buyer')
                        navigate('/')
                    else if (responseData.response.user.response.role === 'seller')
                        navigate('/seller')
                }, 500);
            } catch (error) {
                console.error('Error during Google Sign-In:', error);
                setSnackbarMsg(error?.response?.data?.message ? error?.response?.data?.message : "Error Occured");
                setSnackbarSeverity("error");
                setOpen(true);
            }
        },
    });

    const handleGoogleSignIn = async (googleUser) => {
        try {
            const googleToken = googleUser.credential;
            const responseData = await authenticateWithGoogle(googleToken);
            setTimeout(() => {
                localStorage.setItem('access-token', responseData.response.tokens.access.token);
                localStorage.setItem('refresh-token', responseData.response.tokens.refresh.token);
                localStorage.setItem('access-token-expiry', responseData.response.tokens.access.expires);
                localStorage.setItem('refresh-token-expiry', responseData.response.tokens.refresh.expires);
                localStorage.setItem('Cornucopia-user', JSON.stringify(responseData.response.user));
                if (responseData.response.user.response.role === 'user')
                    navigate('/selectrole')
                else if (responseData.response.user.response.role === 'buyer')
                    navigate('/')
                else if (responseData.response.user.response.role === 'seller')
                    navigate('/seller')
            }, 500);
        } catch (error) {
            console.error('Error during Google Sign-In:', error);
            setSnackbarMsg(error?.response?.data?.message ? error?.response?.data?.message : "Error Occured");
            setSnackbarSeverity("error");
            setOpen(true);
        }
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    onSuccess={handleGoogleSignIn}
                    onFailure={(error) => {
                        console.error('Google sign-in failed:', error);
                    }}
                    cookiePolicy={'single_host_origin'}
                    shape='pill'
                    type='standard'
                    size='medium'
                    width={isMobile ? '300' : '350'}
                    logo_alignment='left'
                // theme='filled_black'

                />
            </GoogleOAuthProvider> */}
            <GoogleBtn onClick={loginWithGoogle}> <img src={GoogleIcon} style={{ width: '20px', height: '24px', marginRight: '1rem' }} /> Sign in with google</GoogleBtn>
        </>
    );
};

export default GoogleLoginButton;
