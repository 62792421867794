import React, { useState, useEffect } from 'react';
import Modal from "react-modal";
import { AGREE_TERMS, CANCEL, TERMS_CONDITION } from '../../constants/text';
import { useMediaQuery } from "react-responsive";
import TermsConditions from "../../assets/images/terms_and_conditions.svg"
import { FlexDiv, TermsAndConditionText, TermsAndConditionDiv } from '../../assets/styles/styles';

const TermsConditionsModal = ({ isOpen, onClose, onAccept }) => {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const isSmallMobile = useMediaQuery({ maxWidth: 555 });
    useEffect(() => {
        // Set the initial state when the component mounts
        setTermsAccepted(false);
    }, []);
    const handleCloseModal = () => {
        setTermsAccepted(false);
        onClose();
    };
    return (
        < Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Terms and conditions Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '100'
                },
                content: {
                    width: isSmallMobile ? '95%' : '500px', // Adjust the width as per your preference
                    height: 'max-content',
                    margin: 'auto',
                    borderRadius: '0.75rem',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: '1.5rem',
                    inset: '0px'
                },
            }
            }

        >
            <img src={TermsConditions} alt="" style={{
                width: '3.5rem',
                height: '3.5rem',
                borderRadius: '0.5rem',
                border: '8px solid var(--primary-50, #F9F5FF)',
                background: 'var(--primary-100, #F4EBFF)',
                marginBottom: '1.25rem',
            }} />
            <h2 style={{
                color: '#46137C',
                fontFamily: 'Poppins',
                fontSize: isSmallMobile ? '1.3rem' : '1.5rem',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                display: 'flex',

            }}>
                {TERMS_CONDITION}
            </h2>
            <FlexDiv style={{
                justifyContent: 'center',
                alignItems: 'center'
            }}
            >
                <TermsAndConditionDiv>
                    <TermsAndConditionText>
                        We are Cornucopia AI LLC (“Company”, “we”, “us”, “our”), a company registered in Virginia, United States at 400 Warren St. , Blacksburg, VA 24060.
                    </TermsAndConditionText>
                    <TermsAndConditionText>
                        We operate the website <a href='http://cornucopia-ai.s3-website-us-east-1.amazonaws.com' target='_blank'>http://cornucopia-ai.s3-website-us-east-1.amazonaws.com</a> (the “Site”), the mobile application Cornucopia AI (the “App”), as well as any other related products and services that refer or link to these legal terms (the “Legal Terms”) (collectively, the “Services”).
                    </TermsAndConditionText>
                    <TermsAndConditionText>
                        You can contact us by phone at <a href="tel:+17043020475">(+1)7043020475</a>, email at <a href='mailto:ata@vt.edu' target='_blank'>ata@vt.edu</a>, or by mail to 400 Warren St. , Blacksburg, VA 24060, United States.
                    </TermsAndConditionText>
                </TermsAndConditionDiv>
            </FlexDiv>
            <div style={{ display: 'flex', marginTop: '1.2rem', alignItems: 'center' }}>
                <input type="checkbox" id="acceptTerms" style={{ marginRight: '0.5rem' }} onChange={(e) => setTermsAccepted(e.target.checked)} />
                <label htmlFor="acceptTerms">
                    <span
                        style={{
                            color: 'var(--gray-500, #667085)',
                            fontFamily: 'Roboto',
                            fontSize: '0.875rem',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 'normal',
                        }}
                    >
                        {AGREE_TERMS}
                        <span
                            style={{
                                textDecoration: 'underline',
                                color: '#3F0065'
                            }}
                        >
                            {TERMS_CONDITION}
                        </span>
                    </span>
                </label>
            </div>
            <div style={{ display: 'flex', marginTop: '2rem' }}>
                <button
                    onClick={handleCloseModal}
                    style={{
                        width: '50%',
                        borderRadius: '0.4375rem',
                        background: '#EDEDED',
                        color: '#8F8F8F',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        border: 'none',
                        display: 'block',
                        padding: '0.625rem 1.125rem',
                        marginRight: '0.75rem'
                    }}
                >
                    {CANCEL}
                </button>
                <button
                    onClick={onAccept}
                    disabled={!termsAccepted}
                    style={{
                        background: termsAccepted ? '#3F0065' : '#997AAC', // Change background color based on the disabled state
                        color: termsAccepted ? '#FFF' : '#CCBCD6',
                        width: '50%',
                        borderRadius: '0.4375rem',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        border: 'none',
                        display: 'block',
                        padding: '0.625rem 1.125rem',
                        cursor: termsAccepted ? 'pointer' : 'not-allowed',
                        transition: 'opacity 0.3s',
                        opacity: 1,
                    }}
                    onMouseOver={(e) => e.target.style.opacity = 0.8}
                    onMouseOut={(e) => e.target.style.opacity = 1}
                >
                    Next
                </button>
            </div>
        </Modal >
    );
}
export default TermsConditionsModal;
