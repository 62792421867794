import React from 'react';
import BagIcon from '../../assets/images/seller_icon.png';
import BlankState from '../Utils/BlankState';

const NoSeller = ({ width = '100%' }) => {
    return (
        <BlankState text={'No Seller Found!'} icon={BagIcon} height={'43.875rem'} marginLeft={'3rem'} width={width} />
    );
};

export default NoSeller;
