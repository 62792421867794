import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const BlankState = ({text, icon, height, marginLeft, icon_height='1.25rem', icon_width='1.25rem', width='100%'}) => {
    return (
        <div
            style={{
                width: '100%',
                height: height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Card
                sx={{
                    borderRadius: '0.375rem',
                    background: '#FFF',
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.22)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    width: width,
                    height: '100%',
                }}
            >
                <CardContent>
                    <div
                        style={{
                            borderRadius: '1.75rem',
                            border: '8px solid var(--primary-50, #F9F5FF)',
                            background: 'var(--primary-100, #F4EBFF)',
                            width: '3rem',
                            height: '3rem',
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            marginLeft: marginLeft                           
                        }}
                    >
                        <img
                            src={icon}
                            alt="Icon"
                            style={{
                                width: icon_width, 
                                height: icon_height,                                
                                opacity: 0.3,
                            }}
                        />
                    </div>
                    <Typography
                        sx={{
                            alignSelf: 'stretch',
                            color: 'var(--gray-900, #101828)',
                            fontFamily: 'Poppins',
                            fontSize: '1rem',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '1.5rem',
                            mt: 1,
                            width: '100%', // Set text width to 100%
                        }}
                    >
                        {text}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default BlankState;
