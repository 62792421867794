import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import OnlineIcon from '../../assets/images/online_icon.svg';
import { useMediaQuery } from "react-responsive";
import { useNotification } from '../../Context/NotificationContext';
import { Avatar } from '@mui/material';

const formatTimeInPST = (timestamp) => {
    const date = new Date(timestamp);
    date.setHours(date.getHours());
    const pstTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'Asia/Karachi',
    });
    return pstTime;
};

const cardStyle = {
    width: '15rem',
    maxWidth: '100%',
    borderRadius: '0.3125rem',
    background: '#FFF',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.17)',
};

const profilePictureContainerStyle = {
    position: 'relative',
};

const onlineIconStyle = {
    position: 'absolute',
    top: '0',
    marginLeft: '-0.85rem'
};
const nameStyle = {
    color: '#3F0065',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '0.31rem',
    marginLeft: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

const messageStyle = {
    color: '#8E8E8E',
    fontFamily: 'Poppins',
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginLeft: '1rem',
    textAlign: 'left'
};
const timeStyle = {
    color: '#6A6A6A',
    fontFamily: 'Poppins',
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
};


const InboxChat = ({ firstName = 'Jennifer', lastName = 'Fritz', content, notification, chatID, displayPicture, color, time }) => {
    const isTab = useMediaQuery({ minWidth: 750 });
    const isSmallMobile = useMediaQuery({ maxWidth: 390 });
   
    const {
        chatId
    } = useNotification();
   
    const cardStyleWithNotification = {
        ...cardStyle,
        border: (chatID === chatId) ? '1.5px solid rgba(66, 0, 81, 0.62)' : 'none',
        width: !isSmallMobile && !isTab ? '22rem' : '17rem'
    };

    const truncatedContent = notification ? <b>New Message</b> : (content.length > 40 ? `${content.substring(0, 40)}...` : content);
   
    const combinedName = `${firstName} ${lastName}`;
   
    const displayName = combinedName.length > 12 ? `${combinedName.substring(0, 11)}...` : combinedName;
    
    return (
        <Card style={cardStyleWithNotification}>
            <CardContent style={{ ...cardStyle, width: !isSmallMobile && !isTab ? '22rem' : '17rem' }}>
                <div style={{ display: 'flex' }}>
                    <div style={profilePictureContainerStyle}>
                        {displayPicture ? (
                            <img src={displayPicture} alt="Profile" style={{ width: '2.875rem', height: '2.875rem', borderRadius: '2.8rem' }} />
                        ) : (
                            <Avatar sx={{ bgcolor: color, fontSize: '0.8rem', width: '2.8rem', height: '2.7rem', borderRadius: '2.8rem' }}>
                                {`${firstName[0]}${lastName[0]}`}
                            </Avatar>
                        )}
                        <img src={OnlineIcon} alt="Online" style={{ ...onlineIconStyle, marginLeft: displayPicture ? '-0.85rem' : '0.5rem' }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ ...nameStyle, textAlign: 'left' }}>{displayName}</div>
                        <div style={messageStyle}>{truncatedContent}</div>
                    </div>
                    <div style={{ ...timeStyle, marginLeft: 'auto' }}>{time ? formatTimeInPST(time) : '3:08pm'}</div>
                </div>
            </CardContent>
        </Card>
    );
};

export default InboxChat;
