import React from 'react';
import { Card, CardContent, Paper } from '@mui/material';
import Chart from 'react-apexcharts';
import YellowLine from '../../assets/images/yellow_line.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TOTAL_SALES } from '../../constants/text';


const chartData = {
    series: [452, 326, 652], // Average, Below Average, Above Average
    options: {
        chart: {
            type: 'donut',
        },
        labels: ['Average', 'Below Average', 'Above Average'],
        colors: ['#D2BD70', '#870000', '#8EC37B'],
        legend: {
            position: 'right',
            offsetY: 0,
            itemMargin: {
                vertical: 5,
            },
            formatter: function (seriesName, opts) {
                return `${seriesName}`;
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return `${Math.round(val)}% `;
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            label: 'Reviews',
                            fontSize: '0.6875rem',
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                        }
                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 500,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetY: 0,
                    },
                },
            },
        ],
    },
};


const totalReviewsStyle = {
    color: '#666',
    fontFamily: 'Poppins',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
};
const totalReviewsNumber = {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
}
const totalValue = chartData.series.reduce((acc, curr) => acc + curr, 0);
const StatsChart = () => {
    const isSmallScreen = useMediaQuery('(max-width:440px)');
    const isSmallerScreen = useMediaQuery('(max-width:380px)');

    return (
        <Card
            variant="outlined"
            style={{
                width: '100%',
                borderRadius: '0.5625rem',
                background: '#FFF',
            }}
            sx={{ '& .MuiCardContent-root': { padding: '1.56rem 0rem 0rem 1.12rem', } }}
        >
            <CardContent>
                <div style={{ display: 'flex' }}>
                    {!isSmallScreen && 
                    <img src={YellowLine} alt='' />
                    }
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{
                            ...totalReviewsStyle, marginLeft: isSmallScreen ? (isSmallerScreen ? '5rem' :'8.12rem') : '0.87rem', textAlign: isSmallScreen ? 'center' : 'left' }}>{TOTAL_SALES}</div>
                        {!isSmallScreen && 
                        <div style={{ ...totalReviewsNumber, marginLeft: '1.13rem' }}>{totalValue.toLocaleString()}</div>
                        }
                    </div>
                </div>
                <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="donut"
                    width="380"
                    style={{ marginTop: isSmallScreen ? '0' : '-3rem', marginLeft: isSmallScreen ? (isSmallerScreen ? '-3rem' : '0') : '3rem', }}
                />
            </CardContent>
        </Card>
    );
};

export default StatsChart;
