import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMediaQuery } from 'react-responsive';

const ShowRowContainerStyle = {
    // position: 'absolute',
    display: 'flex',
    alignItems: 'center',
};

const ShowRowTextStyle = {
    color: '#58006B',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.6875rem',
    marginRight: '1.2rem',
};

const dropdownButtonStyle = {
    width: '5.8125rem',
    height: '2.375rem',
    borderRadius: '0.4375rem',
    border: '1px solid #9D40B1',
    background: '#9D40B1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '0.5rem', // Space for text
    paddingRight: '0.5rem', // Space for arrow icon
    cursor: 'pointer',
};

const dropdownButtonTextStyle = {
    textTransform: 'none',
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.6875rem',
    marginLeft: '0.8rem',
};

const arrowIconStyle = {
    color: '#FFF', // Set the arrow icon color to white
};

const ShowRow = ({setRow, row}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState('4');
    const isTab = useMediaQuery({ minWidth: 750 });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setRow(option)
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={ShowRowContainerStyle}>
            {isTab && <span style={ShowRowTextStyle}>Show Rows</span>}
            <Button
                style={dropdownButtonStyle}
                aria-controls="sort-by-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <span style={dropdownButtonTextStyle}>{row}</span>
                <ArrowDropDownIcon style={arrowIconStyle} />
            </Button>
            <Menu
                id="sort-by-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleOptionClick(2)}>2</MenuItem>
                <MenuItem onClick={() => handleOptionClick(4)}>4</MenuItem>
                <MenuItem onClick={() => handleOptionClick(8)}>8</MenuItem>
            </Menu>
        </div>
    );
};

export default ShowRow;
