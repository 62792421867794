import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo-cropped.png';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { IP_ADD } from '../constants/api';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VerificationModal from './VerificationModal';
import SuccessIcon from '../assets/images/Success-icon.svg';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import SpinnerComponent from './Spinner';

const WebOnboardCard = () => {
    const [emailFocus, setEmailFocus] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [correctPass, setCorrectPass] = useState(true);
    const [url, setUrl] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [isEmailLoading, setIsEmailLoading] = useState(true); // Track email loading state
    const location = useLocation();
    const isTab = useMediaQuery({ maxWidth: 900 });
    const navigate = useNavigate();
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        if (token) {
            setToken(token);

            // Fetch user email from the API
            axios.post(`${IP_ADD}/api/auth/token-email`, {
                token: token
            })
                .then(response => {
                    setUserEmail(response.data.response.email);

                    // Compare with the email stored in localStorage
                    const storedUser = localStorage.getItem('Cornucopia-user');
                    const user = JSON.parse(storedUser);
                    if (user && user.response && user.response.email !== response.data.response.email) {
                        setSnackbarMessage('Emails do not match. Please try again.');
                        setSnackbarSeverity('error');
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            navigate('/');
                        }, 2000);
                    }
                    else {
                        setIsEmailLoading(false); // Set email loading state to false if emails match
                    }
                })
                .catch(error => {
                    console.error('Error fetching user email:', error);
                    setSnackbarMessage('Token Expired. Please try with a new link');
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 2000);
                });
        }
    }, [location.search, navigate]);


    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleEmailFocus = () => {
        setEmailFocus(true);
    };

    const handleEmailBlur = () => {
        setEmailFocus(false);
    };

    const handlePasswordFocus = () => {
        setPasswordFocus(true);
    };

    const handlePasswordBlur = () => {
        setPasswordFocus(false);
    };

    const handleConfirmPasswordFocus = () => {
        setConfirmPasswordFocus(true);
    };

    const handleConfirmPasswordBlur = () => {
        setConfirmPasswordFocus(false);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleContinueClick = () => {
        // Regex pattern for password validation
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

        if ((password === confirmPassword) && (password !== '')) {
            // Check if the password matches the regex pattern
            if (!passwordRegex.test(password)) {
                setCorrectPass(false)
                return;
            }
            if (passwordRegex.test(password)) {
                setCorrectPass(true)
            }

            setLoading(true);
            axios.post(`${IP_ADD}/api/auth/web/onboard-user`, {
                token: token,
                password: password
            })
                .then(response => {
                    if (response.data.message === 'Credentials updated successfully') {
                        setUrl(response.data.response.url);
                        setIsVerificationModalOpen(true);
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error?.response?.data?.message === 'Credentials already exist') {
                        setSnackbarMessage('You have already created an account.');
                        setSnackbarSeverity('error');
                        setOpenSnackbar(true);
                    }
                    else {
                        setSnackbarMessage('An error occurred while processing your request.');
                        setSnackbarSeverity('error');
                        setOpenSnackbar(true);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            if (password === '') {
                setSnackbarMessage('Please enter a password.');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
            else {
                setSnackbarMessage('Password and confirm password do not match.');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        }
    };

    const handleVerifyEmail = async () => {
        if (url) {
            let updatedUrl = url;
            if (!updatedUrl.startsWith('http://') && !updatedUrl.startsWith('https://')) {
                updatedUrl = `https://${updatedUrl}`;
                await new Promise((resolve) => {
                    setUrl(updatedUrl, resolve); // Update the URL state
                });
            }
            window.open(updatedUrl, '_blank');
        } else {
            console.error('URL is empty or invalid');
        }
    };

    const handleVerificationModalClose = () => {
        setIsVerificationModalOpen(false);
    };

    const divStyles = {
        paddingTop: '3.75rem',
        paddingBottom: '3.75rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '53.75rem',
        borderRadius: '1.3125rem',
        border: '1px solid #B9B9B9',
        boxShadow: '0px 0px 4px 1px #D4D4D4',
        maxWidth: '90%',
        margin: '0 auto',
        marginTop: '8.5rem',
        border: 'none'
    };

    const LogoStyles = {
        width: '14.0625rem',
        height: '3.3125rem'
    };

    const HeadingStyles = {
        color: '#780093',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: isTab ? '1rem' : '1.25rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginTop: '3.44rem',
        width: isTab ? '70%' : '33.125rem'
    };

    const TextStyles = {
        fontFamily: 'Poppins',
        fontSize: '1.0625rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
    };

    const inputStyles = {
        padding: '1rem 0 1.06rem 1rem',
        outline: 'none',
        width: isTab ? '18rem' : '19.4375rem',
        height: '3.1875rem',
        flexShrink: 0,
        borderRadius: '0.4375rem',
        border: `1px solid ${emailFocus || passwordFocus || confirmPasswordFocus ? '#9D40B1' : '#D3D3D3'}`,
        color: '#9D40B1',
        fontSize: '0.9375rem',
    };

    const buttonStyles = {
        width: '18rem',
        height: '3.6875rem',
        flexShrink: 0,
        borderRadius: '2.125rem',
        background: '#6A007C',
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '1.25rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        border: 'none',
        marginTop: '3.12rem'
    };

    return (
        <>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>

            <VerificationModal onClose={handleVerificationModalClose} isOpen={isVerificationModalOpen} heading={'Congratulations'} subHeading={'Your account has been created! Click on Continue to be redirected to the website.'} buttonText={'Continue'} icon={SuccessIcon} onVerifyEmail={handleVerifyEmail} url={url} />
            {isEmailLoading ? (
                <SpinnerComponent />
            ) : (
                <div style={divStyles}>
                    <img style={LogoStyles} src={Logo} alt="Cornucopia Logo" />
                    <h1 style={HeadingStyles}>Create an account to access the purchased website. Your existing email will be used, please set up a password.</h1>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2rem' }}>
                        <p style={{ ...TextStyles, color: '#780093' }}>Email</p>
                        <input
                            type="text"
                            style={{ ...inputStyles, border: `1px solid ${'#9D40B1'}`, }}
                            onFocus={handleEmailFocus}
                            onBlur={handleEmailBlur}
                            value={userEmail}
                            readOnly
                            disabled
                        />
                        <p style={{ ...TextStyles, marginTop: '1.25rem', color: passwordFocus ? '#780093' : '#595959' }}>Password</p>
                        <div style={{ position: 'relative' }}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                style={{ ...inputStyles, border: `1px solid ${passwordFocus ? '#9D40B1' : '#D3D3D3'}` }}
                                onFocus={handlePasswordFocus}
                                onBlur={handlePasswordBlur}
                                onChange={handlePasswordChange}
                            />
                            {password !== '' &&
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                    style={{ position: 'absolute', right: '0.5rem', top: '50%', transform: 'translateY(-50%)' }}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            }
                        </div>
                        {!correctPass &&
                            <p style={{ ...TextStyles, marginTop: '0.25rem', color: '#FF0000', fontSize: '0.75rem', width: '17rem' }}>
                                    Password must be 8 characters long and contain at least 1 letter, 1 number, and 1 special character.
                            </p>
                        }
                        <p style={{ ...TextStyles, marginTop: '1.25rem', color: confirmPasswordFocus ? '#780093' : '#595959' }}>Confirm Password</p>
                        <div style={{ position: 'relative' }}>
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                style={{ ...inputStyles, border: `1px solid ${confirmPasswordFocus ? '#9D40B1' : '#D3D3D3'}` }}
                                onFocus={handleConfirmPasswordFocus}
                                onBlur={handleConfirmPasswordBlur}
                                onChange={handleConfirmPasswordChange}
                            />
                            {confirmPassword !== '' &&
                                <IconButton
                                    onClick={toggleConfirmPasswordVisibility}
                                    style={{ position: 'absolute', right: '0.5rem', top: '50%', transform: 'translateY(-50%)' }}
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            }
                        </div>
                    </div>
                    <button style={buttonStyles} onClick={handleContinueClick}>
                        {loading ?
                            <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} />
                            :
                            'Continue'
                        }
                    </button>
                    {responseMessage && <p>{responseMessage}</p>}
                </div>
            )}
        </>

    );
};

export default WebOnboardCard;

