import styled from "@emotion/styled";

export const Line = styled.div `
    width: 15.125rem;
    height: 0.0625rem;
    background: #D9D9D9;
    margin-left: 2.25rem;
    margin-top: 2.19rem;
`;

export const cardStyle = {
    width: '20.875rem',
    borderRadius: '0.5625rem',
    border: '1px solid rgba(255, 255, 255, 1)',
    background: '#FFF',
};

export const profileImageStyle = {
    width: '11.625rem',
    height: '12.8125rem',
    borderRadius: '0.6875rem'    
};

export const nameStyle = {
    color: '#3F0065',
    fontFamily: 'Poppins',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

export const professionStyle = {
    color: '#9D40B1',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
};

export const ratingTextStyle = {
    color: '#888',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'italic',
    fontWeight: '500',
    lineHeight: 'normal',
};

export const starImageStyle = {
    width: '8.8855rem',
    height: '1.17275rem',
};

export const productReviews = {
    color: '#888',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'italic',
    fontWeight: 500,
    lineHeight: 'normal',
};

export const tickStyle = {
    width: '1.6875rem',
    height: '1.6875rem',
};

export const verifiedtext = {
    color: '#169C00',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'italic',
    fontWeight: '500',
    lineHeight: 'normal',
};

export const userNameStyle = {
    color: '#747474',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWtyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

export const usernameValueStyle = {
    color: '#888',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

export const descriptionStyle = {
    color: '#747474',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

export const descriptionContentStyle = {
    color: '#888',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '121.187%',
    letterSpacing: '0.015rem',
};