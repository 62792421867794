import { Grid } from "@mui/material";
import { FlexDiv, DiscoverText, AiBoostText, LandingPageHomeText, AnalyticsText, ProfileImgText, ViewProductBtn, DiscoverImg, AnalystText, AnalystLongText, UserCenteredText, BlurredBackground, AnalystDiv } from "../../assets/styles/styles";
import { UNLOCK_POWER, DISCOVER, AI_BOOST, REAL_GEEKS_AI, ANALYTICS, AUTOMATION, VIEW_PRODUCT } from "../../constants/text";
import Trolley from "../../assets/images/trolley.svg";
import Coins from "../../assets/images/coins.svg";
import ProfileImg from "../../assets/images/profile_img.png";
import Fire from "../../assets/images/fire.svg";
import Discover from "../../assets/images/HeroImg.webp";
import GeekAIHeroImg from '../../assets/images/GeekAIHeroImg.png';
import GAIPP from '../../assets/images/GAIPP.png';
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom";
import { ProductOwnerDiv, ProductOwnerHeader, ProductOwnerText } from "./constants";

const TopSectionRG = () => {
    const isBigDesktop = useMediaQuery({ minWidth: 1600 });
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isTab = useMediaQuery({ minWidth: 600 });
    const isMobile = useMediaQuery({ maxWidth: 599 })
    const navigate = useNavigate();

    const handleViewProdClick = () => {
        navigate('/product');
    }

    return (
        <>
            <Grid container>
                <Grid item lg={6} md={12} sm={12} xs={12} display='flex' justifyContent='center' marginTop={isDesktop ? '5rem' : ''}>
                    <motion.div
                        initial={{ x: -1000 }} // initial position outside the viewport
                        animate={{ x: 0 }} // animate to the original position
                        transition={{ duration: 1 }} // transition duration
                    >
                        <div style={{ marginTop: '2rem' }}>
                            <div style={{ display: isBigDesktop ? 'flex' : '' }}>
                                <DiscoverText style={{ fontSize: isMobile ? '2.5rem' : '3.125rem' }}>Discover</DiscoverText>
                                <AiBoostText style={{ marginLeft: isBigDesktop ? '0.5rem' : '', fontSize: isMobile ? '2.5rem' : '3.125rem' }}>GeekAI</AiBoostText>
                                </div>
                            {isTab &&
                                <div style={{ width: (isBigDesktop || !isDesktop) ? '33rem' : '23rem', marginTop: '1.2rem' }}>
                                    <LandingPageHomeText>"Speed to Lead" is the name of the game, but it can be tough to reach out to every lead immediately.
Enter GeekAI – Strengthen your follow-up process with an AI communication assistant, and enjoy peace of mind with 100% of opportunities engaged.</LandingPageHomeText>
                                </div>
                            }
                            <FlexDiv style={{ marginTop: '1.5rem' }}>
                                <img alt="" src={Trolley} style={{ marginRight: '0.5rem' }} />
                                <img alt="" src={Coins} style={{ marginRight: '1.5rem' }} />
                                <AnalyticsText style={{ marginRight: '1rem' }}>Real Estate</AnalyticsText>
                                <AnalyticsText>{AUTOMATION}</AnalyticsText>
                            </FlexDiv>
                            <FlexDiv style={{ marginTop: '1.5rem' }}>
                                <img alt="" src={GAIPP} style={{ width: '2.625rem', height: '2.625rem' }} />
                                <div style={{ marginLeft: '0.5rem' }}>
                                    <ProfileImgText>Real Geeks</ProfileImgText>
                                    <ProfileImgText style={{ color: '#ACA8A8' }}>Eon swift, fleeting time</ProfileImgText>
                                </div>
                            </FlexDiv>
                            <FlexDiv style={{ marginTop: '2.5rem', cursor: 'pointer' }}>
                                <img alt="" src={Fire} style={{ marginRight: '0.8rem' }} />
                                <ViewProductBtn style={{ cursor: 'pointer' }} onClick={() => window.location.href = 'https://www.realgeeks.com/geek-ai/'} >{VIEW_PRODUCT}</ViewProductBtn>
                            </FlexDiv>

                            {/* {isMobile && <img alt="" src={Discover} style={{width:'21rem', height:'16rem'}} />} */}
                        </div>
                    </motion.div>
                </Grid>
                {isDesktop &&
                    <Grid item lg={6} md={12} sm={12} xs={12} display='flex' justifyContent='center' marginTop='5rem'>
                        <BlurredBackground />
                        <div>
                            <motion.div transition={{ delay: 0.1 }} initial={{ y: -150 }} animate={{ y: 0 }}>
                                <DiscoverImg alt="" src={GeekAIHeroImg} style={{ marginLeft: '3rem' }} /></motion.div>
                            <FlexDiv style={{ gap: '1rem' }}>
                                <motion.div transition={{ delay: 0.1 }} initial={{ y: 150 }} animate={{ y: 0 }}>
                                    <AnalystDiv style={{ marginTop: '1rem' }}>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '0.4rem' }}>
                                            {/* <AnalystText>{BUSINESS_ANALYST}</AnalystText> */}
                                            <UserCenteredText>Mission statement</UserCenteredText>
                                            <AnalystLongText>                                            To combine innovative solutions and modern technology to improve the lives of Real Estate Professionals.</AnalystLongText>
                                        </div>
                                    </AnalystDiv>
                                </motion.div>
                                <motion.div transition={{ delay: 0.1 }} initial={{ x: 150 }} animate={{ x: 0 }}>
                                    <ProductOwnerDiv>
                                        <ProductOwnerHeader>Vision statement</ProductOwnerHeader>
                                        <ProductOwnerText>
                                            To combine innovative solutions and modern technology to improve the lives of Real Estate Professionals
                                        </ProductOwnerText>
                                    </ProductOwnerDiv>
                                </motion.div>
                            </FlexDiv>
                        </div>
                    </Grid>
                }
            </Grid>
            {isMobile &&
                <motion.div
                    initial={{ x: 1000 }} // initial position outside the viewport
                    animate={{ x: 0 }} // animate to the original position
                    transition={{ duration: 1 }} // transition duration
                >
                    <FlexDiv style={{ justifyContent: 'center' }}>
                        <img alt="" src={Discover} style={{ width: '21rem', height: '16rem', marginTop: '2.5rem', marginBottom: '-2rem', borderRadius:'0.3125rem' }} />
                    </FlexDiv>
                </motion.div>
            }
        </>
    )
}
export default TopSectionRG;