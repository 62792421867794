import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { FlexDiv, TrendingProductsText, ViewBtn } from '../../assets/styles/styles';
import { MEET_THE_SALES_FORCE, VIEW_ALL } from '../../constants/text';
import { useMediaQuery } from 'react-responsive';
import SalesForceCard from './SalesForceCard';
import { sellerData } from '../../constants/data';
import NoSeller from '../ViewAllSellers/NoSeller';

const SalesForce = ({
    filteredSellers = [],
    selectedOption = 'All',
    totalSalesForce = 6,
    cardWidth = '15.75rem',
    lg = 4,
    md = 4,
    sm = 6,
    xs = 12,
    header = true,
    viewBtn = true,
    setCurrentScreen = () => { },
}) => {
    const sellersToDisplay = filteredSellers.length > 0 ? filteredSellers : sellerData;
    const filteredSellersToDisplay = selectedOption === 'All' ? sellersToDisplay : sellersToDisplay.filter((seller) => seller.designation === selectedOption);
    const limitedSellersToDisplay = filteredSellersToDisplay.slice(0, totalSalesForce);
    const filteredLimitedSellersToDisplay = limitedSellersToDisplay.filter(seller =>
        !(seller?.firstName?.toLowerCase() === 'emanuel' && seller?.lastName?.toLowerCase() === 'berhanu')
    );

    const limitedSellersWithoutEmanuelBerhanu = filteredLimitedSellersToDisplay.slice(0, totalSalesForce);

    const navigate = useNavigate();
    const isMobileTwo = useMediaQuery({ maxWidth: 600 });

    const handleSellerClick = (sellerID) => {
        navigate(`/seller-details/${sellerID}`);
    };

    const handleViewAllClick = () => {
        navigate('/sellers');
    };

    const screenSizes = {
        isLargeScreen: useMediaQuery({ query: '(min-width: 1200px)' }),
        isMediumScreen: useMediaQuery({ query: '(min-width: 900px) and (max-width: 1199.99px)' }),
        isSmallScreen: useMediaQuery({ query: '(min-width: 600px) and (max-width: 899.99px)' }),
        isExtraSmallScreen: useMediaQuery({ query: '(max-width: 599.99px)' }),
    };

    useEffect(() => {
        const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } = screenSizes;
        if (isLargeScreen) {
            setCurrentScreen(4);
        } else if (isMediumScreen) {
            setCurrentScreen(3);
        } else if (isSmallScreen) {
            setCurrentScreen(2);
        } else if (isExtraSmallScreen) {
            setCurrentScreen(1);
        }
    }, [screenSizes, setCurrentScreen]);

    return (
        <div>
            {header && <TrendingProductsText style={{ fontSize: '2rem', textAlign: 'center', marginLeft: '0.5rem', marginRight: '0.5rem', marginTop: isMobileTwo ? '10rem' : '' }}>{MEET_THE_SALES_FORCE}</TrendingProductsText>}
            {filteredSellers.length === 0 ? <div style={{ marginTop: '2rem', marginLeft: !screenSizes.isExtraSmallScreen ? '2.5rem' : '' }}><NoSeller width={'89%'} /></div> : <>
                <Grid container marginTop='2.3rem' marginBottom='3rem'>
                    {limitedSellersWithoutEmanuelBerhanu.map((seller, index) => (
                        <Grid item key={index} lg={lg} md={md} sm={sm} xs={xs} display='flex' flexDirection='column' alignItems='center' marginTop='2.8rem'>
                            <button onClick={() => handleSellerClick(seller.id)} style={{ display: 'contents' }}>
                                {seller.firstName.toLowerCase() !== 'emanuel' && seller.lastName.toLowerCase() !== 'berhanu' &&
                                    < SalesForceCard name={`${seller.firstName} ${seller.lastName}`} designation={seller.designation ? seller.designation : 'Web Developer'} cardWidth={cardWidth} cardImage={seller.displayPicture} />
                                }
                            </button>
                        </Grid>
                    ))}
                </Grid>
                {viewBtn && (
                    <FlexDiv style={{ justifyContent: 'center' }}>
                        <ViewBtn onClick={handleViewAllClick}>{VIEW_ALL}</ViewBtn>
                    </FlexDiv>
                )}
            </>
            }
        </div>
    );
};

export default SalesForce;
