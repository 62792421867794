import React, { useState } from 'react';
import Modal from 'react-modal';
import MessageIcon from '../assets/images/message.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import { IP_ADD } from '../constants/api';
import { sendVerificationEmail } from '../Services/Authentication';
import { useMediaQuery } from 'react-responsive';

const VerificationModal = ({ isOpen, onClose = () => { }, onVerifyEmail = () => { }, isVerifyingEmail = false, heading = 'Please verify your Email', buttonText = 'Verify Email', subHeading = 'A link has been sent to your email. If you dont receive email in 1 minute click Resend Email', icon = null, forgotPassword = false, email, signup = false }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 490 })
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleResendEmail = async () => {
        try {
            // Show circular progress while the API call is in progress
            setLoading(true);

            // Make API call to resend email
            if (forgotPassword) {
                const response = await axios.post(`${IP_ADD}/api/auth/forgot-password`, { email });
            }
            else if (signup) {
                await sendVerificationEmail(email);
            }
            // Close circular progress
            setLoading(false);

            // Show success message
            setSnackbarSeverity('success');
            setSnackbarMessage('Email sent successfully!');
            setSnackbarOpen(true);
        } catch (error) {
            // Close circular progress
            setLoading(false);

            // Show error message
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to resend email. Please try again later.');
            setSnackbarOpen(true);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Terms and conditions Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                },
                content: {
                    width: isMobile ? '80%' : '25rem',
                    height: '18rem',
                    margin: 'auto',
                    borderRadius: '0.75rem',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: '1.5rem',
                    zIndex: 1001,
                },
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                    src={icon === null ? MessageIcon : icon}
                    alt=""
                    style={{
                        width: '3.5rem',
                        height: '3.5rem',
                        borderRadius: '0.5rem',
                        border: '8px solid var(--primary-50, #F9F5FF)',
                        background: 'var(--primary-100, #F4EBFF)',
                        marginBottom: '1.25rem',
                        justifyContent: 'center',
                    }}
                />
            </div>
            <h2
                style={{
                    color: '#46137C',
                    fontFamily: 'Poppins',
                    fontSize: '1.5rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {heading}
            </h2>
            <h2
                style={{
                    color: '#667085',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }}
            >
                {subHeading}
            </h2>
            <div style={{ display: 'flex', marginTop: '0rem', gap: forgotPassword || signup ? '0.75rem' : '' }}>
                {(forgotPassword || signup) &&
                    <button
                        onClick={handleResendEmail}
                        style={{
                            background: '#EDEDED', // Change background color based on the disabled state
                            color: '#8F8F8F',
                            width: '50%',
                            borderRadius: '0.4375rem',
                            fontFamily: 'Poppins',
                            fontSize: '0.8125rem',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            marginTop: '2rem',
                            border: 'none',
                            display: 'block',
                            padding: '8px',
                            cursor: 'pointer',
                        }}
                    >
                        {loading ? <CircularProgress color="secondary" style={{ width: '20px', height: '20px' }} /> : 'Resend Email'}
                    </button>
                }
                <button
                    onClick={onVerifyEmail}
                    style={{
                        background: '#3F0065', // Change background color based on the disabled state
                        color: '#FFF',
                        width: forgotPassword || signup ? '50%' : '100%',
                        borderRadius: '0.4375rem',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        marginTop: '2rem',
                        border: 'none',
                        display: 'block',
                        padding: '8px',
                        cursor: 'pointer',
                    }}
                >
                    {isVerifyingEmail ? <CircularProgress color="secondary" style={{ width: '27px', height: '25px' }} /> : buttonText}
                </button>
            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Modal>
    );
};

export default VerificationModal;
