import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  width: 11rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125rem;
  border: 1px solid #9803FA;
  color: #9803FA;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  outline: none;
  margin-top: 3rem;
  @media (max-width: 700px) {
    width: 95%;
    margin-top: 0rem;
  }
`;

const Option = styled.option`
  font-family: Roboto;
`;

const Dropdown = ({ setSortBy }) => { // Receive setSortBy as prop
  const handleSelectChange = (e) => {
    setSortBy(e.target.value); // Call setSortBy with the selected value
  };

  return (
    <StyledSelect onChange={handleSelectChange}> {/* Add onChange event */}
      <Option value="">Sort by: Featured</Option>
      <Option value="price_asc">Price: Low to High</Option>
      <Option value="price_desc">Price: High to Low</Option>
      <Option value="avg_customer_review">Avg. Customer Reviews</Option>
      <Option value="created_at">Newest Arrivals</Option>
      <Option value="best_seller">Best Sellers</Option>
    </StyledSelect>
  );
};

export default Dropdown;
