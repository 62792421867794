import SellerSidebar from "../../components/SellerDashboard/SellerSidebar";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import io from "socket.io-client";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise, ENDPOINT } from "./constants";
import LogoutModal from "../../components/LogoutModal";
import { logout } from "../../Services/Authentication";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

var socket;

const SellerDashboard = () => {
    const isLaptop = useMediaQuery({ minWidth: 720 });
    const [socketConnected, setSocketConnected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const closeModal = () => {
        setLoading(false);
        setIsOpen(false);
    };

    const handlelogout = async (token) => {
        try {
            setLoading(true)
            const token = localStorage.getItem('refresh-token');
            await logout(token);
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('access-token-expiry');
            localStorage.removeItem('refresh-token-expiry');
            localStorage.removeItem('Cornucopia-user');
            setLoading(false);
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem("Cornucopia-user");
        if (user) {
            socket = io(ENDPOINT);
            socket.on("connected", () => setSocketConnected(true));
        }
    }, []);

    return (
        <>
            <LogoutModal isOpen={isOpen} onClose={closeModal} onVerifyEmail={handlelogout} isVerifyingEmail={loading} heading='Logging Out' buttonText='Logout' />
            <SellerSidebar setIsOpen={setIsOpen} />
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <div style={{ marginLeft: isLaptop ? '15rem' : '0rem' }}>
                    <Elements stripe={stripePromise}>
                        <Outlet />
                    </Elements>
                </div>
            </Container>
        </>
    )
}

export default SellerDashboard;
