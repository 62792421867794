import axios from "axios";
import { IP_ADD } from "../constants/api";

const apiUrl = `${IP_ADD}/api`;

export const matchPassword = async (currentPassword) => {
    const endpoint = `${apiUrl}/users/match-password`;
    const accessToken = (localStorage.getItem('access-token'));
    const token = accessToken
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const payload = {
        confirm_password: currentPassword,
    };

    try {
        const response = await axios.post(endpoint, payload, { headers });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (email, newPassword) => {
    const forgotPassRes = await axios.post(`${apiUrl}/auth/forgot-password`, { email });
    const token = forgotPassRes.data.response;

    try {
        const response = await axios.post(`${apiUrl}/auth/reset-password?token=${token}`, { password: newPassword });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const passwordReset = async (email, confirmPass, newPass) => {
    try {
        const response = await axios.post(`${apiUrl}/auth/update-password`,
            {
                email: email,
                confirm_password: confirmPass,
                new_password: newPass
            }
        )
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateProfile = async (userId, formData) => {
    const endpoint = `${apiUrl}/users/${userId}`;
    const accessToken = (localStorage.getItem('access-token'));
    const token = accessToken
    try {
        const response = await axios.put(endpoint, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllSellers = async (api_url, type, setFilteredSellers, setTotalPages, setIsFetching, searchChange, setCurrentPage, setTotalSellers) => {
    try {
        let response;

        if (type === 'get') {
            response = await axios.get(api_url);
        } else if (type === 'post') {
            response = await axios.post(api_url);
            setCurrentPage(1);
        }

        if (response.data.status === 200) {
            let data = response.data.response;
            if (type === 'get') setFilteredSellers(data.results);
            else if (type === 'post') setFilteredSellers(data);
            setTotalPages(data.totalPages);
            setTotalSellers(data.totalResults)
            setIsFetching(false);
        }
    } catch (error) {
        console.log('error', error);
        setIsFetching(false);
    }
};



export const getSellers = async (limit) => {
    try {
        const response = await axios.get(`${apiUrl}/users/sellers?limit=${limit}`);
        if (response.data.status === 200) {
            let data = response.data.response;
            return data.results;
        }
    } catch (error) {
        console.error('Error fetching sellers:', error);
        throw error;
    }
};

export const handleUpdateUser = async (userId) => {
    try {
        const res = await axios.get(`${IP_ADD}/api/users/${userId}`);
        if (res.data) {
            localStorage.setItem('Cornucopia-user', JSON.stringify(res.data));
        }
        return res.data;
    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
};

