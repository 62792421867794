import React from 'react';
import { Card, CardContent } from '@mui/material';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import cardImg from '../../assets/images/AlexLandingCard.jpg';
import { LOREM_IPSUM_DOLOR } from '../../constants/text';
import { useMediaQuery } from 'react-responsive';
import { AlexJohnson, Designer, LoremIpsum, CardImage, StyledCard } from './constants';

const SalesForceCard = ({ cardWidth = '15.75rem', name = 'Alex Johnson', designation = 'UI/UX Designer', cardImage = undefined }) => {
  const isMobileTwo = useMediaQuery({ maxWidth: 600 });

  return (
    <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.4 }}>
      <StyledCard isMobileTwo={isMobileTwo} sx={{ width: isMobileTwo ? '21rem' : cardWidth }}>
        <CardContent>
          <CardImage
            src={cardImage && cardImage.includes('cornucopia-uploads.s3.us-east-1.amazonaws') ? cardImage : cardImg}
            alt="Card Image"
            isMobileTwo={isMobileTwo}
          />
          <AlexJohnson>{name}</AlexJohnson>
          <Designer>{designation}</Designer>
          <LoremIpsum>Tech enthusiast turned seller! Offering cutting-edge gadgets and solutions to elevate your tech experience. Join the innovation journey!</LoremIpsum>
        </CardContent>
      </StyledCard>
    </motion.div>
  );
};

export default SalesForceCard;