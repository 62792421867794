import React from 'react'
import Carousel from 'react-material-ui-carousel'
import Item from './Item'
import TopSection from '../LandingPage/TopSection'
import TopSectionRG from '../LandingPage/TopSectionRG'
import TopSectionRG2 from '../LandingPage/TopSectionRG2'


const CarouselWrapper = () => {
  var items = [<TopSection />, <TopSectionRG />, <TopSectionRG2 />]

  return (
    <Carousel>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  )
}

export default CarouselWrapper;