

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardMedia, CardContent, Container, Grid, Paper, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Boundless, SelectAccount, Morbi, SavvyShopper, ImageStyle, CardStyle, FlexDiv, LoginBoxBtn } from '../../assets/styles/styles';
import trolleyImage from '../../assets/images/trolley_1.svg';
import trolleyWhite from '../../assets/images/trolley_white.svg'
import arrowWhite from '../../assets/images/arrow_white.svg'
import tagWhite from '../../assets/images/tag_white.svg';
import arrowImage from '../../assets/images/arrow.svg';
import tagImg from '../../assets/images/tag.svg';
import { UNLOCK_BOUDLESS, SELECT_YOUR_ACCOUNT, MORBI } from '../../constants/text';
import axios from 'axios';
import { IP_ADD } from '../../constants/api';

const SelectRole = () => {
    const [buyerIsClicked, setBuyerIsClicked] = useState(false);
    const [sellerIsClicked, setSellerIsClicked] = useState(false);
    const [trolleyImg, setTrolleyImg] = useState(trolleyImage);
    const [arrowImgSeller, setArrowImgSeller] = useState(arrowImage);
    const [arrowImgBuyer, setArrowImgBuyer] = useState(arrowImage);
    const [tagImage, setTagImage] = useState(tagImg)
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));


    const cardStyle = {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #9600AF',
        height: isMobile ? '5rem' : '6.8125rem',
        borderRadius: '0.69rem',
        marginTop: '1.5rem',
        marginBottom: '1.3rem',
        backgroundColor: buyerIsClicked ? '#6A007C' : '#FFFFFF',
        color: buyerIsClicked ? '#FFFFFF' : 'initial',
        width: isMobile ? '100%' : '29rem',
    };

    const cardStyleSeller = {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #9600AF',
        height: isMobile ? '5rem' : '6.9125rem',
        borderRadius: '0.69rem',
        backgroundColor: sellerIsClicked ? '#6A007C' : '#FFFFFF',
        color: buyerIsClicked ? '#FFFFFF' : 'initial',
        width: isMobile ? '100%' : '29rem',
    };

    const cardContentStyle = {
        padding: isMobile ? '1rem' : '2rem',
        textAlign: 'center',
        borderRadius: '1.3125rem',
    };

    const boundlessStyle = {
        marginTop: isMobile ? '2rem' : '4.19rem',
        fontSize: isMobile ? '1.5rem' : '2.25rem',
    };

    const selectAccountStyle = {
        textAlign: 'left',
        fontSize: isMobile ? '1.1rem' : '1.5rem',
        marginBottom: isMobile ? '0.1rem' : '0.25rem',
        marginTop: isMobile ? '3rem' : '4.06rem',
    };

    const morbiStyle = {
        textAlign: 'left',
        fontSize: isMobile ? '0.75rem' : '1rem',
    };

    const savvyShopperStyle = {
        fontSize: isMobile ? '0.8rem' : '1.375rem',
    };

    const handleClick = () => {
        setBuyerIsClicked(!buyerIsClicked);
        setSellerIsClicked(false);
        if (!buyerIsClicked) {
            setTrolleyImg(trolleyWhite);
            setArrowImgBuyer(arrowWhite);
            setArrowImgSeller(arrowImage);
            setTagImage(tagImg);
        } else {
            setTrolleyImg(trolleyImage);
            setArrowImgBuyer(arrowImage);
        }
    };

    const handleClickSeller = () => {
        setSellerIsClicked(!sellerIsClicked);
        setBuyerIsClicked(false);
        if (!sellerIsClicked) {
            setTrolleyImg(trolleyImage);
            setArrowImgSeller(arrowWhite);
            setTagImage(tagWhite);
            setArrowImgBuyer(arrowImage);
        } else {
            setArrowImgSeller(arrowImage);
            setTagImage(tagImg);
        }
    };
    const handleContinue = () => {
        if (buyerIsClicked || sellerIsClicked) {
            const apiUrl = `${IP_ADD}/api/users/${userData.response.id}`;
            const role = buyerIsClicked ? 'buyer' : 'seller';

            axios.put(apiUrl, { role })
                .then(response => {
                    // Handle the response if needed
                    localStorage.setItem('Cornucopia-user', JSON.stringify(response.data));
                    if (buyerIsClicked)
                        navigate('/')
                    else
                        navigate('/seller')
                })
                .catch(error => {
                    // Handle errors if needed
                    console.error(error);
                });
        }
    };
    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <Container>
                    <Grid container spacing={2} justifyContent="center" style={{ marginTop: isMobile ? '1rem' : '2rem', marginBottom: '2rem' }}>
                        <Grid item xs={12} md={8}>
                            <Paper square elevation={3} style={cardContentStyle}>
                                <Typography>
                                    <Boundless style={boundlessStyle}>
                                        {UNLOCK_BOUDLESS}
                                    </Boundless>
                                </Typography>
                                <div style={{ margin: '2rem 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ marginRight: isMobile ? '-1rem' : '4rem' }}>
                                        <Typography>
                                            <SelectAccount style={selectAccountStyle}>
                                                <b>
                                                    {SELECT_YOUR_ACCOUNT}
                                                </b>
                                            </SelectAccount>
                                        </Typography>
                                        <Typography>
                                            <Morbi style={morbiStyle}>
                                                {MORBI}
                                            </Morbi>
                                        </Typography>
                                    </div>
                                    <CardStyle>
                                        <Card style={{ ...cardStyle, cursor: 'pointer' }} onClick={handleClick}>
                                            <ImageStyle style={{ marginLeft: '2rem', marginRight: '0.5rem' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={trolleyImg}
                                                    title="Image Title"
                                                    alt="Trolley Image"
                                                />
                                            </ImageStyle>
                                            <CardContent>
                                                <Typography>
                                                    <SavvyShopper style={{ ...savvyShopperStyle, color: buyerIsClicked ? '#FFFFFF' : savvyShopperStyle.color }}>
                                                        I am a savvy shopper,
                                                    </SavvyShopper>
                                                    <SavvyShopper style={{ ...savvyShopperStyle, color: buyerIsClicked ? '#FFFFFF' : savvyShopperStyle.color }}>
                                                        I want to join as a buyer
                                                    </SavvyShopper>
                                                </Typography>
                                            </CardContent>
                                            <ImageStyle style={{ marginLeft: '2rem', marginRight: '0.5rem' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={arrowImgBuyer}
                                                    title="Image Title"
                                                    alt="Trolley Image"
                                                />
                                            </ImageStyle>
                                        </Card>
                                    </CardStyle>
                                    <CardStyle>
                                        <Card style={{ ...cardStyleSeller, cursor: 'pointer' }} onClick={handleClickSeller}>
                                            <ImageStyle style={{ marginLeft: '2.5rem', marginRight: '0.5rem' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={tagImage}
                                                    title="Image Title"
                                                    alt="Trolley Image"
                                                />
                                            </ImageStyle>
                                            <CardContent>
                                                <Typography>
                                                    <SavvyShopper style={{ ...savvyShopperStyle, color: sellerIsClicked ? '#FFFFFF' : savvyShopperStyle.color }}>
                                                        I am an entrepreneur,
                                                    </SavvyShopper>
                                                    <SavvyShopper style={{ ...savvyShopperStyle, color: sellerIsClicked ? '#FFFFFF' : savvyShopperStyle.color }}>
                                                        I want to join as a seller
                                                    </SavvyShopper>
                                                </Typography>
                                            </CardContent>
                                            <ImageStyle style={{ marginLeft: '2rem', marginRight: '0.5rem' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={arrowImgSeller}
                                                    title="Image Title"
                                                    alt="Trolley Image"
                                                />
                                            </ImageStyle>
                                        </Card>
                                    </CardStyle>
                                </div>
                                <FlexDiv style={{ justifyContent: 'center' }}>
                                    <LoginBoxBtn
                                        onClick={handleContinue}
                                        style={{
                                            marginTop: '2.75rem',
                                            width: isMobile ? '70%' : '18rem',
                                            height: isMobile ? '3rem' : '3.6875rem',
                                            flexShrink: 0,
                                            borderRadius: '2.125rem',
                                            background: buyerIsClicked || sellerIsClicked ? '#6A007C' : '#ABABAB',
                                            color: '#FFFFFF',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '20px',
                                            border: buyerIsClicked || sellerIsClicked ? '1px solid #3F0065' : '1px solid #ABABAB',
                                        }}
                                    >
                                        Continue
                                    </LoginBoxBtn>
                                </FlexDiv>

                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Container>

        </>
    );
}

export default SelectRole;