import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import CornucopiaIcon from '../../assets/images/cornucopia_landing_icon.png'
import ForgotPass from '../../assets/images/forgot-password.svg'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { LoginBoxBtn } from '../../assets/styles/styles';
import { useTheme, useMediaQuery } from '@mui/material';
import Footer from '../../components/Footer';
import FooterDivider from '../../components/FooterDivider';
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { IP_ADD } from '../../constants/api';
import { ENTER_EMAIL, RESET_PASSWORD, YOUR_EMAIL, NEW_PASSWORD, CONFIRM_NEW_PASSWORD, CONTINUE } from '../../constants/text';
import { resetTextStyle, textFieldHeader, textFieldStyle } from './constants';
import VerificationModal from '../../components/VerificationModal';
import { Container } from '@mui/material';

const ForgotPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newPassSelected, setNewPassSelected] = useState(false);
    const [confirmPassSelected, setConfirmPassSelected] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [dataSubmission, setDataSubmission] = useState(false);
    const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    const isPasswordFieldNotEmpty = newPassword.trim().length > 0;
    const isConfirmPasswordFieldNotEmpty = confirmPassword.trim().length > 0;
    // const [gettingEmail, setGettingEmail] = useState(true);
    // const [token, setToken] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const gettingEmail = !token;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const apiUrl = `${IP_ADD}/api`;
    const handlePassTextFieldFocus = () => {
        setNewPassSelected(true);
    };
    const handlePassTextFieldBlur = () => {
        setNewPassSelected(false);
    };
    const handleConfirmTextFieldFocus = () => {
        setConfirmPassSelected(true);
    };
    const handleConfirmTextFieldBlur = () => {
        setConfirmPassSelected(false);
    };
    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };
    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };
    const handleVerifyEmail = async () => {
        try {
            setIsVerifyingEmail(true);
            closeModal();
            setTimeout(() => {
                navigate("/login");
            }, 1000);
        } catch (error) {
            console.error('Error sending verification email:', error);
        } finally {
            setIsVerifyingEmail(false);
        }
    };
    const getToken = async (email) => {
        try {
            setUserEmail(email);
            const resetToken = await axios.post(`${apiUrl}/auth/forgot-password`, { email });
            return resetToken.data.response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const handleContinueButtonClick = async () => {
        try {
            if (!token) {
                setDataSubmission(true);
                const newToken = await getToken(newPassword);
                setSnackbarMsg('Reset password link has been emailed');
                setSnackbarSeverity('success');
                setOpen(true);
                openModal();
                // setToken(newToken);
                setNewPassword('');
                setDataSubmission(false);
                // setGettingEmail(false)
            } else if (!passwordPattern.test(newPassword)) {
                setPasswordError('Password must be at least 8 characters long and contain atleast 1 letter,number and special character')
            } else if (newPassword !== confirmPassword) {
                setPasswordError('')
                setSnackbarMsg('Passwords do not match');
                setSnackbarSeverity('error');
                setOpen(true);
            } else {
                setDataSubmission(true);
                const response = await axios.post(`${apiUrl}/auth/reset-password?token=${token}`, { password: newPassword });
                setSnackbarMsg('Password reset successful');
                setSnackbarSeverity('success');
                setOpen(true);
                setDataSubmission(false);
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
            }
        } catch (error) {
            setDataSubmission(false);
            console.log(error);
            setSnackbarMsg('Error Occurred');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                    >
                        {snackbarMsg}
                    </MuiAlert>
                </Snackbar>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5.5rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <img src={CornucopiaIcon} alt='' />
                        <img src={ForgotPass} alt='' style={{ marginTop: '5.69rem', width: '3.5625rem', height: '3.0625rem', marginLeft: '6rem' }} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={resetTextStyle}>
                        {gettingEmail ? ENTER_EMAIL : RESET_PASSWORD}
                    </div>
                    <div style={{ ...textFieldHeader, color: newPassSelected ? '#780093' : '#595959', marginRight: gettingEmail ? '10.7rem' : '11.7rem' }}>{gettingEmail ? YOUR_EMAIL : NEW_PASSWORD}</div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextField
                            variant="outlined"
                            type={showPassword || gettingEmail ? 'text' : 'password'}
                            style={{ ...textFieldStyle, marginLeft: gettingEmail ? '3rem' : '' }}
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            helperText={passwordError}
                            InputProps={{
                                endAdornment: !gettingEmail && isPasswordFieldNotEmpty && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& input': {
                                    color: newPassSelected ? '#9D40B1' : 'inherit',
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9D40B1',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '0.3125rem',
                                    height: '3.1875rem'
                                },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9D40B1',
                                },
                            }}
                            onFocus={handlePassTextFieldFocus}
                            onBlur={handlePassTextFieldBlur}
                        />

                    </div>
                    {!gettingEmail &&
                        <>
                            <div style={{ ...textFieldHeader, color: confirmPassSelected ? '#780093' : '#595959', marginRight: '7.25rem', marginTop: passwordError.length > 0 ? '5rem' : '1.75rem' }}>{CONFIRM_NEW_PASSWORD}</div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    style={textFieldStyle}
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    InputProps={{
                                        endAdornment: !gettingEmail && isConfirmPasswordFieldNotEmpty && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        '& input': {
                                            color: confirmPassSelected ? '#9D40B1' : 'inherit', // Change text color on focus
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0.3125rem',
                                            height: '3.1875rem'
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9D40B1',
                                        },
                                    }}
                                    onFocus={handleConfirmTextFieldFocus}
                                    onBlur={handleConfirmTextFieldBlur}
                                />
                            </div>
                        </>
                    }
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <LoginBoxBtn
                            onClick={handleContinueButtonClick}
                            style={{
                                marginTop: '5.12rem',
                                width: isMobile ? '70%' : '18rem',
                                height: isMobile ? '3rem' : '3.6875rem',
                                flexShrink: 0,
                                borderRadius: '2.125rem',
                                background: '#6A007C',
                                color: '#FFFFFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '20px',
                                marginLeft: gettingEmail ? '3rem' : ''
                            }}
                        >
                            {!dataSubmission ? CONTINUE : <CircularProgress color="secondary" style={{ width: '27px', height: '25px' }} />}

                        </LoginBoxBtn>
                    </div>
                </div>
                <VerificationModal isOpen={isOpen} onClose={closeModal} onVerifyEmail={handleVerifyEmail} isVerifyingEmail={isVerifyingEmail} heading='Reset Password' buttonText='Cancel' forgotPassword={true} email={userEmail} />
            </Container>
            <FooterDivider />
            <Footer />

            <VerificationModal isOpen={isOpen} onClose={closeModal} onVerifyEmail={handleVerifyEmail} isVerifyingEmail={isVerifyingEmail} heading='Reset Password' buttonText='Cancel' forgotPassword={true} email={userEmail} />

        </>
    );
};

export default ForgotPassword;
