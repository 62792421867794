import React from 'react';
import { Card, CardContent, Rating } from '@mui/material';
import ProfilePic from '../../assets/images/profilePicture.png';
import CommentPicture from '../../assets/images/comment.svg';
import Smiley from '../../assets/images/green_smiley.svg';
import SadFace from '../../assets/images/sad_face.svg';
import { format } from 'date-fns';
import ReviewAvatar from './ReviewAvatar';

const cardStyles = {
    width: '45rem',
    borderRadius: '0.5rem',
    border: '1px solid #58006B',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
};
const profilePicture = {
    width: '2.875rem',
    height: '2.875rem',
    marginTop:'0.5rem',
};
const nameTextStyles = {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '1rem',

};
const dateTextStyles = {
    color: '#8F8F8F',
    fontFamily: 'Poppins',
    fontSize: '0.5625rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};
const ratingPointStyles = {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'italic',
    fontWeight: 500,
    lineHeight: 'normal',
};
const commentImageStyles = {
    width: '0.6875rem',
    height: '0.6875rem',
};
const smileyStyles = {
    width: '1.01563rem',
    height: '1.01563rem',
};
const loremIpsumStyles = {
    color: '#888',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};


const ReviewCardSeller = ({ ratingPoints = 2.1, numOfStars = 2, smileyFace = false, comment, name, date }) => {
    smileyFace = numOfStars > 2.5;
    const smileOrSad = smileyFace === false ? SadFace : smileyFace === true ? Smiley : 'null';
    const formattedDate = format(new Date(date), 'dd MMM yyyy');

    return (
        <Card sx={{ ...cardStyles, '& .MuiCardContent-root': { padding: '0.87rem 1rem 1rem 1rem' } }}>
            <CardContent>
                <div style={{ display: 'flex' }}>
                    
                    {/* <img style={profilePicture} src={ProfilePic} alt="" /> */}
                    <ReviewAvatar name={name} width='2.875rem' height='2.875rem'/>
                    <div style={{ marginLeft: '0.37rem', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ ...nameTextStyles, marginTop: '0.82rem' }}>{name}</div>
                        <div style={dateTextStyles}>{formattedDate}</div>
                    </div>
                    
                    <div style={{ ...ratingPointStyles, marginLeft: 'auto', marginTop: '0.3rem' }}>
                        {ratingPoints}/5
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginLeft: '0.5rem' }}>
                            <Rating
                                name="custom-rating"
                                value={numOfStars}
                                precision={0.5}
                                readOnly
                                sx={{
                                    // Set the color based on the numOfStars
                                    color: numOfStars > 0 ? '#E4A951' : 'rgba(217, 217, 217, 1)',
                                    width: '1.06963rem', 
                                    height: '1.02225rem', 
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <img style={{ ...commentImageStyles, marginLeft: '3.75rem', marginTop: '0.92rem' }} src={CommentPicture} alt="" />
                            <div style={{ ...dateTextStyles, fontSize: '0.9375rem', marginLeft: '0.31rem', marginTop: '0.6rem' }}>7</div>
                            <img style={{ ...smileyStyles, marginLeft: '1.2rem', marginTop: '0.79rem' }} src={smileOrSad} alt="" />
                        </div>
                    </div>
                </div>
                <div style={{ ...loremIpsumStyles, marginTop: '1.12rem', marginLeft: '0.69rem' }}>
                    {comment}
                </div>
            </CardContent>
        </Card>
    );
};

export default ReviewCardSeller;
