import React from 'react';
import { Box, Typography } from '@mui/material';

function ProductType({ type, setType }) {
    const handleOptionClick = (option) => {
        if (option === 'all') {
            setType(null); // Set type to null when 'All' is selected
        } else {
            setType(option);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '2.8125rem',
                borderRadius: '0.625rem',
                border: '1px solid #9D40B1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }}
        >
            <Typography
                sx={{
                    color: (type === 'all' || type === null) ? '#58006B' : '#B9B7BA', // Adjusted condition
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '1.6875rem',
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                    marginLeft: '1.7rem',
                    marginRight: '1.19rem',
                    textShadow: (type === 'all' || type === null) ? '0px 0px 15px #BA68C8' : 'none', // Adjusted condition
                }}
                onClick={() => handleOptionClick('all')}
            >
                All
            </Typography>
            <div
                style={{
                    width: '0.03125rem',
                    height: '1.25rem',
                    background: '#9D40B1',
                }}
            />
            <Typography
                sx={{
                    color: type === 'web' ? '#58006B' : '#B9B7BA',
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '1.6875rem',
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                    cursor: 'pointer',
                    marginLeft: '1.19rem',
                    textShadow: type === 'web' ? '0px 0px 15px #BA68C8' : 'none',
                }}
                onClick={() => handleOptionClick('web')}
            >
                Website
            </Typography>
            <div
                style={{
                    width: '0.03125rem',
                    height: '1.25rem',
                    background: '#9D40B1',
                    marginLeft: '1.25rem'
                }}
            />
            <Typography
                sx={{
                    color: type === 'desktop' ? '#58006B' : '#B9B7BA',
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '1.6875rem',
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                    cursor: 'pointer',
                    marginLeft: '1.19rem',
                    textShadow: type === 'desktop' ? '0px 0px 15px #BA68C8' : 'none',
                }}
                onClick={() => handleOptionClick('desktop')}
            >
                Desktop App
            </Typography>
        </Box>
    );
}

export default ProductType;
