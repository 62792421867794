import React from "react";
import { FlexDiv } from "../../../assets/styles/styles";
import UserProfileSeller from "../UserProfileSeller";
import { useMediaQuery } from "react-responsive";
import { DetailText, Heading } from "../Integration/constants";
import HighFive from '../../../assets/images/high_five.svg'
const EarningGuidelines = () => {
    const isTab = useMediaQuery({ maxWidth: 835 })
    const isSmallScreen = useMediaQuery({ maxWidth: 720 })

    return (
        <FlexDiv style={{ flexDirection: 'column' }}>
            <FlexDiv style={{ marginTop: '1.2rem', justifyContent: isSmallScreen ? 'flex-end' : 'space-between', alignItems: 'center' }}>
                {!isSmallScreen &&
                    <Heading style={{ fontSize: isTab ? '1rem' : '1.5rem' }}>Withdraw your earnings</Heading>
                }
                <UserProfileSeller />
            </FlexDiv>
            <FlexDiv style={{ flexDirection: 'column', justifyContent: isSmallScreen ? 'center' : '', alignItems: isSmallScreen ? 'center' : '', marginTop: '2rem' }}>
                {isSmallScreen && <Heading>Withdraw your earnings</Heading>}
                <FlexDiv style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <img src={HighFive} alt="" style={{ width: isSmallScreen ? '15rem' : '300px', height: isSmallScreen ? '15rem' : '227px' }} />
                </FlexDiv>
                <Heading style={{ marginTop: '2rem' }}>How it works</Heading>
                <ul style={{
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: '1.25rem',
                    color: '#888',
                    textAlign: 'left',
                    marginBottom: '3rem',
                    paddingLeft: '1.5rem',
                    width: '90%'
                }}>
                    <li>When buyers make orders, they pay the Platform.</li>
                    <li>Once that order is completed, 85% of the funds are applied to your account.</li>
                    <li>The funds remain under a "pending" status for a 2-7 working days clearing period. This timeline is for financial processing and buyer satisfaction guarantee.</li>
                    <li>When the funds are marked "cleared," they are available for withdrawal.</li>
                </ul>
            </FlexDiv>

        </FlexDiv>
    );
};

export default EarningGuidelines