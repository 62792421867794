import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LockIcon from '../../assets/images/lock.svg'
import SubmitReviewIcon from '../../assets/images/submitReview.svg'
import SellerInfoImage from '../../assets/images/sellerInfo.png';
import { LoginBoxBtn } from '../../assets/styles/styles';
import DropMessage from '../../assets/images/drop_message.svg'
import styled from "@emotion/styled";
import { IP_ADD } from '../../constants/api';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { BUSINESS_GROWTH, DOLLAR, DROP_A_MSG, JAMES_AND_LAURA, LEAVE_A_MSG, LOREM_IPSUM_DOLOR, PURCHASED_FOR, RATE_THIS_APP, SELLER_INFO, TELL_OTHERS, WRITE_A_REVIEW, CONTACT } from '../../constants/text';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import Avatar from '../../assets/images/male-avatar.png'
import LockGreen from '../../assets/images/lock-green.svg'
import { sendNotificationApi } from '../../Services/Notification';
import { useNotification } from '../../Context/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { createNewReview, updateReview, fetchReviewUser, deleteReviewUser } from '../../Services/Review';
export const PurchasedBtn = styled.button`
  width: 21.625rem;
  height: 2.8125rem;
  border-radius: 0.375rem;
  background: #43952E;
  text-align:center;
  color: #FFF;
  font-family: Poppins;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid; 
  transition: background-color 0.3s ease;
  &:hover {
    color: #43952E;
    background: #FFF;
  }      
`;
export const SubmitReviewBtn = styled.button`
  width: 21.625rem;
  height: 2.8125rem;
  border-radius: 0.375rem;
  background: #026FAC;
  text-align:center;
  color: #FFF;
  font-family: Poppins;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid; 
  transition: background-color 0.3s ease;
  &:hover {
    color: #026FAC;
    background: #FFF;
  }      
`;
const cardStyles = {
    width: '25.5625rem',
    borderRadius: '1.125rem',
    border: '1px solid rgba(90, 0, 144, 0.15)',
    padding: '1.37rem 1.63rem 1.37rem 1.63rem', // Top, Right, Bottom, Left
};

const writeReview = {
    color: 'rgba(63, 0, 101, 0.71)',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '1.51rem',
};
const textFieldStyles = {
    width: '100%',
    borderRadius: '0.4375rem',
    border: '1px solid #D3D3D3',
    height: '5.3125rem',
    resize: 'none',
    marginTop: '0.87rem',
};
const dollarStyle = {
    fontFamily: 'Poppins',
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    position: 'relative',
    top: '-0.7rem',
    marginLeft: '0.5rem',
};
const priceStyle = {
    fontFamily: 'Poppins',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginLeft: '0.13rem',

};
const dividerStyles = {
    width: '100%',
    height: '0.0625rem',
    background: '#DBDBDB',
    marginTop: '1.25rem'
};
const RateAppStyle = {
    color: '#3F0065',
    fontFamily: 'Poppins',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '1.19rem',
};
const RateAppText = {
    color: '#888',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: '0.31rem',
};
const imageStyle = {
    width: '7.4375rem',
    height: '6.375rem',
    borderRadius: '0.3125rem',
};

const Jamesandlaura = {
    color: '#7300BE',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const BusinessGrowth = {
    color: '#2D2D2D',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

const BusniessGrowthSubtext = {
    color: '#757474',
    fontFamily: 'Poppins',
    fontSize: '0.6875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',

};

const textAreaStyle = {
    width: '21.8125rem',
    height: '9.375rem',
    borderRadius: '0.4375rem',
    border: '1px solid #D3D3D3',
    padding: '1.25rem',
    resize: 'none',
    color: '#858585', // Placeholder text color
    fontFamily: 'Poppins',
    fontSize: '1.0625rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',

};

const sellerInfo = {
    color: '#3F0065',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const customIcon = (
    <span style={{ color: '#E4A951' }}>
        &#9733; {/* Unicode star character */}
    </span>
);

const customEmptyIcon = (
    <span style={{ color: 'rgba(217, 217, 217, 1)' }}>
        &#9733; {/* Unicode star character */}
    </span>
);

const TokenExpired = (tokenDate) => {
    const convertedTime = new Date(tokenDate);
    const currentTime = new Date();
    if (currentTime >= convertedTime) {
        return true;
    }
    return false;
}

const RateAndReview = ({ sellerId, productID, productCost = '799.99', userHasReview, setUserHasReview, firstName, lastName, profilePicture, companyName, bio, setUpdatedReview }) => {
    const isSmallScreen = useMediaQuery('(max-width:450px)');
    const [userRating, setUserRating] = useState(0);
    const [productComment, setProductComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isExpired, setIsExpired] = useState(true);
    const [sendingMsg, setSendingMsg] = useState(false);
    const [message, setMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isHovered, setIsHovered] = useState(false);
    const { socket } = useNotification();
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const userToken = (localStorage.getItem('access-token'));
    const navigate = useNavigate();

    const userID = userData.response.id

    const handleTextareaChange = (event) => {
        setMessage(event.target.value);
    };

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleLeave = () => {
        setIsHovered(false);
    };

    const refreshtoken = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const token = localStorage.getItem('refresh-token');
                const response = await axios.post(`${IP_ADD}/api/auth/refresh-tokens`, {
                    refreshToken: token
                });

                if (response.status === 200) {
                    localStorage.setItem('access-token', response.data.response.response.tokens.access.token);
                    localStorage.setItem('refresh-token', response.data.response.response.tokens.refresh.token);
                    localStorage.setItem('access-token-expiry', response.data.response.response.tokens.access.expires);
                    localStorage.setItem('refresh-token-expiry', response.data.response.response.tokens.refresh.expires);
                    setIsExpired(false);

                    // Resolve the promise with the response data
                    resolve(response.data);
                } else {
                    // Reject the promise with an error message
                    reject(new Error('Token refresh failed.'));
                }
            } catch (error) {
                // Reject the promise with the error
                reject(error);
            }
        });
    };

    const LockImageSrc = isHovered ? LockGreen : LockIcon;
    const accessChat = async () => {
        if (message === '') {
            setSnackbarMsg("Please enter a message");
            setSnackbarSeverity('error');
            setOpen(true);
            return;
        }
        const refreshExpiryToken = localStorage.getItem('refresh-token-expiry');
        const convertedTime = new Date(refreshExpiryToken);
        const currentTime = new Date();
        if (currentTime >= convertedTime) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('access-token-expiry');
            localStorage.removeItem('refresh-token-expiry');
            localStorage.removeItem('Cornucopia-user');
            navigate('/login');
            return;
        }
        const accessExpiryToken = localStorage.getItem('access-token-expiry');
        if (TokenExpired(accessExpiryToken)) {
            try {
                // Assuming refreshtoken returns a promise
                await refreshtoken();
            } catch (refreshError) {
                console.log('Error refreshing token', refreshError);
                // Handle error if needed
            }
        }
        const userId = sellerId;
        try {
            setSendingMsg(true);
            const userToken = (localStorage.getItem('access-token'));
            let config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(`${IP_ADD}/api/chats`, { userId }, config);
            const chatId = response.data.response._id;
            const formData = new FormData();

            formData.append('content', message);
            formData.append('chatId', chatId);
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userToken}`,
                },
            };

            const { data } = await axios.post(
                `${IP_ADD}/api/messages`,
                formData,
                config
            );
            if (socket && socket.connected) {
                socket.emit('event', { type: 'message', data: data.response });
            }
            setMessage('')
            setSnackbarMsg("Message Delivered");
            setSnackbarSeverity('success');
            setOpen(true);
            const notificationApiResult = await sendNotificationApi(
                sellerId,
                message,
                userData.response.id,
                "message"
            );
            if (!notificationApiResult.success) {
                console.log("Error in notification", notificationApiResult.error);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMsg("Message Failed");
            setSnackbarSeverity('error');
            setOpen(true);
            throw error;
        } finally {
            setSendingMsg(false);
        }
    };

    const createReview = async (rating, comment) => {
        try {
            setLoading(true);
            const response = await createNewReview(userID, productID, rating, comment);
            // handle the response as needed
            setSnackbarMsg("Review Submitted Successfully");
            setSnackbarSeverity("success");
            setOpen(true);
            setUserHasReview(true);
        } catch (error) {
            console.error('Error creating review:', error.message);
            if (error?.response?.data?.message) {
                setSnackbarMsg(error.response.data.message);
                setSnackbarSeverity('error');
            } else if (error?.message) {
                setSnackbarMsg(error.message);
                setSnackbarSeverity('error');
            } else {
                setSnackbarMsg('Error');
                setSnackbarSeverity('error');
            }
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };
    const updateUserReview = async (rating, comment) => {
        try {
            setLoading(true);
            const response = await updateReview(userID, productID, rating, comment);
            // handle the response as needed
            setSnackbarMsg("Review Updated Successfully");
            setSnackbarSeverity("success");
            setOpen(true);
            setUpdatedReview(true);
        } catch (error) {
            console.error('Error updating review:', error.message);
            if (error?.response?.data?.message) {
                setSnackbarMsg(error.response.data.message);
                setSnackbarSeverity('error');
            } else if (error?.message) {
                setSnackbarMsg(error.message);
                setSnackbarSeverity('error');
            } else {
                setSnackbarMsg('Error');
                setSnackbarSeverity('error');
            }
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };


    const fetchUserReview = async () => {
        try {
            const response = await fetchReviewUser(userID, productID);
            // handle the response as needed
            if (response.status === 200 && response.response) {
                const { rating, comment } = response.response;
                setUserRating(rating);
                setProductComment(comment);
                setUserHasReview(true);
            }
        } catch (error) {
            // handle errors
            console.error('Error fetching user review:', error.message);
        }
    };
    const deleteReview = async () => {
        try {
            setLoading(true);
            const response = await deleteReviewUser(userID, productID);
            // handle the response as needed
            setSnackbarMsg("Review Deleted Successfully");
            setSnackbarSeverity("success");
            setOpen(true);
            // Reset the state or perform any other necessary actions after deletion
            setUserRating(0);
            setProductComment('');
            setUserHasReview(false);
        } catch (error) {
            console.error('Error deleting review:', error.message);
            if (error?.response?.data?.message) {
                setSnackbarMsg(error.response.data.message);
                setSnackbarSeverity('error');
            } else if (error?.message) {
                setSnackbarMsg(error.message);
                setSnackbarSeverity('error');
            } else {
                setSnackbarMsg('Error');
                setSnackbarSeverity('error');
            }
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserReview();
    }, [userID, productID, userHasReview]);

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Set anchorOrigin to top right
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>

            <Card style={cardStyles}>
                <CardContent>

                    <PurchasedBtn
                        style={{ width: '100%', justifyContent: 'center' }}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleLeave}
                    >
                        <img
                            style={{ marginBottom: '0.88rem', marginTop: '0.75rem', marginRight: '0.81rem' }}
                            src={LockImageSrc}
                            alt=""
                        />
                        {PURCHASED_FOR}
                        <span style={dollarStyle}>{DOLLAR}</span>
                        <span style={priceStyle}>{productCost}</span>
                    </PurchasedBtn>                    <div style={dividerStyles}></div>
                    <div style={{ ...RateAppStyle, display: 'flex' }}>
                        <b>{RATE_THIS_APP}</b>
                        {userHasReview && (
                            <>
                                <Tooltip title='Delete'>
                                    <DeleteIcon
                                        style={{ marginLeft: 'auto', marginRight: '0.5rem', cursor: 'pointer', color: '#D95656' }}
                                        onClick={deleteReview} />
                                </Tooltip>
                                <Tooltip title='Update Review'>
                                    <EditNoteIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setIsEditing(true)}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </div>
                    <div style={RateAppText}>{TELL_OTHERS}</div>
                    <Rating
                        value={userRating}
                        precision={0.5}
                        icon={customIcon}
                        emptyIcon={customEmptyIcon}
                        onChange={(event, newValue) => {
                            setUserRating(newValue);
                        }}
                        style={{ marginTop: '0.75rem' }}
                        readOnly={!isEditing && userHasReview}
                    />
                    <div style={writeReview}><b>{WRITE_A_REVIEW}</b></div>
                    <TextareaAutosize
                        style={{
                            ...textFieldStyles,
                            width: '100%',
                            padding: '1rem',
                            color: '#6B6B6B',
                            fontFamily: 'Poppins',
                            fontSize: '0.75rem',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 'normal',
                            backgroundColor: isEditing || !userHasReview ? 'transparent' : '#f5f5f5',
                        }}
                        value={productComment}
                        onChange={(e) => setProductComment(e.target.value)}
                        readOnly={!isEditing && userHasReview}
                    />
                    <SubmitReviewBtn
                        style={{ width: '100%', justifyContent: 'center', marginTop: '1.19rem' }}
                        onClick={() => {
                            const rating = userRating;
                            const comment = productComment;
                            if (!rating && !comment) {
                                setSnackbarMsg("Please provide both rating and comment.");
                                setSnackbarSeverity("error");
                                setOpen(true);
                                return;
                            }
                            if (!rating) {
                                setSnackbarMsg("Please provide rating.");
                                setSnackbarSeverity("error");
                                setOpen(true);
                                return;
                            }
                            if (!comment) {
                                setSnackbarMsg("Please provide comment.");
                                setSnackbarSeverity("error");
                                setOpen(true);
                                return;
                            }
                            if (userHasReview) {
                                updateUserReview(rating, comment);
                            } else {
                                createReview(rating, comment);
                            }
                        }}
                    >
                        {loading ? (
                            <CircularProgress style={{ width: '27px', height: '25px' }} />
                        ) : (
                            <>
                                <img style={{ marginBottom: '0.88rem', marginTop: '0.5rem', marginRight: '0.63rem' }} src={SubmitReviewIcon} alt="" />
                                {userHasReview ? "Update Review" : "Submit Review"}
                            </>
                        )}
                    </SubmitReviewBtn>
                    <div style={{ ...RateAppStyle, marginTop: '1.44rem' }}><b>{SELLER_INFO}</b></div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: isSmallScreen ? 'column' : 'inherit' }}>
                        <div style={{ marginTop: '1.62rem' }}>
                            <img style={imageStyle} src={profilePicture ? profilePicture : Avatar} alt='Seller Info' />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ ...Jamesandlaura, marginLeft: '0.94rem', marginTop: isSmallScreen ? '0.5rem' : '2.2rem', textAlign: isSmallScreen ? 'center' : 'inherit' }}>
                                {firstName} {lastName}
                            </div>

                            <div style={{ ...BusinessGrowth, marginLeft: '0.94rem', marginTop: '0.12rem', textAlign: isSmallScreen ? 'center' : 'inherit' }}>
                                {companyName ? companyName : BUSINESS_GROWTH}
                            </div>

                            <div style={{ ...BusniessGrowthSubtext, marginLeft: '0.94rem', marginTop: '0.31rem', textAlign: isSmallScreen ? 'center' : 'inherit' }}>
                                {bio && bio.length > 120 ? `${bio.substring(0, 120)}...` : bio || "Tech enthusiast turned seller! Offering cutting-edge gadgets and solutions to elevate your tech experience!"}
                            </div>
                        </div>
                    </div>

                    <div style={{ ...sellerInfo, fontSize: '1.375rem', marginTop: '1.44rem' }}>
                        <b>{CONTACT}</b>
                    </div>
                    <div style={{ marginTop: '1.25rem' }}>
                        <TextareaAutosize style={{ ...textAreaStyle, width: '100%' }} placeholder={LEAVE_A_MSG} value={message} onChange={handleTextareaChange} />
                    </div>
                    <div style={{ justifyContent: 'center' }}>
                        <LoginBoxBtn style={{ width: '100%', marginTop: '1.7rem', }} onClick={accessChat}>
                            {sendingMsg ? <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} /> :
                                <>
                                    <img style={{ marginRight: '0.5rem' }} src={DropMessage} alt="" />{DROP_A_MSG}
                                </>
                            }
                        </LoginBoxBtn>
                    </div>
                </CardContent>
            </Card>
        </>
    )
};

export default RateAndReview;
