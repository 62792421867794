import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MagnifyGlass from '../../assets/images/magnify_glass_search.svg'
import { useMediaQuery } from 'react-responsive';

const containerStyle = {
    display: 'flex',
    alignItems: 'center',
};
const titleStyle = {
    color: '#58006B',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.6875rem',
    marginRight: '1rem',
    whiteSpace: 'nowrap',
};
const responsiveContainerStyle = {
    ...containerStyle,
    justifyContent: 'center',
    left: 0,
    right: 0,

};

const SellerSearch = ({ onSearch }) => {
    const isSmallMobile = useMediaQuery({ maxWidth: 400 })
    const isMobile = useMediaQuery({ maxWidth: 550 });
    const isTab = useMediaQuery({ minWidth: 750 });
    const [searchText, setSearchText] = useState('');

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearchEnter = (event) => {
        if (event.key === 'Enter') {
            onSearch(searchText);
        }
    };
    const handleSearchClick = () => {
        onSearch(searchText);
    };

    return (
        <div style={isMobile ? responsiveContainerStyle : containerStyle}>
            {isTab && <div style={titleStyle}>All Sellers</div>}
            <TextField

                placeholder="Search by name or designation"
                onChange={handleSearchInputChange}
                onKeyDown={handleSearchEnter}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        width: isSmallMobile ? '119%' : '22.5rem',
                        height: '2.375rem',
                        borderRadius: '0.4375rem',
                        padding: '0.5rem',
                        position: 'relative',

                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'rgb(152, 3, 250)' },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'rgb(152, 3, 250)' },
                    '& input::placeholder': {
                        color: '#979797',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '1.6875rem',

                    },

                }}
                InputProps={{
                    startAdornment: (
                        <img
                            src={MagnifyGlass}
                            alt="Search"
                            style={{
                                cursor: 'pointer',
                                marginRight: '1.31rem',
                                marginLeft: '-0.5rem',
                            }}
                            onClick={handleSearchClick}
                        />
                    ),
                }}
            />
        </div>
    );
};
export default SellerSearch;