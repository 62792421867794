export const resetTextStyle = {
    color: '#6D0085',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop: '1.75rem'
};

export const textFieldHeader = {
    fontFamily: 'Poppins',
    fontSize: '1.0625rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '11.7rem',
    marginTop: '1.75rem',
};

export const textFieldStyle = {
    width: '19.4375rem',
    height: '3.1875rem',
    flexShrink: 0,
    borderRadius: '0.3125rem',
    marginTop: '0.94rem',
};