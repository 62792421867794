import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import magnify_glass from '../../assets/images/magnify_glass.svg';

function ChatSearch({ searchTerm, onSearchChange, onSearchSubmit }) {
    const handleSearchSubmit = (e) => {
        if (e.key === 'Enter') {
            onSearchSubmit();
        }
    };

    return (
        <TextField
            sx={{
               
                borderRadius: '0.3125rem',
                width: '100%',
                background: '#FFF',
                
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <img src={magnify_glass} alt="" />
                    </InputAdornment>
                ),
            }}
            inputProps={{
                style: {
                    color: 'rgba(35, 35, 35, 0.9)',
                    fontFamily: 'Poppins',
                    fontSize: '0.9375rem',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                },
            }}
            InputLabelProps={{
                style: {
                    '&.Mui-focused': {
                        color: 'rgba(35, 35, 35, 0.9)', 
                    },
                },
            }}
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            onKeyDown={handleSearchSubmit}
            placeholder="Search"
            focused={false}            
        />
    );
}

export default ChatSearch;
