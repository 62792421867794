import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState([]);
    const [notificationUpdated, setNotificationUpdated] = useState(false);
    const [chatId, setChatId] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [displayPicture, setDisplayPicture] = useState();
    const [lastName, setLastName] = useState('');
    const [chatAvailable, setChatAvailable] = useState(false);
    const [chatsCopy, setChatsCopy] = useState([]);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [chatListClicked, setChatListClicked] = useState(false);
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [socketBuyer, setSocketBuyer] = useState();
    const [paymentFail, setPaymentFail] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentFailSeller, setPaymentFailSeller] = useState(false);
    const [fetchedDisplayPic, setFetchedDisplayPic] = useState(false);
    const [newProductId, setNewProductId] = useState();
    const [avatarColor, setAvatarColor] = useState()
    const [selected, setSelected] = useState('web');
    const [selectedCheckboxKeys, setSelectedCheckboxKeys] = useState([]);
    const [selectedTopCheckboxes, setSelectedTopCheckboxes] = useState({});
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    return (
        <NotificationContext.Provider value={{
            notification,
            notificationUpdated,
            setNotification,
            setNotificationUpdated,
            chatId,
            setChatId,
            firstName,
            setFirstName,
            lastName,
            setLastName,
            chatAvailable,
            setChatAvailable,
            chatsCopy,
            setChatsCopy,
            fetchAgain,
            setFetchAgain,
            chatListClicked,
            setChatListClicked,
            messages,
            setMessages,
            socket,
            setSocket,
            socketBuyer,
            setSocketBuyer,
            paymentFail,
            setPaymentFail,
            setPaymentSuccess,
            paymentSuccess,
            paymentFailSeller,
            setPaymentFailSeller,
            displayPicture,
            setDisplayPicture,
            fetchedDisplayPic,
            setFetchedDisplayPic,
            avatarColor,
            setAvatarColor,
            setNewProductId,
            newProductId,
            selected,
            setSelected,
            selectedCheckboxKeys,
            setSelectedCheckboxKeys,
            selectedTopCheckboxes,
            setSelectedTopCheckboxes,
            selectedFilters,
            setSelectedFilters,
            selectedCategory,
            setSelectedCategory
        }}>
            {children}
        </NotificationContext.Provider>
    );
};
