import axios from "axios";
import { IP_ADD } from "../constants/api";


export const fetchFinancialAnalyticsApi = async (userId) => {
    try {
        const response = await axios.post(`${IP_ADD}/api/analytics/financial-analytics`, {
            userId: userId 
        });

        if (response.data.status === 200) {
            return response.data;
        } else {
            throw new Error(response.data.message || 'Failed to fetch financial analytics');
        }
    } catch (error) {
        throw new Error('Failed to fetch financial analytics');
    }
};