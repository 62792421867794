import React from 'react';
import { TrendingProductsText, AiProductsText, ViewBtn, FlexDiv } from '../../assets/styles/styles';
import { useMediaQuery } from 'react-responsive';
import Grid from '@mui/material/Grid';
import TrendingProductsCard from './TrendingProductsCard';
import { AI_PRODUCTS_TEXT, VIEW_MORE } from '../../constants/text';
import { useNavigate } from 'react-router-dom';
import NoProduct from '../SellerDashboard/NoProducts';

const TrendingProducts = ({ totalTrendingProducts = 8, trendingProductsText = 'Trending Products', products = [], viewMore = true, custom }) => {
    const isMobile = useMediaQuery({ maxWidth: 550 });
    const isMobileTwo = useMediaQuery({ maxWidth: 699 });
    const navigate = useNavigate();

    const handleViewMoreClick = () => {
        navigate('/products');
    };

    return (
        <div style={{ margin: isMobileTwo ? '0rem 1rem 1rem 1rem' : '0rem 4rem 1rem 6.5rem' }}>
            <FlexDiv style={{ justifyContent: 'space-between' }}>
                <div>
                    <TrendingProductsText style={{ fontSize: isMobile ? '1.4rem' : '' }}>{trendingProductsText}</TrendingProductsText>
                    {!isMobileTwo && <AiProductsText>{AI_PRODUCTS_TEXT}</AiProductsText>}
                </div>
                {/* {products.length > 0 && viewMore && <ViewBtn onClick={handleViewMoreClick}>{VIEW_MORE}</ViewBtn>} */}

            </FlexDiv>
            {
                products.length === 0 ? <div style={{ marginTop: '2rem' }}><NoProduct /></div> : <>
                    <Grid container marginBottom='4.8rem' spacing={8}>
                        {products.map((product, index) => (
                            <Grid item key={index} lg={3} md={4} sm={6} xs={12} display='flex' flexDirection='column' alignItems='center' marginTop='2.8rem'>
                                <TrendingProductsCard
                                    product={{
                                        name: product.name,
                                        price: product.price,
                                        description: product.description,
                                        image: product.images?.[0] || '',
                                        buyers: product.buyers?.length || 0,
                                        id: product.id,
                                    }}
                                    tags={product.category}
                                    custom={custom}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
            }
        </div >
    );
};

export default TrendingProducts;
