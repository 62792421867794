import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { OPTION_ONE, US_DOLLAR } from '../../constants/text';

const PricePrefix = {
    width: '3rem',
    height: '3.1875rem',
    borderRadius: '0.3125rem 0rem 0rem 0.3125rem',
    background: '#DBB9E7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft:'-0.55rem',
};

const DollarSign = {
    color: '#9D40B1',
    fontFamily: 'Poppins',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
};

const ButtonText = {
    color: '#9D40B1', 
    fontFamily: 'Poppins',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginLeft:'0.94rem',
    
};

const DropdownButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        // setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ width: '50%', height: '3.1875rem', flexShrink: 0 }}>
            <Button
                onClick={handleClick}
                disableRipple  
                // variant="outlined"
                sx={{
                    border: '1px solid #9D40B1',
                    width: '100%',
                    height: '3.1875rem',
                    borderRadius: '0.3125rem',
                    background: '#FAF7FB',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: '0.5rem',
                    '&:active': {
                        background: '#FAF7FB'
                    },
                   
                    
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={PricePrefix}>
                        <Typography sx={DollarSign}>$</Typography>
                    </div>
                    <Typography sx={{ ...ButtonText, textTransform: 'none' }}>{US_DOLLAR}</Typography>
                </div>
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleClose} value={1}>{OPTION_ONE}</MenuItem>
                <MenuItem onClick={handleClose} value={2}>{OPTION_ONE}</MenuItem>
                <MenuItem onClick={handleClose} value={3}>{OPTION_ONE}</MenuItem>
            </Popover>
        </div>
    );
};

export default DropdownButton;
