import React from 'react';
import BlankState from '../Utils/BlankState';
import ReviewIcon from '../../assets/images/review_icon.svg';

const NoReview = () => {
    return (
        <BlankState text={'No Reviews yet'} icon={ReviewIcon} height={'43.875rem'} marginLeft={'2.5rem'} icon_width={'2.5rem'} icon_height={'2.5rem'}/>
    );
};

export default NoReview;
